import MetaTag from "components/Common/Meta-tag"
import React, { useEffect, useState } from "react"

import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Nav,
  NavItem,
  Row,
  TabContent,
  TabPane,
  NavLink,
  Container,
  CardDeck,
  Button,
} from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaSnapchat,
  FaTwitter,
  FaWhatsapp,
} from "react-icons/fa" // Assuming you are using the Font Awesome library for icons
import Breadcrumbs from "components/Common/Breadcrumb"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import { getGroWebDetails, getGrowebDashboardData } from "store/actions"
import person from "../../../assets/images/testimg/1.png"
import moment from "moment"
import { useCopyText } from "hooks/useCopyText"

function SingleViewDetails({ toggleCustom }) {
  const params = useParams()
  const dispatch = useDispatch()
  const { loading, growebDetails } = useSelector(state => ({
    loading: state.GroWeb.loading,
    growebDetails: state.GroWeb.growebDetails,
  }))

  const { copyText } = useCopyText()

  const handleCopy = text => {
    copyText(text)
  }
  return (
    <>
      <MetaTag title={"groweb-details"} />
      <div className=" groweb-single-view mt-3">
        <Container fluid>
          <Row className="custom-row">
            <Col lg={4}>
              <Row>
                <Card className="custom-card-1 card-in-same-row-1 p-0">
                  <CardBody>
                    <Row>
                      <Col
                        xs={6}
                        className="d-flex flex-column align-items-start justify-content-start"
                      >
                        <img
                          src={person}
                          alt="Image"
                          className="img-fluid logo-img-single-view"
                        />
                      </Col>
                      <Col xs={6} className="my-auto content-side">
                        <div className="mb-3">
                          <strong>Name:&nbsp;</strong>
                          {growebDetails?.name}
                        </div>
                        {/* <div className="mb-3">
                          <strong>API Key:</strong>
                          <i
                            onClick={() => handleCopy(growebDetails?.apiKey)}
                            className="bx bx-copy me-3 "
                            style={{ cursor: "copy", fontSize: "1.2rem" }}
                            title="Copy Api key"
                          ></i>
                        </div> */}
                        <button
                          className=" btn copy-btn"
                          onClick={() => handleCopy(growebDetails?.apiKey)}
                          style={{ fontSize: "12px", color: "white" }}
                          title="Copy API Key"
                        >
                          Copy API Key
                          <i
                            className="bx bx-copy ms-3"
                            style={{ cursor: "pointer" }}
                          ></i>
                        </button>
                      </Col>
                    </Row>
                    <Row>
                      {/* First column (50% width) for the image */}

                      {/* Second column (50% width) for two content lines */}
                      <Col xs={12} className="my-auto content-side mt-2">
                        <div className="mb-2">
                          <strong>Description:&nbsp;</strong>{" "}
                          {growebDetails?.description}
                        </div>

                        {growebDetails?.keywords && (
                          <div className="">
                            <strong>Keywords:&nbsp;</strong>{" "}
                            {growebDetails?.keywords}
                          </div>
                        )}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Row>
            </Col>

            <Col lg={3} className="">
              <Card className="custom-card-2 card-in-same-row-1 p-0">
                <CardBody className="d-flex flex-column  justify-content-center">
                  <div className="font-weight-bold">
                    <h6 className="client-h">Client</h6>
                  </div>
                  <div className="mt-3">
                    <h6>Name: {growebDetails?.client?.name}</h6>
                    <h6>Phone Number:{growebDetails?.client?.phone}</h6>
                    <h6>
                      WhatsApp Number:{growebDetails?.client?.whatsappNumber}
                    </h6>
                    <h6>
                      Created:{" "}
                      {moment(growebDetails?.client?.updatedAt).format(
                        "DD/MM/YYYY - h:mm a"
                      )}
                    </h6>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg={4}>
              <div className="d-flex align-items-start flex-column ">
                <Button color="warning" className="btn-rounded mb-1 ">
                  Groweb Preview Content
                </Button>
                <Button
                  color="warning"
                  className="btn-rounded "
                  onClick={() => {
                    toggleCustom("6")
                  }}
                >
                  Update GrowPage
                </Button>
              </div>
              {/* <Row>
                <Col lg={3}>
                  <Card className="custom-card-1 card-in-same-row-5  ">
                    <CardBody className="d-flex flex-column align-items-center justify-content-center padding-zero">
                      {" "}
                      <h5 className="mb-1 total-text">Total GrowPage </h5>
                      <div className="h3 mb-0">
                        {growebDashboardData?.growpageCount}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={3}>
                  <Card className="custom-card-1 card-in-same-row-5 ">
                    <CardBody className="d-flex flex-column align-items-center padding-zero">
                      {" "}
                      <h5 className="mb-1 total-text"> Total Leads </h5>
                      <div className="h3 mb-0 ">
                        {growebDashboardData?.leadCount}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={3}>
                  <Card className="custom-card-1 card-in-same-row-5 ">
                    <CardBody className="d-flex flex-column align-items-center padding-zero">
                      {" "}
                      <h5 className="mb-1 total-text">Total Pageview </h5>
                      <div className="h3 mb-0">
                        {growebDashboardData?.pageViewCount}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={3}>
                  <Card className="custom-card-1 card-in-same-row-5 ">
                    <CardBody className="d-flex flex-column align-items-center padding-zero">
                      {" "}
                      <h5 className="mb-1 total-text"> Total Product</h5>
                      <div className="h3 mb-0">
                        {growebDashboardData?.productCount}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row> */}
            </Col>
          </Row>

          <Row className="custom-row">
            <Col lg={4} className="card-in-same-row-2 p-0">
              <Card className="custom-card-1 custom-height-2nd-row card-in-same-row-2  ">
                <CardBody>
                  <div className="font-weight-bold">
                    <h6 className="client-h">Company</h6>
                  </div>
                  <div className="mt-3">
                    <h6>Name: {growebDetails?.company?.name}</h6>
                    <h6>
                      Phone Number:{" "}
                      {growebDetails?.company?.contactDetails?.phone}
                    </h6>
                    <h6>
                      email:{growebDetails?.company?.contactDetails?.email}
                    </h6>
                    <h6>
                      website: {growebDetails?.company?.contactDetails?.website}
                    </h6>
                  </div>

                  <div className="mt-3">
                    <h6 className="Address">socialLinks</h6>
                    <div className="">
                      {growebDetails?.company?.socialLinks?.facebook && (
                        // <FaFacebook className="me-2" />
                        <a
                          href={`https://${growebDetails?.company?.socialLinks?.facebook}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="me-2"
                        >
                          <FaFacebook />
                        </a>
                      )}
                      {growebDetails?.company?.socialLinks?.instagram && (
                        <a
                          href={`https://${growebDetails?.company?.socialLinks?.instagram}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="me-2"
                        >
                          <FaInstagram />
                        </a>
                      )}
                      {growebDetails?.company?.socialLinks?.twitter && (
                        <a
                          href={`https://${growebDetails?.company?.socialLinks?.twitter}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="me-2"
                        >
                          <FaTwitter />
                        </a>
                      )}
                      {growebDetails?.company?.socialLinks?.linkedin && (
                        <a
                          href={`https://${growebDetails?.company?.socialLinks?.linkedin}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="me-2"
                        >
                          <FaLinkedin />
                        </a>
                      )}
                      {growebDetails?.company?.socialLinks?.snapchat && (
                        <a
                          href={`https://${growebDetails?.company?.socialLinks?.snapchat}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="me-2"
                        >
                          <FaSnapchat />
                        </a>
                      )}
                      {growebDetails?.company?.socialLinks?.whatsApp && (
                        <a
                          href={`https://${growebDetails?.company?.socialLinks?.whatsApp}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="me-2"
                        >
                          <FaWhatsapp />
                        </a>
                      )}
                    </div>
                    {/* <h6>
                      facebook:{growebDetails?.company?.socialLinks?.facebook}
                    </h6>
                    <h6>
                      instagram:{growebDetails?.company?.socialLinks?.instagram}
                    </h6>
                    <h6>
                      linkedin: {growebDetails?.company?.socialLinks?.linkedin}
                    </h6>
                    <h6>
                      snapchat:{growebDetails?.company?.socialLinks?.snapchat}
                    </h6>
                    <h6>
                      twitter : {growebDetails?.company?.socialLinks?.twitter}
                    </h6>
                    <h6>
                      whatsApp :{growebDetails?.company?.socialLinks?.whatsApp}
                    </h6> */}
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg={3} className="card-in-same-row-2 mr-2">
              <Row>
                <Col lg={12} className=" card-in-same-row-3 ">
                  <Card className="custom-card-1 ">
                    <CardBody>
                      <div className="mt-3">
                        <h6 className="Address">Address</h6>
                        <h6>
                          addressLine1:{" "}
                          {growebDetails?.company?.address?.addressLine1}
                        </h6>
                        <h6>
                          addressLine2:{" "}
                          {growebDetails?.company?.address?.addressLine2}
                        </h6>
                        <h6>
                          country: {growebDetails?.company?.address?.country}
                        </h6>
                        <h6>
                          district: {growebDetails?.company?.address?.district}
                        </h6>
                        <h6>
                          state : {growebDetails?.company?.address?.state}
                        </h6>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default SingleViewDetails
// toggleCustom
SingleViewDetails.propTypes = {
  toggleCustom: PropTypes.any,
}
