import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useParams } from "react-router"
import { Alert, Card, CardBody, Col, Container, Row } from "reactstrap"

import Breadcrumbs from "../../../../components/Common/Breadcrumb"

//actions
import { getUserDetails, updateUser } from "store/actions"
import MetaTag from "components/Common/Meta-tag"

const UpdateUser = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams()

  const { userDetails, loading, createUserError } = useSelector(state => ({
    createUserError: state.Users.createUserError,
    userDetails: state.Users.userDetails,
    loading: state.Users.loading,
  }))

  function handleValidSubmit(values) {
    dispatch(updateUser(values, userDetails?._id, history))
  }

  useEffect(() => {
    dispatch(getUserDetails(params?.id))
  }, [dispatch])

  const userRole = [
    {
      role: "user",
    },
    {
      role: "dealer",
    },
    {
      role: "admin",
    },
  ]

  return (
    <React.Fragment>
      <MetaTag title={"Update Users"} />

      <div className="page-content">
        <Breadcrumbs title="Dashboard / Users" breadcrumbItem="Update User" />
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={8} xl={8}>
              <Card className="overflow-hidden">
                <CardBody className="pt-4">
                  <div className="p-2">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(v)
                      }}
                    >
                      {createUserError && (
                        <Alert color="danger">{createUserError}</Alert>
                      )}

                      <div className="mb-3">
                        <AvField
                          name="name"
                          label="User Name"
                          value={userDetails?.name || ""}
                          className="form-control"
                          placeholder="Enter user name"
                          type="text"
                        />
                      </div>

                      <div className="mb-3">
                        <AvField
                          name="email"
                          label="Email"
                          value={userDetails?.email || ""}
                          type="text"
                        />
                      </div>

                      <div className="mb-3">
                        <AvField
                          type="select"
                          name="role"
                          label="User Role"
                          value={userDetails?.role || "" || ""}
                        >
                          {userRole?.map((item, key) => (
                            <option key={key} value={item.role}>
                              {item.role}
                            </option>
                          ))}
                        </AvField>
                      </div>

                      {/* <div className="mb-3">
                        <AvField
                          name="role"
                          label="Role"
                          value={userDetails?.role}
                          type="text"
                        />
                      </div> */}

                      <div className="mb-3">
                        <AvField
                          name="firebaseUid"
                          label="User Id"
                          value={userDetails?.firebaseUid}
                          type="text"
                          readOnly
                        />
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-success btn-block"
                          type="submit"
                        >
                          Update User
                        </button>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default UpdateUser
