/* GroWebs - This line cannot be edited or removed */
export const GET_GROWEBS = "GET_GROWEBS"
export const GET_GROWEBS_SUCCESS = "GET_GROWEBS_SUCCESS"
export const GET_GROWEBS_FAIL = "GET_GROWEBS_FAIL"

/* GroWeb_DETAILS */
export const GET_GROWEB_DETAILS = "GET_GROWEB_DETAILS"
export const GET_GROWEB_DETAILS_SUCCESS = "GET_GROWEB_DETAILS_SUCCESS"
export const GET_GROWEB_DETAILS_FAIL = "GET_GROWEB_DETAILS_FAIL"
export const CLEAR_GROWEB_DETAILS = "CLEAR_GROWEB_DETAILS"

/* CREATE GroWeb */
export const CREATE_GROWEB = "CREATE_GROWEB"
export const CREATE_GROWEB_SUCCESS = "CREATE_GROWEB_SUCCESS"
export const CREATE_GROWEB_FAIL = "CREATE_GROWEB_FAIL"

/* Edit GroWeb */
export const UPDATE_GROWEB = "UPDATE_GROWEB"
export const UPDATE_GROWEB_SUCCESS = "UPDATE_GROWEB_SUCCESS"
export const UPDATE_GROWEB_FAIL = "UPDATE_GROWEB_FAIL"

/* Delete GroWeb */
export const DELETE_GROWEB = "DELETE_GROWEB"
export const DELETE_GROWEB_SUCCESS = "DELETE_GROWEB_SUCCESS"
export const DELETE_GROWEB_FAIL = "DELETE_GROWEB_FAIL"
// groweb dashboard data
export const GET_GROWEB_DASHBOARD_DATA = "GET_GROWEB_DASHBOARD_DATA"
export const GET_GROWEB_DASHBOARD_DATA_SUCCESS =
  "GET_GROWEB_DASHBOARD_DATA_SUCCESS"
export const GET_GROWEB_DASHBOARD_DATA_FAIL = "GET_GROWEB_DASHBOARD_DATA_FAIL"
