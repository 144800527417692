import {
  GET_GROWPAGES,
  GET_GROWPAGES_SUCCESS,
  GET_GROWPAGES_FAIL,
  GET_GROWPAGE_DETAILS,
  GET_GROWPAGE_DETAILS_SUCCESS,
  GET_GROWPAGE_DETAILS_FAIL,
  CREATE_GROWPAGE,
  CREATE_GROWPAGE_SUCCESS,
  CREATE_GROWPAGE_FAIL,
  UPDATE_GROWPAGE,
  UPDATE_GROWPAGE_SUCCESS,
  UPDATE_GROWPAGE_FAIL,
  DELETE_GROWPAGE,
  DELETE_GROWPAGE_SUCCESS,
  DELETE_GROWPAGE_FAIL,
  CREATE_SECTION,
  DELETE_SECTION,
  UPDATE_SECTION,
  GET_DEFAULT_SECTION,
  GET_DEFAULT_SECTION_FAIL,
  GET_DEFAULT_SECTION_SUCCESS,
  CREATE_SECTION_FAIL,
  UPDATE_SECTION_FAIL,
  DELETE_SECTION_FAIL,
  CHECK_PAGE_TITLE,
  CHECK_PAGE_TITLE_SUCCESS,
  CHECK_PAGE_TITLE_FAIL,
  GET_DUPLICATE_SECTION,
  GET_DUPLICATE_SECTION_SUCCESS,
  GET_DUPLICATE_SECTION_FAIL,
  CREATE_SECTION_SUCCESS,
  UPDATE_SECTION_SUCCESS,
  DELETE_SECTION_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  growpages: {
    growpages: [],
    total: "",
    page: "",
  },
  growpageDetails: {},
  error: {},
  loading: false,

  pageTitleCheck: {},

  section: {},
  pageTitleCheck: {},
  sectionDuplicate: {},

  defaultSections: [],
  defaultSectionLoad: false,
  sectionLoad: false,
}

const GrowPage = (state = INIT_STATE, action) => {
  switch (action.type) {
    // GrowPage - This line cannot be edited or removed
    case GET_GROWPAGES:
    case GET_GROWPAGE_DETAILS:
    case CREATE_GROWPAGE:
    case UPDATE_GROWPAGE:
    case DELETE_GROWPAGE:
      return {
        ...state,
        loading: true,
      }

    case CREATE_SECTION:
    case DELETE_SECTION:
    case UPDATE_SECTION:
      return {
        ...state,
        sectionLoad: true,
      }

    case GET_DEFAULT_SECTION:
      return {
        ...state,
        defaultSectionLoad: true,
      }
    case GET_DEFAULT_SECTION_FAIL:
      return {
        ...state,
        defaultSectionLoad: false,
        error: action.payload,
      }
    case GET_DEFAULT_SECTION_SUCCESS:
      return {
        ...state,
        defaultSectionLoad: false,
        defaultSections: action.payload,
      }

    case DELETE_GROWPAGE_FAIL:
    case GET_GROWPAGES_FAIL:
    case GET_GROWPAGE_DETAILS_FAIL:
    case UPDATE_GROWPAGE_FAIL:
    case CREATE_GROWPAGE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case CREATE_SECTION_FAIL:
    case UPDATE_SECTION_FAIL:
    case DELETE_SECTION_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        sectionLoad: false,
      }

    case CHECK_PAGE_TITLE:
      return {
        ...state,
        loading: true,
      }
    case CHECK_PAGE_TITLE_SUCCESS:
      return {
        ...state,
        pageTitleCheck: action.payload,
        loading: false,
      }

    case CHECK_PAGE_TITLE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_DUPLICATE_SECTION:
      return {
        ...state,
      }
    case GET_DUPLICATE_SECTION_SUCCESS:
      return {
        ...state,
        growpageDetails: {
          ...state.growpageDetails,
          sections: [action.payload, ...state.growpageDetails.sections],
        },
        loading: false,
      }

    case GET_DUPLICATE_SECTION_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case CREATE_SECTION_SUCCESS:
      return {
        ...state,
        sectionLoad: false,
        section: action.payload,
        growpageDetails: {
          ...state.growpageDetails,
          sections: [...state.growpageDetails.sections, action.payload],
        },
      }
    case UPDATE_SECTION_SUCCESS:
      return {
        ...state,
        sectionLoad: false,
        growpageDetails: {
          ...state.growpageDetails,
          sections: state?.growpageDetails?.sections?.map(item =>
            item?._id === action?.payload?._id ? { ...action?.payload } : item
          ),
        },
      }
    case DELETE_SECTION_SUCCESS:
      return {
        ...state,
        sectionLoad: false,
        growpageDetails: {
          ...state.growpageDetails,
          sections: state?.growpageDetails?.sections?.filter(
            section => section?._id !== action.payload
          ),
        },
      }

    case GET_GROWPAGES_SUCCESS:
      return {
        ...state,
        growpages: action.payload,
        error: {},
        loading: false,
      }

    case GET_GROWPAGE_DETAILS_SUCCESS:
      return {
        ...state,
        growpageDetails: action.payload,
        error: {},
        loading: false,
      }

    case CREATE_GROWPAGE_SUCCESS:
      return {
        ...state,
        growpages: {
          ...state.growpages,
          growpages: [action.payload, ...state.growpages.growpages],
          total: state.growpages.total + 1,
        },
        growpageDetails: action.payload,
        error: {},
        loading: false,
      }

    case UPDATE_GROWPAGE_SUCCESS:
      return {
        ...state,
        growpages: {
          ...state.growpages,
          growpages: state.growpages.growpages.map(growpage =>
            growpage._id === action.payload._id
              ? { ...growpage, ...action.payload }
              : growpage
          ),
        },
        growpageDetails: action.payload,
        loading: false,
        error: {},
      }

    case DELETE_GROWPAGE_SUCCESS:
      return {
        ...state,
        growpages: {
          ...state.growpages,
          growpages: state.growpages.growpages.filter(
            growpage => growpage._id !== action.payload._id
          ),
        },
        error: {},
        loading: false,
      }
    default:
      return state
  }
}

export default GrowPage
