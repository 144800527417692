import React, { Fragment, useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Spinner,
  FormGroup,
  Badge,
  UncontrolledTooltip,
} from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import Select from "react-select"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import { debounce, map, range } from "lodash"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

//actions
import { deleteClient, getClients } from "store/actions"

import "../../assets/scss/datatables.scss"

import MyPagination from "components/Common/MyPagination"
import DeleteModal from "components/Common/DeleteModal"
import { AvField, AvForm } from "availity-reactstrap-validation"
import moment from "moment"
import ClientModal from "./Crud/Modal"

const Clients = () => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [clientModal, setClientModal] = useState({ status: false, data: {} })
  const [sort, setSort] = useState("all")

  const { loading, prodLoading, Clients } = useSelector(state => ({
    loading: state.Client.loading,
    Clients: state.Client.clients,

    prodLoading: state.Products.loading,
  }))
  console.log(Clients)
  const totalPages = Math.ceil(Clients?.total / 10)
  const pages = range(1, totalPages + 1)

  useEffect(() => {
    dispatch(getClients(page, sort, 10, ""))
  }, [dispatch, page, sort])

  const [isOpen, setIsOpen] = useState({ status: false, id: "" })

  const handleDelete = id => {
    setIsOpen({ status: true, id: id })
  }

  const handleDeleteEvent = () => {
    setIsOpen({ ...isOpen, status: false })
    dispatch(deleteClient(isOpen?.id))
  }

  const columns = [
    {
      dataField: "name",
      text: "User Name",
      sort: true,
    },
    {
      dataField: "phone",
      text: "Phone",
      sort: true,
    },
    {
      dataField: "createdAt",
      text: "Created At",
    },
    // {
    //   dataField: "message",
    //   text: "Message",
    // },
    {
      dataField: "action",
      text: "Actions",
    },
  ]

  const tableData = map(Clients?.clients, (item, index) => ({
    ...item,

    message: (
      <div style={{ whiteSpace: "break-spaces" }}>
        {item?.message?.slice(0, 110)}
        {item?.message?.length > 110 ? "..." : ""}
      </div>
    ),
    createdAt: moment(item.createdAt).format("DD/MM/YYYY - h:mm a"),
    action: (
      <div>
        <Link
          to={`#`}
          id={`edit${index}`}
          className="btn-md me-3"
          onClick={() => setClientModal({ status: true, data: item })}
        >
          <i className="bx bx-edit" style={{ fontSize: "1.3rem" }} />
        </Link>
        <Link
          to={`/client/${item?._id}`}
          id={`view${index}`}
          className="btn-md me-3"
          // onClick={() => setClientModal({ status: true, data: item })}
        >
          <i
            className="mdi mdi-eye"
            style={{ color: "green", fontSize: "20px" }}
          />
        </Link>
        <Link
          // id={`Delete${index}`}
          // type="button"
          // color="white"
          to={"#"}
          id={`delete${index}`}
          className="btn-md "
          onClick={() => {
            handleDelete(item._id)
          }}
        >
          <i
            className="bx bx-trash"
            style={{ color: "red", fontSize: "1.3rem" }}
          />
        </Link>
        <UncontrolledTooltip placement="bottom" target={`edit${index}`}>
          Edit
        </UncontrolledTooltip>
        <UncontrolledTooltip placement="bottom" target={`view${index}`}>
          View
        </UncontrolledTooltip>
        <UncontrolledTooltip placement="bottom" target={`delete${index}`}>
          Delete
        </UncontrolledTooltip>
      </div>
    ),
  }))
  const tableTitles = [
    {
      title: "All",
      value: "all",
    },

    {
      title: "Deleted",
      value: "deleted",
    },
  ]

  return (
    <React.Fragment>
      <Row>
        <DeleteModal
          show={isOpen?.status}
          onCloseClick={() => setIsOpen({ ...isOpen, status: false })}
          onDeleteClick={handleDeleteEvent}
        />
        <ClientModal
          onCloseClick={() => setClientModal({ status: false, data: {} })}
          show={clientModal?.status}
          updateData={clientModal?.data}
        />
        <Col className="col-12">
          <Card>
            <CardBody>
              <ToolkitProvider
                keyField="_id"
                key={"id"}
                columns={columns}
                data={tableData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col sm="4">
                        <div
                          className="btn-group mt-2 mt-xl-0"
                          role="group"
                          aria-label="Basic radio toggle button group"
                        >
                          {map(tableTitles, (head, key) => (
                            <Fragment key={key}>
                              <input
                                type="radio"
                                className="btn-check"
                                name="btnradio"
                                id={head.value + key}
                                autoComplete="off"
                                checked={sort === head.value}
                                onChange={() => setSort(head?.value || "all")}
                              />
                              <label
                                className="btn btn-success d-flex align-items-center w-100"
                                htmlFor={head.value + key}
                                style={{
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {head?.title}

                                <Badge
                                  className={`font-size-12 ms-2 ${
                                    sort === head.value
                                      ? "badge-soft-warning"
                                      : "badge-soft-light"
                                  }`}
                                  pill
                                >
                                  {head?.count}
                                </Badge>
                              </label>
                            </Fragment>
                          ))}
                        </div>
                        {/* <div className="search-box ms-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <AvForm>
                              <AvField
                                name="searchText"
                                placeholder="Search"
                                type="text"
                                value={searchText}
                                onChange={e =>
                                  debounceReviewSearch(e.target.value)
                                }
                              />
                            </AvForm>
                            <i className="bx bx-search-alt search-icon" />
                          </div>
                        </div> */}
                        {/* <div className="search-box ms-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <SearchBar {...toolkitProps.searchProps} />
                            <i className="bx bx-search-alt search-icon" />
                          </div>
                        </div> */}
                      </Col>
                      {/* <Col lg={4}>
                        {" "}
                        <FormGroup className="mb-3">
                          <div className="ajax-select mt-lg-0 select2-container">
                            <Select
                              onInputChange={handleProductEnters}
                              value={productSelect?.title}
                              placeholder={productSelect?.title}
                              onChange={handlerProductFinalValue}
                              options={modifiedOption}
                              classNamePrefix="select2-selection"
                              isLoading={prodLoading}
                            />
                          </div>
                        </FormGroup>
                      </Col> */}
                      <Col sm="8 " lg={8}>
                        <div className="text-sm-end">
                          <Button
                            type="button"
                            color="success"
                            className="btn-rounded  mb-2 me-2"
                            onClick={() => setClientModal({ status: true })}
                          >
                            <i className="mdi mdi-plus me-1" />
                            Create Client
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        {Clients?.total >= 1 ? (
                          <>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    key={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    // selectRow={selectRow}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    // onTableChange={handleTableChange}
                                    // {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <MyPagination
                              totalPages={pages}
                              page={page}
                              setPage={setPage}
                            />
                          </>
                        ) : (
                          <p className="my-4 mx-3 ">No Clients !</p>
                        )}
                      </>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default Clients
