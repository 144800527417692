import { MdOutlineKeyboardArrowRight } from "react-icons/md"
import {
  CardHeader,
  CardTitle,
  Collapse,
  FormGroup,
  Label,
  UncontrolledTooltip,
} from "reactstrap"
import { RiNotificationBadgeFill } from "react-icons/ri"
import { useDispatch, useSelector } from "react-redux"
import { Button, Card, CardBody } from "reactstrap"
import { Swiper, SwiperSlide } from "swiper/react"
import React, { useEffect, useRef, useState } from "react"
import { Row, Col, Container } from "reactstrap"
import { useParams } from "react-router"
import { FaTimes, FaTrash } from "react-icons/fa"
import { Link } from "react-router-dom"
import ReactPlayer from "react-player"
import classnames from "classnames"
import { debounce, filter, map } from "lodash"
import { WithContext as ReactTags } from "react-tag-input"
import Select from "react-select"
import { OffSymbol, OnSymbol } from "pages/Product/Crud/Create/Create"
import ReactDragListView from "react-drag-listview/lib/index.js"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import useCloudinary from "hooks/useCloudinaryImage"
import MetaTag from "components/Common/Meta-tag"
import MyRating from "components/Common/Rating"
import AddSectionModal from "./AddSectionModal"
import rgbHex from "rgb-hex"
import "swiper/swiper.scss"

//actions
import {
  checkPageTitle,
  deleteSection,
  getAllProducts,
  getDuplicateSection,
  getGrowPageDetails,
  updateGrowPage,
  updatePages,
  updateSection,
  getSections,
} from "store/actions"
import { defaultImg } from "assets/images"
import UseImageUploader from "components/imageUploader/useImageUploader"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { Notification } from "components/Common/Notification"
import FormEditors from "pages/Product/Crud/Create/Editor"
import { Markup } from "interweave"
import SectionUi from "./SectionUi"
import AddDefaultSectionModal from "./AddDefaultSectionModal"
import useGetStore from "hooks/useGetStore"
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min"
import Switch from "react-switch"
import { ChromePicker } from "react-color"

const UpdatePages = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const history = useHistory()
  const { isStoreAdmin } = useGetStore()
  const location = useLocation()
  const { growpageDetails, proLoading, section, pageTitleCheck, products } =
    useSelector(state => ({
      growpageDetails: state.GrowPage.growpageDetails,
      section: state.GrowPage.section,
      pageTitleCheck: state.GrowPage?.pageTitleCheck,
      products: state.Products.products,
      proLoading: state.Products.loading,
    }))

  const productDetails = growpageDetails?.product
  const storeId = growpageDetails?.storeName

  const [sectionModal, setSectionModal] = useState({ status: false, id: "" })
  const [defaultSectionModal, setDefaultSectionModal] = useState(false)
  const [isNavigationButtonNeeded, setisNavigationButtonNeeded] =
    useState(false)

  const [pageImage, setPageImage] = useState([])
  const [iconImage, setIconImage] = useState([])
  const [tags, setTags] = useState([])
  const [keywords, setKeywords] = useState([])
  const [BackgroundColor, setBackgroundColor] = useState("")
  const [ForegroundColor, setForegroundColor] = useState("")
  useEffect(() => {
    dispatch(getGrowPageDetails(params?.id))
  }, [params?.id])

  console.log("iconImage", iconImage)

  const [data, SetData] = useState([])

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const modifiedData = [...data]
      const item = modifiedData.splice(fromIndex, 1)[0]
      modifiedData.splice(toIndex, 0, item)
      SetData(modifiedData)
      const pageUpdateData = {
        ...growpageDetails,
        store: growpageDetails?.store?._id,
        product: growpageDetails?.product?._id,
        sections: modifiedData?.map(item => item?._id),
        keywords,
      }
      dispatch(updateGrowPage(pageUpdateData, params?.id))
    },
    nodeSelector: "tr",
    handleSelector: "tr",
  }
  useEffect(() => {
    setPageImage(
      growpageDetails?.images?.map(i => ({
        public_id: i.public_id,
        url: i.url,
      }))
    )
  }, [growpageDetails?.images])

  useEffect(() => {
    SetData(growpageDetails?.sections)
  }, [growpageDetails?.sections])

  const handleSectionId = () => {
    const sections = growpageDetails?.sections?.map(item => item?._id)
    if (sections?.includes(section?._id) === true) {
      return sections
    } else {
      return [...sections, section?._id]
    }
  }

  useEffect(() => {
    if (params?.id && section?._id) {
      const pageUpdateData = {
        ...growpageDetails,

        product: growpageDetails?.product?._id,
        store: growpageDetails?.store?._id,
        sections: section?._id
          ? handleSectionId()
          : growpageDetails?.sections?.map(item => item?._id),
      }
      dispatch(updateGrowPage(pageUpdateData, params?.id))
    }
  }, [section?._id, params?.id])

  const [pageTitle, setPageTitle] = useState()

  const [productSelect, setproductSelect] = useState({})
  const [productSearch, setProductSearch] = useState()

  // console.log(productSearch?.searchText)

  const debounceProductSearch = debounce(value => setProductSearch(value), 600)
  const handleProductEnters = textEntered => {
    debounceProductSearch({
      ...productSelect,
      searchText: textEntered,
    })
  }

  function handlerProductFinalValue(event) {
    const product = {
      ...productSelect,
      title: event.label,
      id: event.value,
      image: event.image,
      varients: event.varients,
    }
    setproductSelect(product)
  }
  const rgbtohex = c => {
    console.log(c)
    if (c?.r) {
      return "#" + rgbHex(c?.r, c?.g, c?.b, c?.a)
    }
  }
  const productOptions = [
    {
      options: (products?.length >= 1 ? products : [])?.map(
        (result, index) => ({
          key: index,
          label: `${result?.primaryLang?.name} ${
            result.hasVarients
              ? result?.varients?.length >= 1
                ? `(${result?.varients[0]?.value} ${result?.varients[0]?.unit})`
                : ""
              : ""
          }`,
          value: result?._id,
          image: result?.images[0]?.url,
        })
      ),
    },
  ]

  useEffect(() => {
    dispatch(getAllProducts(1, "all", "", productSearch?.searchText, storeId))
  }, [dispatch, productSearch?.searchText, storeId])

  useEffect(() => {
    if (productDetails?._id) {
      const product = {
        ...productSelect,
        title: productDetails?.primaryLang?.name,
        id: productDetails?._id,
        image: productDetails?.images,
        varients: productDetails?.varients,
      }
      setproductSelect(product)
    }
  }, [growpageDetails])

  const debouncePageTitle = debounce(value => setPageTitle(value), 600)

  function handleValidSubmit(e, v) {
    const updateData = {
      ...v,
      // store: growpageDetails?.store?._id,
      groweb: growpageDetails?.groweb,
      sections: growpageDetails?.sections?.map(item => item?._id),
      product: productSelect?.id,
      description: v?.description,
      title: v?.title,
      images: pageImage,
      keywords: keywords,
      isNavigationButtonNeeded: isNavigationButtonNeeded,
      navigationButton: {
        text: v?.text,
        icon: iconImage,
        link: v?.link,
        foregroundColor:
          ForegroundColor?.hex ||
          growpageDetails?.navigationButton?.foregroundColor,
        backgroundColor:
          BackgroundColor?.hex ||
          growpageDetails?.navigationButton?.backgroundColor,
      },
    }

    dispatch(updateGrowPage(updateData, growpageDetails?._id, history))
    // Notification({
    //   type: "success",
    //   message: "page Updated Successfully!",
    //   title: "",
    // })
  }

  const KeyCodes = {
    comma: 188,
    enter: 13,
  }

  const delimiters = [KeyCodes.comma, KeyCodes.enter]

  const handleDeleteTag = i => {
    const modifiedTag = tags.filter((tag, index) => index !== i)
    setTags(modifiedTag)

    const arrangedKeywords = modifiedTag.map(a => a.text)
    setKeywords(arrangedKeywords)
  }

  const handleAddition = tag => {
    setTags([...tags, tag])
    const arrangedKeywords = [...tags, tag].map(a => a.text)
    setKeywords(arrangedKeywords)
  }

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice()
    newTags.splice(currPos, 1)
    newTags.splice(newPos, 0, tag)
    // re-render
    setTags(newTags)
    const arrangedKeywords = newTags.map(a => a.text)
    setKeywords(arrangedKeywords)
  }

  const handleTagClick = index => {
    console.log("The tag at index " + index + " was clicked")
  }

  useEffect(() => {
    setKeywords(growpageDetails?.keywords)

    setIconImage(growpageDetails?.navigationButton?.icon || [])
    setBackgroundColor(growpageDetails?.navigationButton?.backgroundColor)
    setForegroundColor(growpageDetails?.navigationButton?.foregroundColor)
    setisNavigationButtonNeeded(growpageDetails?.isNavigationButtonNeeded)
  }, [growpageDetails])

  useEffect(() => {
    const keyWordList = growpageDetails?.keywords

    setTags(keyWordList?.map(i => ({ id: i, text: i })))
    setKeywords(keyWordList)
  }, [growpageDetails])

  useEffect(() => {
    if (growpageDetails?._id && pageTitle?.length >= 1) {
      dispatch(checkPageTitle(pageTitle, growpageDetails?._id))
    }
  }, [pageTitle])

  const commonUpdateData = {
    ...growpageDetails,
    sections: growpageDetails?.sections?.map(item => item?._id),
    product: growpageDetails?.product?._id,
    store: growpageDetails?.store?._id,
  }

  const updatePageWithNewSection = (sectionId, type) => {
    if (type === "remove") {
      const updateData = {
        ...growpageDetails,
        sections: growpageDetails?.sections?.filter(i => i._id !== sectionId),
        product: growpageDetails?.product?._id,
        store: growpageDetails?.store?._id,
      }
      dispatch(updateGrowPage(updateData, params?.id))
    } else {
      const updateData = {
        ...growpageDetails,
        sections: [
          ...growpageDetails?.sections?.map(item => item?._id),
          sectionId,
        ],
        product: growpageDetails?.product?._id,
        store: growpageDetails?.store?._id,
      }
      dispatch(
        updateGrowPage(updateData, params?.id, "", setDefaultSectionModal)
      )
    }
  }

  const handleDuplicate = sectionId => {
    dispatch(getDuplicateSection(sectionId, updatePageWithNewSection))
  }

  const updateProductWhenImageChange = (newImageId, type, newImageObj) => {
    if (type === "remove") {
      const newImage = pageImage?.filter(i => i?.public_id !== newImageId)
      if (newImageId && newImage) {
        const updateData = {
          ...commonUpdateData,
          images: newImage,
        }
        dispatch(updateGrowPage(updateData, params.id))
      }
    }
    if (type === "add") {
      const newImage = [...pageImage, newImageObj]
      if (newImageId && newImage) {
        const updateData = {
          ...commonUpdateData,
          images: newImage,
        }
        dispatch(updateGrowPage(updateData, params.id))
      }
    }
  }

  return (
    <>
      <MetaTag title={"Update GrowPage"} />
      <div className="page-content">
        <Breadcrumbs
          title="GrowPage Details"
          breadcrumbItem="Update GrowPage"
        />
        <Container fluid>
          <div className="container-fluid">
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <div className="d-flex col-6 align-items-center justify-content-between mb-4">
                      <AvForm
                        onValidSubmit={(e, v) => {
                          handleValidSubmit(e, v)
                        }}
                        className="w-100"
                      >
                        <div className="d-flex flex-column">
                          {/* {!isStoreAdmin && (
                            <div className=" mb-3">
                              <AvField
                                value={growpageDetails?.store?.storeName || ""}
                                name="store"
                                className=""
                                label="Store"
                                type="text"
                              />
                            </div>
                          )}{" "} */}
                          <div className=" mb-3">
                            <AvField
                              value={growpageDetails?.title}
                              name="title"
                              className=""
                              label="Page Title"
                              type="text"
                              onChange={e => {
                                debouncePageTitle(e.target.value)
                              }}
                            />
                            {pageTitle && (
                              <>
                                {pageTitleCheck?.response === false && (
                                  <h6 className="pt-1" style={{ color: "red" }}>
                                    Not Available{" "}
                                  </h6>
                                )}
                              </>
                            )}
                          </div>{" "}
                          <div className=" mb-3">
                            <p
                              className="mb-2 font-size-13"
                              style={{ fontWeight: 500 }}
                            >
                              Select Product
                            </p>
                            <FormGroup className="mb-3">
                              <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                                <Select
                                  onInputChange={handleProductEnters}
                                  value={productSelect?.title}
                                  placeholder={productSelect?.title}
                                  onChange={handlerProductFinalValue}
                                  options={productOptions}
                                  classNamePrefix="select2-selection"
                                  isLoading={proLoading}
                                />
                              </div>
                            </FormGroup>
                          </div>
                          <div className=" mb-3">
                            <AvField
                              style={{ height: 100 }}
                              name="description"
                              value={growpageDetails?.description}
                              className=""
                              label="Description"
                              type="textarea"
                            />
                          </div>
                          <div className="  pb-3">
                            <label>Keywords</label>
                            <ReactTags
                              tags={tags}
                              placeholder="Type TEXT and enter"
                              // suggestions={suggestions}
                              delimiters={delimiters}
                              handleDelete={handleDeleteTag}
                              handleAddition={handleAddition}
                              handleDrag={handleDrag}
                              handleTagClick={handleTagClick}
                              // inputFieldPosition="inline"
                              autocomplete
                              allowUnique
                              inline
                            />
                          </div>
                          <div className="  pb-2 mt-2">
                            {/* <UseImageUploader
                              imageSize={4}
                              imagesPreview={pageImage} //array
                              setImagesPreview={setPageImage}
                              uploadUrl={"/pages"}
                              onChangeUpdate={updateProductWhenImageChange}
                            /> */}
                            <Label> SEO Site Image</Label>
                            <UseImageUploader
                              imageSize={4}
                              imagesPreview={pageImage}
                              setImagesPreview={setPageImage}
                              uploadUrl={"/growebosperb"}
                              isMultiple
                            />
                          </div>
                        </div>
                        <div className="align-items-center d-flex mb-3 mt-3">
                          <Label check for="checkbox">
                            {" "}
                            Is Navigator
                          </Label>
                          <Switch
                            uncheckedIcon={<OffSymbol />}
                            checkedIcon={<OnSymbol />}
                            className="me-1 mb-sm-8 mx-3"
                            onColor="#626ed4"
                            onChange={v => {
                              setisNavigationButtonNeeded(v)
                            }}
                            checked={isNavigationButtonNeeded}
                          />
                        </div>
                        {isNavigationButtonNeeded && (
                          <>
                            <Row>
                              <Col lg={6}>
                                <AvField
                                  value={
                                    growpageDetails?.navigationButton?.text
                                  }
                                  name="text"
                                  className=""
                                  label="text"
                                  type="text"
                                />
                              </Col>
                              <Col lg={6}>
                                <AvField
                                  value={
                                    growpageDetails?.navigationButton?.link
                                  }
                                  name="link"
                                  className=""
                                  label="link"
                                  type="text"
                                />
                              </Col>
                            </Row>
                            <Row className="mt-2">
                              <Label> Icon</Label>
                              <UseImageUploader
                                imageSize={4}
                                imagesPreview={iconImage}
                                setImagesPreview={setIconImage}
                                uploadUrl={"/growebosperb"}
                              />
                            </Row>
                            <Row>
                              <Col>
                                {/* Shipping Inputs */}

                                <Col lg={12}>
                                  <div className="d-flex mt-5 color-picker mx-4">
                                    <div className="">
                                      <p>Background Color</p>
                                      <ChromePicker
                                        width="180px"
                                        className="me-3"
                                        onChange={color =>
                                          setBackgroundColor(color)
                                        }
                                        color={BackgroundColor}
                                      />
                                    </div>
                                    <div>
                                      <p>Foreground Color</p>
                                      <ChromePicker
                                        width="180px"
                                        className="me-3"
                                        onChange={color =>
                                          setForegroundColor(color)
                                        }
                                        color={ForegroundColor}
                                      />
                                    </div>
                                  </div>
                                </Col>
                              </Col>
                            </Row>
                          </>
                        )}
                        <div className="d-flex align-items-end justify-content-end w-100 mt-2">
                          <Button
                            type="submit"
                            color="success"
                            className="px-5"
                          >
                            Update
                          </Button>
                        </div>
                      </AvForm>
                    </div>
                    <div className="col-6 px-3">
                      <div className="d-flex col-6 justify-content-end w-100">
                        {" "}
                        <div className="form-check form-switch form-switch-md me-4">
                          <label
                            className="form-check-label"
                            htmlFor="isPageActiveSwitch"
                          >
                            {growpageDetails?.isActive ? `Active` : `Inactive`}
                          </label>
                          <input
                            type="checkbox"
                            className={
                              "form-check-input " +
                              `${growpageDetails?.isActive && "bg-success"}`
                            }
                            id="isPageActiveSwitch"
                            style={{
                              border: growpageDetails?.isActive && "none",
                            }}
                            onChange={e => {
                              dispatch(
                                updateGrowPage(
                                  {
                                    ...growpageDetails,
                                    product: growpageDetails?.product?._id,
                                    sections: growpageDetails?.sections?.map(
                                      item => item?._id
                                    ),
                                    isActive: e.target.checked,
                                  },
                                  params?.id
                                )
                              )
                            }}
                            checked={growpageDetails?.isActive}
                          />
                        </div>
                        <div className="form-check form-switch form-switch-md me-4">
                          <label
                            className="form-check-label"
                            htmlFor="isHomeActiveSwitch"
                          >
                            {growpageDetails?.isHomepage
                              ? `Home Page`
                              : `Home Page`}
                          </label>
                          <input
                            type="checkbox"
                            className={
                              "form-check-input " +
                              `${growpageDetails?.isHomepage && "bg-success"}`
                            }
                            id="isHomeActiveSwitch"
                            style={{
                              border: growpageDetails?.isHomepage && "none",
                            }}
                            onChange={e => {
                              dispatch(
                                updateGrowPage(
                                  {
                                    ...growpageDetails,
                                    store: growpageDetails?.store?._id,
                                    product: growpageDetails?.product?._id,
                                    sections: growpageDetails?.sections?.map(
                                      item => item?._id
                                    ),
                                    isHomepage: e.target.checked,
                                  },
                                  params?.id
                                )
                              )
                            }}
                            checked={growpageDetails?.isHomepage}
                          />
                        </div>
                      </div>
                      <div className="col-6 w-100 p-5 py-0">
                        {productDetails?._id ? (
                          <>
                            <CardTitle className="h6 text_deals_green">
                              Product Details
                            </CardTitle>
                            <div className="w-100">
                              <img
                                className="me-4"
                                style={{ borderRadius: 5 }}
                                src={productDetails?.images[0]?.url}
                                height={"80%"}
                                width={"80%"}
                              />
                            </div>

                            <div className="w-100">
                              <p className="mb-1 p-0">
                                {productDetails?.itemCode}
                              </p>
                              <h5>{productDetails?.primaryLang?.name}</h5>
                              {productDetails?.offerPrice ? (
                                <p>
                                  <span
                                    style={{
                                      fontSize: 15,
                                      fontWeight: "bold",
                                    }}
                                  >
                                    ₹{productDetails?.offerPrice}
                                  </span>{" "}
                                  <del>₹{productDetails?.price}</del>
                                </p>
                              ) : (
                                <p>₹{productDetails?.price}</p>
                              )}
                              <Link
                                to={`/product/update/${productDetails?._id}`}
                              >
                                <Button
                                  type="button"
                                  color="light"
                                  className="btn text-info d-flex align-items-center "
                                >
                                  View Full Details{" "}
                                  <MdOutlineKeyboardArrowRight
                                    size={"1.2rem"}
                                    className="mx-2"
                                  />
                                </Button>
                              </Link>
                            </div>
                          </>
                        ) : (
                          <>
                            <p className="mx-2 text-warning">
                              No Product Found !
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>

            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col className="col-12">
                      <div className="w-100 d-flex align-items-center justify-content-between">
                        <CardTitle className="h4 mb-4 text_deals_green">
                          Selected Sections
                        </CardTitle>

                        <div>
                          <button
                            type="button"
                            onClick={() => setDefaultSectionModal(true)}
                            className="btn btn-light save-customer me-4"
                          >
                            Add Default Section
                          </button>
                          <button
                            type="button"
                            onClick={() =>
                              setSectionModal({
                                status: true,
                                id: params?.id,
                                data: { isCreate: true },
                              })
                            }
                            className="btn btn-light save-customer"
                          >
                            Add New Section
                          </button>
                        </div>
                      </div>

                      <div className="table-responsive mt-5">
                        <ReactDragListView {...dragProps}>
                          {(growpageDetails?.sections || [])?.map(
                            (item, index) => (
                              <tr key={index} className="d-flex w-100 mb-4">
                                <Card
                                  outline
                                  color="light"
                                  className="border w-100 m-0 drag-pointer grabbable"
                                >
                                  <CardHeader className="bg-transparent d-flex justify-content-between">
                                    <h5
                                      className="my-0 text-success d-flex align-items-center "
                                      style={{ minWidth: 150 }}
                                    >
                                      <RiNotificationBadgeFill
                                        className=""
                                        height={16}
                                        width={16}
                                      />
                                      <span
                                        className="ms-2"
                                        style={{
                                          textTransform: "capitalize",
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        {handleLayoutText(item?.layout)}
                                      </span>
                                    </h5>
                                    <div className="d-flex align-items-center justify-content-between w-100 ms-3">
                                      <div className="d-flex">
                                        <div className="form-check form-switch form-switch-md me-4">
                                          <label
                                            className="form-check-label"
                                            htmlFor="isActiveSwitch"
                                          >
                                            {item?.isActive
                                              ? `Active`
                                              : `Inactive`}
                                          </label>
                                          <input
                                            type="checkbox"
                                            className={
                                              "form-check-input " +
                                              `${
                                                item?.isActive && "bg-success"
                                              }`
                                            }
                                            id="isActiveSwitch"
                                            style={{
                                              border: item?.isActive && "none",
                                            }}
                                            onChange={e => {
                                              dispatch(
                                                updateSection(
                                                  {
                                                    ...item,
                                                    isActive: e.target.checked,
                                                  },
                                                  item?._id,
                                                  false,
                                                  growpageDetails?._id
                                                )
                                              )
                                            }}
                                            checked={item?.isActive}
                                          />
                                        </div>
                                        <div className="form-check form-switch form-switch-md me-4">
                                          <label
                                            className="form-check-label"
                                            htmlFor="isDefaultSwitch"
                                          >
                                            {item?.isDefault
                                              ? `Default`
                                              : `Default`}
                                          </label>
                                          <input
                                            type="checkbox"
                                            className={
                                              "form-check-input " +
                                              `${
                                                item?.isDefault && "bg-success"
                                              }`
                                            }
                                            id="isDefaultSwitch"
                                            style={{
                                              border: item?.isDefault && "none",
                                            }}
                                            onChange={e => {
                                              dispatch(
                                                updateSection(
                                                  {
                                                    ...item,
                                                    isDefault: e.target.checked,
                                                  },
                                                  item?._id,
                                                  false,
                                                  growpageDetails?._id
                                                )
                                              )
                                            }}
                                            checked={item?.isDefault}
                                          />
                                        </div>
                                      </div>
                                      <div className="d-flex align-items-center justify-content-between">
                                        <div className="d-flex ">
                                          <Button
                                            id={`view${index}`}
                                            type="button"
                                            color="white"
                                            className="btn d-flex align-items-center "
                                            onClick={() =>
                                              setSectionModal({
                                                status: true,
                                                id: params?.id,
                                                data: {
                                                  ...item,
                                                  isUpdate: true,
                                                },
                                              })
                                            }
                                          >
                                            <i
                                              className="bx bx-edit me-2 text-info"
                                              style={{ fontSize: "22px" }}
                                            />
                                          </Button>
                                          <UncontrolledTooltip
                                            placement="bottom"
                                            target={`duplicate${index}`}
                                          >
                                            duplicate
                                          </UncontrolledTooltip>
                                          <Button
                                            onClick={() =>
                                              handleDuplicate(item?._id)
                                            }
                                            color="white"
                                            id={`duplicate${index}`}
                                          >
                                            <i className="bx bx-copy font-size-22" />
                                          </Button>
                                          {item?.isDefault ? (
                                            <Button
                                              id={`view${index}`}
                                              type="button"
                                              color="white"
                                              className="btn d-flex align-items-center "
                                              onClick={() =>
                                                updatePageWithNewSection(
                                                  item?._id,
                                                  "remove"
                                                )
                                              }
                                              title="Remove From This Page"
                                            >
                                              <FaTimes
                                                className="text-danger me-2"
                                                size={"1rem"}
                                              />
                                            </Button>
                                          ) : (
                                            <Button
                                              id={`view${index}`}
                                              type="button"
                                              color="white"
                                              className="btn d-flex align-items-center "
                                              onClick={() =>
                                                dispatch(
                                                  deleteSection(item?._id)
                                                )
                                              }
                                              title="Delete From All Section"
                                            >
                                              <FaTrash
                                                className="text-danger me-2"
                                                size={"1rem"}
                                              />
                                            </Button>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </CardHeader>
                                  <SectionUi item={item} />
                                </Card>
                              </tr>
                            )
                          )}
                        </ReactDragListView>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>

            <AddSectionModal
              show={sectionModal?.status}
              onCloseClick={() =>
                setSectionModal({ ...sectionModal, status: false })
              }
              data={sectionModal}
            />

            <AddDefaultSectionModal
              show={defaultSectionModal}
              onCloseClick={() => setDefaultSectionModal(false)}
              updatePage={updatePageWithNewSection}
            />
          </div>
        </Container>
      </div>
    </>
  )
}

export default UpdatePages

export const LayoutData = [
  {
    id: 1,
    title: "HIGHLIGHT",
    value: "highlight",
  },
  {
    id: 3,
    title: "SHORT CONTENT",
    value: "shortContent",
  },
  {
    id: 4,
    title: "WIDE CONTENT",
    value: "wideContent",
  },
  {
    id: 5,
    title: " CAROUSEL",
    value: "carousel",
  },
  {
    id: 6,
    title: "IMAGE GRID",
    value: "imageGrid",
  },
  {
    id: 7,
    title: "VIDEO",
    value: "video",
  },
  {
    id: 8,
    title: "PRODUCT VARIANT",
    value: "productVariant",
  },
  {
    id: 9,
    title: "TESTIMONIALS",
    value: "testimonials",
  },
  {
    id: 10,
    title: "FAQ",
    value: "faqData",
  },
]
export const VarientLayoutData = [
  {
    id: 1,
    title: " CAROUSEL",
    value: "carousel",
  },
  {
    id: 2,
    title: "List",
    value: "list",
  },
]

export const handleLayoutText = layout => {
  switch (layout) {
    case "shortContent":
      return "Short Content"
    case "wideContent":
      return "Wide Content"
    case "imageGrid":
      return "Image Grid"
    case "productVariant":
      return "Product Variant"
    case "faqData":
      return "FAQ Data"

    default:
      return layout
  }
}
