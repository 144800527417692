import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"

//Product
import Products from "./products/reducer"

//user
import Users from "./users/reducer"

//Banner
import Banner from "./banner/reducer"

//Dashboard
import Dashboard from "./dashboard/reducer"

//pages
// import Pages from "./productPages/reducer"

// blog
import Blogs from "./blogs/reducer"
// client
import Client from "./client/reducer"
//GroWeb
import GroWeb from "./groweb/reducer"
//Growpages
import GrowPage from "./growpage/reducer"
//sections
// import Section from "./section/reducer"
//leads
import Lead from "./lead/reducer"
//PageView
import PageView from "./pageview/reducer"
const rootReducer = combineReducers({
  PageView,
  Lead,
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  Products,

  Banner,
  // Pages,
  // Area,

  Users,
  Dashboard,
  Blogs,
  Client,
  GroWeb,
  GrowPage,
  // Section,
})

export default rootReducer
