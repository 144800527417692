import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_GROWPAGES,
  GET_GROWPAGE_DETAILS,
  CREATE_GROWPAGE,
  UPDATE_GROWPAGE,
  DELETE_GROWPAGE,
  CREATE_SECTION,
  GET_DEFAULT_SECTION,
  UPDATE_SECTION,
  DELETE_SECTION,
  CHECK_PAGE_TITLE,
  GET_DUPLICATE_SECTION,
} from "./actionTypes"
import {
  getGrowPagesFail,
  getGrowPagesSuccess,
  getGrowPageDetailsSuccess,
  getGrowPageDetailsFail,
  createGrowPageFail,
  createGrowPageSuccess,
  updateGrowPageSuccess,
  updateGrowPageFail,
  deleteGrowPageSuccess,
  deleteGrowPageFail,
  checkPageTitleFail,
  checkPageTitleSuccess,
  createSectionFail,
  createSectionSuccess,
  deleteSectionFail,
  deleteSectionSuccess,
  getDefaultSectionFail,
  getDefaultSectionSuccess,
  getDuplicateSectionFail,
  getDuplicateSectionSuccess,
  updateSectionFail,
  updateSectionSuccess,
} from "./actions"

import { get, post, ApiPut, del } from "helpers/api_helper"
import { Notification } from "../../components/Common/Notification"

// GrowPage - This line cannot be edited or removed
function getGrowPagesAPi({ page, sort, limit, groweb, searchText }) {
  return get(
    `/growpage/admin/all/?sort=${sort}&page=${page ? page : 1}&limit=${
      limit ? limit : 10
    }&groweb=${groweb ? groweb : ""}&search=${searchText ? searchText : ""}`
  )
}

const getGrowPageDetailsAPi = growpageId => {
  return get(`/growpage/admin/single/${growpageId}`)
}

const createGrowPageApi = ({ growpage }) => {
  return post("/growpage/admin/new", growpage)
}

const updateGrowPageApi = ({ growpage, growpageId }) => {
  return ApiPut(`/growpage/admin/${growpageId}`, growpage)
}

const deleteGrowPageApi = ({ growpageId }) => {
  return del(`/growpage/admin/${growpageId}`)
}

function getDefaultSectionApi() {
  return get("/section/admin/default/all")
}
function createSectionApi(section) {
  return post("/section/admin/new", section)
}
function updateSectionApi({ section, sectionId }) {
  return ApiPut(`/section/admin/${sectionId}`, section)
}
function deleteSectionApi(sectionId) {
  return del(`/section/admin/${sectionId}`, sectionId)
}

function checkPageTitleApi({ title, pageId }) {
  if (pageId) {
    return post(`/growpage/admin/check/title?page=${pageId}`, { title: title })
  } else return post(`/growpage/admin/check/title`, { title: title })
}
function duplicateSectionApi({ sectionId }) {
  return post(`/section/admin/duplicate/${sectionId}`)
}

function* fetchGrowPages({ payload }) {
  try {
    const response = yield call(getGrowPagesAPi, payload)
    yield put(getGrowPagesSuccess(response))
  } catch (error) {
    yield put(getGrowPagesFail(error))
  }
}

function* fetchGrowPageDetails({ payload: growpageId }) {
  try {
    const response = yield call(getGrowPageDetailsAPi, growpageId)
    yield put(getGrowPageDetailsSuccess(response))
  } catch (error) {
    yield put(getGrowPageDetailsFail(error))
  }
}

function* onCreateGrowPage({ payload }) {
  try {
    const response = yield call(createGrowPageApi, payload)
    if (response?.message) {
      Notification({
        type: "error",
        message: response?.message,
        title: "",
      })
    } else if (response) {
      yield put(createGrowPageSuccess(response))
      doneNotification("GrowPage Created Successfully!")
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: "error",
        message: "file size is Too Large",
        title: "",
      })
    }
    yield put(createGrowPageFail(error))
  }
}

function* onUpdateGrowPage({ payload }) {
  try {
    const response = yield call(updateGrowPageApi, payload)
    if (response) {
      Notification({
        type: "success",
        message: "GrowPage Updated Successfully!",
        title: "",
      })
      yield put(updateGrowPageSuccess(response))

      try {
        const response2 = yield call(getGrowPageDetailsAPi, payload?.growpageId)
        yield put(getGrowPageDetailsSuccess(response2))
      } catch (error) {
        yield put(getGrowPageDetailsFail(error))
      }
      // history.goBack()

      // if (payload.history) {
      //   payload.history.push(`/groWeb/update/${response?.groweb}`)
      // }
    }
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: "error",
        message: "file size is Too Large",
        title: "",
      })
    }
    yield put(updateGrowPageFail(error.response.data))
  }
}

function* onDeleteGrowPage({ payload }) {
  try {
    const response = yield call(deleteGrowPageApi, payload)
    yield put(deleteGrowPageSuccess(response))
    Notification({
      type: "success",
      message: "GrowPage Deleted Successfully!",
      title: "",
    })
    history.goBack()
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    yield put(deleteGrowPageFail(error?.response?.data))
  }
}

function* getDefaultSections({ payload }) {
  try {
    const response = yield call(getDefaultSectionApi)
    if (response) {
      yield put(getDefaultSectionSuccess(response))
    }
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    yield put(getDefaultSectionFail(error))
  }
}

function* onCreateSection({ payload }) {
  try {
    const response = yield call(createSectionApi, payload?.section)
    if (response?._id) {
      yield put(createSectionSuccess(response))
      Notification({
        type: "success",
        message: "Section Added Successfully!",
        title: "",
      })
      payload.onCloseClick()

      // try {
      //   const response2 = yield call(getGrowPageDetailsAPi, payload?.pageId)
      //   yield put(getGrowPageDetailsSuccess(response2))
      // } catch (error) {
      //   yield put(getGrowPageDetailsFail(error))
      // }
    }
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    yield put(createSectionFail(error))
  }
}

function* onUpdateSection({ payload }) {
  try {
    const response = yield call(updateSectionApi, payload)
    if (response?._id) {
      yield put(updateSectionSuccess(response))
      Notification({
        type: "success",
        message: "Section Updated Successfully!",
        title: "",
      })
      try {
        const response2 = yield call(getGrowPageDetailsAPi, payload?.pageId)
        yield put(getGrowPageDetailsSuccess(response2))
      } catch (error) {
        yield put(getGrowPageDetailsFail(error))
      }
      payload.onCloseClick()
    }
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    yield put(updateSectionFail(error))
  }
}

function* onDeleteSection({ payload }) {
  try {
    const response = yield call(deleteSectionApi, payload?.sectionId)
    yield put(deleteSectionSuccess(response?._id))
    Notification({
      type: "success",
      message: "Section Deleted Successfully!",
      title: "",
    })
  } catch (error) {
    yield put(deleteSectionFail(error))
  }
}

function* onCheckPageTitle({ payload }) {
  try {
    const response = yield call(checkPageTitleApi, payload)
    yield put(checkPageTitleSuccess(response))
  } catch (error) {
    yield put(checkPageTitleFail(error))
  }
}

function* duplicateSection({ payload }) {
  try {
    const response = yield call(duplicateSectionApi, payload)
    if (response?._id) {
      payload.handleUpdatePage(response?._id)
      yield put(getDuplicateSectionSuccess(response))
      Notification({
        type: "success",
        message: " Section Duplicated Successfully",
        title: "",
      })
    }
  } catch (error) {
    yield put(getDuplicateSectionFail(error))
    Notification({
      type: "error",
      message: error.response?.data?.message,
      title: "",
    })
  }
}

function* growpageSaga() {
  yield takeEvery(GET_GROWPAGES, fetchGrowPages)
  yield takeEvery(GET_GROWPAGE_DETAILS, fetchGrowPageDetails)
  yield takeEvery(CREATE_GROWPAGE, onCreateGrowPage)
  yield takeEvery(UPDATE_GROWPAGE, onUpdateGrowPage)
  yield takeEvery(DELETE_GROWPAGE, onDeleteGrowPage)
  yield takeEvery(CREATE_SECTION, onCreateSection)
  yield takeEvery(GET_DEFAULT_SECTION, getDefaultSections)
  yield takeEvery(UPDATE_SECTION, onUpdateSection)
  yield takeEvery(DELETE_SECTION, onDeleteSection)
  yield takeEvery(CHECK_PAGE_TITLE, onCheckPageTitle)
  yield takeEvery(GET_DUPLICATE_SECTION, duplicateSection)
}

export default growpageSaga

function doneNotification(message) {
  Notification({
    type: "success",
    message: message,
    title: "",
  })
}
