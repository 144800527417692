import { Notification } from "components/Common/Notification"
import { useEffect, useState } from "react"

export const useCopyText = () => {
  const [copy, setCopy] = useState(false)

  const copyText = (text, msg, title) => {
    console.log("text", text)
    navigator.clipboard.writeText(text)
    setCopy(true)
    Notification({
      type: "success",
      message: msg || "text copied!",
      title: title || "",
    })
  }

  useEffect(() => {
    let timer
    if (copy) {
      timer = setTimeout(() => {
        setCopy(false)
      }, 3000)
    }
    return () => clearTimeout(timer)
  }, [copy])

  return { copyText, copy }
}
