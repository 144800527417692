import React, { useState } from "react"
import { Row, Col, Spinner, Button } from "reactstrap"
import { useSelector } from "react-redux"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"

import { map, range } from "lodash"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

import "assets/scss/datatables.scss"
import { Link } from "react-router-dom"
import { LanguageSwitch } from "hooks/LanguageSwitch"
import moment from "moment"
import MyPagination from "components/Common/MyPagination"
import { CurrencySwitch } from "hooks/Currency"
import { defaultImg } from "assets/images"

const RecentViewsDatatable = () => {
  const [page, setPage] = useState(1)

  const { userFavouriteDetails, loading } = useSelector(state => ({
    userFavouriteDetails: state.Users?.userRecentViews,
    loading: state.Users.loading,
  }))

  const total = userFavouriteDetails?.length

  //pagination
  const totalPages = Math.ceil(total / 10)
  const pages = range(1, totalPages + 1)

  const PreColumns = [
    {
      dataField: "imageData",
      text: "Image",
    },
    {
      dataField: "productName",
      text: "Name",
    },
    {
      dataField: "count",
      text: "Count",
    },
    {
      dataField: "lastViewed",
      text: "lastViewed",
    },
    {
      dataField: "stockData",
      text: "Stock",
    },
    {
      dataField: "price",
      text: "Price",
      sort: true,
    },

    // {
    //   dataField: "action",
    //   text: "view",
    // },
  ]

  const columns = LanguageSwitch()?.isMult
    ? PreColumns
    : PreColumns?.filter(i => i.text !== "Secondary Name")

  const currency = CurrencySwitch()

  const userFavData = map(userFavouriteDetails, (item, index) => ({
    ...item,
    productName: (
      <Link to={`/product/${item?.product?._id}`}>
        {item?.product?.primaryLang?.name}{" "}
        {item?.product?.hasVarients === true
          ? `(${item?.product?.varients[0]?.value}` +
            `${item?.product?.varients[0]?.unit})`
          : ""}
      </Link>
    ),
    imageData: (
      <img
        className="rounded-circle header-profile-user"
        style={{ width: "70px", height: "70px" }}
        src={item?.product?.images ? item?.product?.images[0]?.url : defaultImg}
      />
    ),
    lastViewed: item?.lastViewed
      ? moment(item?.lastViewed).format("DD/MM/YYYY - h:mm A")
      : "",
    price: (
      <span>
        {currency}&nbsp;
        {item?.product?.hasVarients === true
          ? item?.product?.varients[0]?.price
          : item?.product?.price}{" "}
      </span>
    ),
    stockData:
      item?.product?.hasVarients === true
        ? item?.product?.varients[0]?.stock
        : item?.product?.stock,
    // item?.product?.varients[0]?.stock
    action: (
      <Link to={`product/${item?._id}`}>
        <Button
          id={`view${index}`}
          type="button"
          color="white"
          className="ms-1 btn"
        >
          <i
            className="bx bx-show-alt me-2"
            style={{ color: "green", fontSize: "22px" }}
          />
        </Button>
      </Link>
    ),
  }))
  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const { SearchBar } = Search

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page, searchText }) => {
    setUserList()
  }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <ToolkitProvider
            keyField="_id"
            columns={columns}
            data={userFavData}
            search
          >
            {toolkitProps => (
              <React.Fragment>
                {loading ? (
                  <Spinner color="secondary" className="d-block m-auto" />
                ) : (
                  <>
                    <Row>
                      <Col xl="12">
                        <div className="table-responsive">
                          <BootstrapTable
                            // keyField={"id"}
                            key={"id"}
                            responsive
                            bordered={false}
                            striped={false}
                            // defaultSorted={defaultSorted}
                            // selectRow={selectRow}
                            classes={"table align-middle table-nowrap"}
                            headerWrapperClasses={"thead-light"}
                            {...toolkitProps.baseProps}
                            onTableChange={handleTableChange}
                          />
                        </div>
                      </Col>
                    </Row>
                    <MyPagination
                      totalPages={pages}
                      page={page}
                      setPage={setPage}
                    />
                  </>
                )}
              </React.Fragment>
            )}
          </ToolkitProvider>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default RecentViewsDatatable
