import {
  GET_GROWPAGES,
  GET_GROWPAGES_SUCCESS,
  GET_GROWPAGES_FAIL,
  GET_GROWPAGE_DETAILS,
  GET_GROWPAGE_DETAILS_SUCCESS,
  GET_GROWPAGE_DETAILS_FAIL,
  CREATE_GROWPAGE,
  CREATE_GROWPAGE_SUCCESS,
  CREATE_GROWPAGE_FAIL,
  UPDATE_GROWPAGE,
  UPDATE_GROWPAGE_SUCCESS,
  UPDATE_GROWPAGE_FAIL,
  DELETE_GROWPAGE_SUCCESS,
  DELETE_GROWPAGE_FAIL,
  DELETE_GROWPAGE,
  GET_DEFAULT_SECTION,
  GET_DEFAULT_SECTION_SUCCESS,
  GET_DEFAULT_SECTION_FAIL,
  CREATE_SECTION,
  CREATE_SECTION_SUCCESS,
  CREATE_SECTION_FAIL,
  UPDATE_SECTION,
  UPDATE_SECTION_SUCCESS,
  UPDATE_SECTION_FAIL,
  DELETE_SECTION,
  DELETE_SECTION_SUCCESS,
  DELETE_SECTION_FAIL,
  CHECK_PAGE_TITLE,
  CHECK_PAGE_TITLE_SUCCESS,
  CHECK_PAGE_TITLE_FAIL,
  GET_DUPLICATE_SECTION,
  GET_DUPLICATE_SECTION_SUCCESS,
  GET_DUPLICATE_SECTION_FAIL,
} from "./actionTypes"

// GrowPage - This line cannot be edited or removed
export const getGrowPages = (page, sort, limit, groweb, searchText) => ({
  type: GET_GROWPAGES,
  payload: { page, sort, limit, groweb, searchText },
})

export const getGrowPagesSuccess = growpages => ({
  type: GET_GROWPAGES_SUCCESS,
  payload: growpages,
})

export const getGrowPagesFail = error => ({
  type: GET_GROWPAGES_FAIL,
  payload: error,
})

export const getGrowPageDetails = growpageId => ({
  type: GET_GROWPAGE_DETAILS,
  payload: growpageId,
})

export const getGrowPageDetailsSuccess = growpageDetails => ({
  type: GET_GROWPAGE_DETAILS_SUCCESS,
  payload: growpageDetails,
})

export const getGrowPageDetailsFail = error => ({
  type: GET_GROWPAGE_DETAILS_FAIL,
  payload: error,
})

export const createGrowPage = growpage => ({
  type: CREATE_GROWPAGE,
  payload: { growpage },
})

export const createGrowPageSuccess = growpage => ({
  type: CREATE_GROWPAGE_SUCCESS,
  payload: growpage,
})

export const createGrowPageFail = error => ({
  type: CREATE_GROWPAGE_FAIL,
  payload: error,
})

export const getGrowPageDetail = growpageId => ({
  type: GET_GROWPAGE_DETAILS,
  payload: growpageId,
})

export const updateGrowPage = (growpage, growpageId, history) => ({
  type: UPDATE_GROWPAGE,
  payload: { growpage, growpageId, history },
})

export const updateGrowPageSuccess = growpage => ({
  type: UPDATE_GROWPAGE_SUCCESS,
  payload: growpage,
})

export const updateGrowPageFail = error => ({
  type: UPDATE_GROWPAGE_FAIL,
  payload: error,
})

export const deleteGrowPage = (growpageId, history) => ({
  type: DELETE_GROWPAGE,
  payload: { growpageId, history },
})

export const deleteGrowPageSuccess = growpage => ({
  type: DELETE_GROWPAGE_SUCCESS,
  payload: growpage,
})

export const deleteGrowPageFail = error => ({
  type: DELETE_GROWPAGE_FAIL,
  payload: error,
})

//section
export const getDefaultSection = () => ({
  type: GET_DEFAULT_SECTION,
})

export const getDefaultSectionSuccess = section => ({
  type: GET_DEFAULT_SECTION_SUCCESS,
  payload: section,
})

export const getDefaultSectionFail = error => ({
  type: GET_DEFAULT_SECTION_FAIL,
  payload: error,
})

export const createSection = (section, onCloseClick, pageId) => ({
  type: CREATE_SECTION,
  payload: { section, onCloseClick, pageId },
})

export const createSectionSuccess = section => ({
  type: CREATE_SECTION_SUCCESS,
  payload: section,
})

export const createSectionFail = error => ({
  type: CREATE_SECTION_FAIL,
  payload: error,
})

export const updateSection = (section, sectionId, onCloseClick, pageId) => ({
  type: UPDATE_SECTION,
  payload: { section, sectionId, onCloseClick, pageId },
})

export const updateSectionSuccess = section => ({
  type: UPDATE_SECTION_SUCCESS,
  payload: section,
})

export const updateSectionFail = error => ({
  type: UPDATE_SECTION_FAIL,
  payload: error,
})

export const deleteSection = (sectionId, history) => ({
  type: DELETE_SECTION,
  payload: { sectionId, history },
})

export const deleteSectionSuccess = section => ({
  type: DELETE_SECTION_SUCCESS,
  payload: section,
})

export const deleteSectionFail = error => ({
  type: DELETE_SECTION_FAIL,
  payload: error,
})

// HOME PAGE

export const checkPageTitle = (title, pageId) => ({
  type: CHECK_PAGE_TITLE,
  payload: { title, pageId },
})

export const checkPageTitleSuccess = response => ({
  type: CHECK_PAGE_TITLE_SUCCESS,
  payload: { response },
})

export const checkPageTitleFail = error => ({
  type: CHECK_PAGE_TITLE_FAIL,
  payload: error,
})

export const getDuplicateSection = (sectionId, handleUpdatePage) => ({
  type: GET_DUPLICATE_SECTION,
  payload: { sectionId, handleUpdatePage },
})

export const getDuplicateSectionSuccess = section => ({
  type: GET_DUPLICATE_SECTION_SUCCESS,
  payload: section,
})
export const getDuplicateSectionFail = error => ({
  type: GET_DUPLICATE_SECTION_FAIL,
  payload: error,
})
