import rgbHex from "rgb-hex"
import Select from "react-select"
import PropTypes from "prop-types"
import { debounce, map, values } from "lodash"
import { ChromePicker } from "react-color"
import React, { useEffect, useState } from "react"
import { CardBody, CardTitle, Col, Label } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { FormGroup, Modal, ModalBody, Row } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { WithContext as ReactTags } from "react-tag-input"

//action
import { createSection, getAllProducts, updateSection } from "store/actions"

import MyRating from "components/Common/Rating"
import Spinner from "components/Common/Spinner"
import { handleLayoutText, LayoutData, VarientLayoutData } from "./Update"
import ReactDragListView from "react-drag-listview/lib/index.js"
import UseImageUploader from "components/imageUploader/useImageUploader"

import defaultImg from "assets/images/Defualt/default.jpg"
import FormEditors from "pages/Product/Crud/Create/Editor"
import { HiLockClosed, HiLockOpen } from "react-icons/hi"
import { OffSymbol, OnSymbol } from "pages/Product/Crud/Create/Create"
import Switch from "react-switch"

const KeyCodes = {
  comma: 188,
  enter: 13,
}

const delimiters = [KeyCodes.comma, KeyCodes.enter]

function AddSectionModal({ onCloseClick, show, data }) {
  const dispatch = useDispatch()
  const { products, proLoading, sectionLoad, pageDetails } = useSelector(
    state => ({
      products: state.Products.products,
      proLoading: state.Products.loading,
      pageDetails: state.GrowPage.growpageDetails,
      sectionLoad: state.GrowPage.sectionLoad,
    })
  )

  const updateData = data?.data

  const [cta, setCta] = useState({
    isRequired: false,
    action: "product",
    text: "",
    type: "",
  })
  const [layout, setLayout] = useState("highlight")
  const [highlightImage, setHighlightImage] = useState([])
  const [carouselLinks, setCarouselLinks] = useState([])
  const [isAutoplay, setIsAutoplay] = useState(false)
  const [isPaginationDot, setIsPaginationDot] = useState(false)
  const [isNavigationButton, setIsNavigationButton] = useState(false)
  //shortcontent
  const [fgColor, setFgColor] = useState("000")
  const [bgColor, setBgColor] = useState("FFF")
  const [shortContentImg, setShortContentImg] = useState([])
  const [shortContentTitle, setShortContentTitle] = useState("")
  const [shortContents, setShortContents] = useState([])

  console.log(fgColor)

  const addShortContent = () => {
    setShortContents([
      ...shortContents,
      {
        image: shortContentImg[0],
        title: shortContentTitle,
        id: shortContents?.length + 1,
      },
    ])

    setShortContentImg([])
    setShortContentTitle("")
  }

  //carousel
  const [carouselImg, setCarouselImg] = useState([])

  //imagegrid
  const [gridImage, setGridImage] = useState([])

  //testimonial
  const [testimonialImg, setTestimonialImg] = useState([])
  const [testimonial, setTestimonial] = useState({
    rating: 0,
    name: "",
    message: "",
  })

  const [testimonialArray, setTestimonialArray] = useState([])

  //carouselPadding
  const [carouselPadding, setCarouselPadding] = useState(["0", "0", "0", "0"])
  const [lockPadding, setLockPadding] = useState(true)

  const handleChangeCarouselPadding = (value, type) => {
    if (lockPadding) {
      setCarouselPadding(prevPadding => {
        const updatedPadding = [...prevPadding]
        updatedPadding[0] = value
        updatedPadding[1] = value
        updatedPadding[2] = value
        updatedPadding[3] = value
        return updatedPadding
      })
    } else {
      setCarouselPadding(prevPadding => {
        const updatedPadding = [...prevPadding]
        updatedPadding[type] = value
        return updatedPadding
      })
    }
  }

  const addNewTestimonial = () => {
    setTestimonialArray([
      {
        ...testimonial,
        id: testimonialArray?.length + 1,
        image: testimonialImg[0],
      },
      ...testimonialArray,
    ])

    setTestimonial({
      rating: 0,
      name: "",
      message: "",
    })

    setTestimonialImg([])
  }
  const [description, setDescription] = useState("")

  useEffect(() => {
    setDescription(updateData?.description)
    if (updateData) {
      setCarouselPadding(
        updateData?.carouselPadding
          ? updateData?.carouselPadding
          : ["0", "0", "0", "0"]
      )
    }
  }, [updateData])

  //faq
  const [faq, setFaq] = useState({})
  const [faqArray, setFaqArray] = useState([])

  const addNewFaq = () => {
    setFaqArray([{ ...faq, id: faqArray?.length + 1 }, ...faqArray])

    setFaq({
      question: "",
      answer: "",
    })
  }

  //product varient
  const [productVarientLayout, setProductVarientLayout] = useState(
    VarientLayoutData[0]?.value
  )

  const [productSelect, setProductSelect] = useState({
    searchText: "",
    id: "",
    title: "Search a Product",
  })
  const [selectedProductArray, setSelectedProductArray] = useState([])
  const [selectedProductId, setSelectedProductId] = useState([])

  const debounceProductSearch = debounce(value => setProductSelect(value), 600)
  const handleProductEnters = textEntered => {
    debounceProductSearch({
      ...productSelect,
      searchText: textEntered,
    })
  }

  function handlerProductFinalValue(event) {
    const product = {
      ...productSelect,
      title: event.label,
      id: event.value,
      image: event.image,
      varients: event.varients,
    }
    setProductSelect(product)
    setSelectedProductArray([...selectedProductArray, product])
    setSelectedProductId([...selectedProductId, event.value])
  }

  const productOptions = [
    {
      options: (products?.length >= 1 ? products : [])?.map(
        (result, index) => ({
          key: index,
          label: `${result?.primaryLang?.name} ${
            result.hasVarients
              ? result?.varients?.length >= 1
                ? `(${result?.varients[0]?.value} ${result?.varients[0]?.unit})`
                : ""
              : ""
          }`,
          value: result?._id,
          image: result?.images[0]?.url,
        })
      ),
    },
  ]

  const handleRemoveSeletedproduct = id => {
    const filteredProd = selectedProductArray?.filter(i => i.id !== id)
    setSelectedProductArray(filteredProd)

    const filteredProdId = selectedProductId?.filter(i => i !== id)
    setSelectedProductId(filteredProdId)
  }

  useEffect(() => {
    dispatch(getAllProducts(1, "all", "", productSelect?.searchText))
  }, [dispatch, productSelect?.searchText])
  //end of product dropdown

  const rgbtohex = c => {
    if (c?.r) {
      return "#" + rgbHex(c.r, c.g, c.b, c.a)
    }
  }
  // console.log("title =>")
  // console.log("data :", pageDetails)

  //section create
  const handleValidSubmit = value => {
    console.log("data :", value)
    const sectionData = () => {
      const common = {
        // growPage:
        callToAction: cta,
        height: parseInt(value?.height),
        textAlign: value?.textAlign ? value?.textAlign : "center",
        platform: value?.platform ? value?.platform : "common",
        link: value?.link,
        // growPage: pageDetails?.store?._id,
        title: value?.title,
        layout,
        description: value?.description || description,
      }
      switch (layout) {
        case "highlight":
          return {
            ...common,
            images: highlightImage,
            fgColor: rgbtohex(fgColor?.rgb),
            bgColor: rgbtohex(bgColor?.rgb),
          }
        case "shortContent":
          return {
            ...common,
            fgColor: rgbtohex(fgColor?.rgb),
            bgColor: rgbtohex(bgColor?.rgb),
            shortContents,
            gridSize: {
              lg: value?.lg,
              sm: value?.sm,
            },
          }
        case "wideContent":
          return {
            ...common,
            imagePosition: value?.imagePosition ? value?.imagePosition : "top",
            fgColor: rgbtohex(fgColor?.rgb),
            bgColor: rgbtohex(bgColor?.rgb),
            images: shortContentImg,
          }
        case "carousel":
          return {
            ...common,
            images: carouselImg,
            imageLinks: carouselLinks,
            carousel: {
              carouselCount: {
                lg: parseInt(value?.carouselCountLg),
                sm: parseInt(value?.carouselCountSm),
              },
              carouselPadding: carouselPadding,
              autoplay: isAutoplay,
              navigationButton: isNavigationButton,
              paginationDot: isPaginationDot,
              speed: value?.speed,
            },
          }
        case "imageGrid":
          return {
            ...common,
            images: gridImage,
            gridSize: {
              lg: value?.lg,
              sm: value?.sm,
            },
            imageLinks: carouselLinks,
          }
        case "video":
          return {
            ...common,
            videoId: value?.videoId,
          }
        case "productVariant":
          return {
            ...common,
            productVariants: selectedProductId,
            productVariantLayout: productVarientLayout,
            gridSize: {
              lg: value?.lg,
              sm: value?.sm,
            },
          }

        case "testimonials":
          return {
            ...common,
            testimonials: testimonialArray,
          }

        case "faqData":
          return {
            ...common,
            faqData: faqArray,
          }
      }
    }

    if (updateData?.isUpdate === true) {
      dispatch(
        updateSection(
          sectionData(),
          updateData?._id,
          onCloseClick,
          pageDetails?._id
        )
      )
    } else {
      dispatch(createSection(sectionData(), onCloseClick, pageDetails?._id))
    }
  }

  useEffect(() => {
    if (updateData?.isUpdate === true) {
      setIsAutoplay(updateData?.carousel?.autoplay)
      setIsPaginationDot(updateData?.carousel?.paginationDot)
      setIsNavigationButton(updateData?.carousel?.navigationButton)
      setLayout(updateData?.layout)
      setProductVarientLayout(updateData?.productVariantLayout)
      switch (layout) {
        case "highlight":
          setHighlightImage(updateData?.images)

          break
        case "shortContent":
        case "wideContent":
          setShortContentImg(updateData?.images)
          break
        case "imageGrid":
          setGridImage(updateData?.images)
          break
        case "carousel":
          setCarouselImg(updateData?.images)
          break
      }

      setShortContents(
        updateData?.shortContents?.map(i => ({ ...i, id: i?._id }))
      )
      setFgColor(updateData?.fgColor)
      setBgColor(updateData?.bgColor)
      setTestimonialArray(
        updateData?.testimonials?.map(i => ({ ...i, id: i?._id }))
      )
      setFaqArray(updateData?.faqData?.map(i => ({ ...i, id: i?._id })))
      setCta(updateData?.callToAction)
      setSelectedProductArray(
        updateData?.productVariants?.map(i => ({
          id: i?._id,
          title: i?.primaryLang?.name,
          image: i?.images[0]?.url,
        }))
      )
      setSelectedProductId(updateData?.productVariants?.map(i => i?._id))
    }
  }, [updateData, updateData?.isUpdate, layout])

  useEffect(() => {
    if (updateData?.isCreate === true) {
      setShortContentImg([])
      setHighlightImage([])
      setCarouselImg([])
      setGridImage([])
      setTestimonialImg([])
      setLayout("")
      setProductVarientLayout(VarientLayoutData[0]?.value)
      setBgColor("FFF")
      setFgColor("000")
      setShortContents([])
      setTestimonialArray([])
      setFaqArray([])
      setSelectedProductArray([])
      // setCarouselPadding(updateData?.carouselPadding)
    }
  }, [updateData, updateData?.isCreate])

  useEffect(() => {
    if (!updateData?._id) {
      setCta({
        isRequired: false,
        action: "product",
        text: "",
      })
    }
  }, [layout])

  const dragPropsShortContent = {
    onDragEnd(fromIndex, toIndex) {
      const modifiedData = shortContents
      const item = shortContents.splice(fromIndex, 1)[0]
      modifiedData.splice(toIndex, 0, item)
      setShortContents(modifiedData)
      if (updateData?.isUpdate === true) {
        dispatch(
          updateSection(
            { ...updateData, shortContents: modifiedData },
            updateData?._id
          )
        )
      }
    },
    nodeSelector: "tr",
    handleSelector: "tr",
  }

  const dragPropsTestimonial = {
    onDragEnd(fromIndex, toIndex) {
      const modifiedData = testimonialArray
      const item = testimonialArray.splice(fromIndex, 1)[0]
      modifiedData.splice(toIndex, 0, item)
      setTestimonialArray(modifiedData)
      if (updateData?.isUpdate === true) {
        dispatch(
          updateSection(
            { ...updateData, testimonials: modifiedData },
            updateData?._id
          )
        )
      }
    },
    nodeSelector: "tr",
    handleSelector: "tr",
  }

  const dragPropsFaq = {
    onDragEnd(fromIndex, toIndex) {
      const modifiedData = faqArray
      const item = faqArray.splice(fromIndex, 1)[0]
      modifiedData.splice(toIndex, 0, item)
      setFaqArray(modifiedData)
      if (updateData?.isUpdate === true) {
        dispatch(
          updateSection(
            { ...updateData, faqData: modifiedData },
            updateData?._id
          )
        )
      }
    },
    nodeSelector: "tr",
    handleSelector: "tr",
  }

  const dragPropsProduct = {
    onDragEnd(fromIndex, toIndex) {
      const modifiedData = selectedProductArray
      const item = selectedProductArray.splice(fromIndex, 1)[0]
      modifiedData.splice(toIndex, 0, item)
      setSelectedProductArray(modifiedData)
      setSelectedProductId(modifiedData?.map(i => i.id))
      if (updateData?.isUpdate === true) {
        dispatch(
          updateSection(
            { ...updateData, productVariants: selectedProductId },
            updateData?._id
          )
        )
      }
    },
    nodeSelector: "tr",
    handleSelector: "tr",
  }

  const updateProductWhenImageChange = (newImageId, type, newImageObj) => {
    if (updateData?.isUpdate === true) {
      if (type === "remove") {
        const newImage = highlightImage?.filter(
          i => i?.public_id !== newImageId
        )
        if (newImageId) {
          dispatch(
            updateSection({ ...updateData, images: newImage }, updateData?._id)
          )
        }
      }
      if (type === "add") {
        const newImage = [...highlightImage, newImageObj]
        if (newImageId && newImage) {
          dispatch(
            updateSection({ ...updateData, images: newImage }, updateData?._id)
          )
        }
      }
    }
  }

  useEffect(() => {
    switch (cta?.action) {
      case "product":
        return setCta({
          ...cta,
          text: "VIEW DETAILS",
        })
      case "checkOut":
        return setCta({
          ...cta,
          text: "BUY NOW!",
        })
      case "cart":
        return setCta({
          ...cta,
          text: "ADD TO CART",
        })
      case "custom":
        return setCta({
          ...cta,
        })
    }
  }, [cta?.action])

  const [isCommon, setIsCommon] = useState(true)

  // carouselPadding
  // cta type

  // carousel isfullscreen or padding
  // product list
  // isbutton or link

  const [tags, setTags] = useState([])

  const handleDeleteTag = i => {
    setTags(tags.filter((tag, index) => index !== i))
    const arrangedKeywords = tags.map(a => a.text)
    setCarouselLinks(arrangedKeywords)
  }

  const handleAddition = tag => {
    setTags([...tags, tag])
    const arrangedKeywords = [...tags, tag].map(a => a.text)
    setCarouselLinks(arrangedKeywords)
  }

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice()
    newTags.splice(currPos, 1)
    newTags.splice(newPos, 0, tag)
    // re-render
    setTags(newTags)
    const arrangedKeywords = newTags.map(a => a.text)
    setCarouselLinks(arrangedKeywords)
  }

  const handleTagClick = index => {
    console.log("The tag at index " + index + " was clicked")
  }

  useEffect(() => {
    const imageLinks = updateData?.imageLinks
    const modifiedList = []
    imageLinks?.map(i => modifiedList?.push({ id: i, text: i }))

    setTags(modifiedList)
    setCarouselLinks(imageLinks)
  }, [updateData])
  console.log(updateData)
  return (
    <Modal
      isOpen={show}
      toggle={onCloseClick}
      centered={true}
      style={deleteModalStyle}
      className="section-modal"
    >
      <ModalBody style={deleteModalBodyStyle} className="p-0">
        <AvForm
          onValidSubmit={(e, v) => {
            handleValidSubmit(v)
          }}
          style={{
            minHeight: 300,
            maxHeight: 800,
          }}
          className="d-flex flex-column justify-content-between"
        >
          <div className="text-start main-body">
            <CardBody>
              <CardTitle className="h4 mb-3 text_deals_green">
                {updateData?.isUpdate === true
                  ? `Update  Section  :  (${handleLayoutText(
                      updateData?.layout
                    )})`
                  : "Create Section"}
              </CardTitle>
              <div>
                {!updateData?.isUpdate && (
                  <p className="my-0 py-0 pb-2">Select A Layout</p>
                )}
                {updateData?.isUpdate ? (
                  <></>
                ) : (
                  <div
                    className="btn-group w-100 flex-wrap mt-2 mt-xl-0 d-flex align-items-center justify-content-start"
                    role="group"
                    aria-label="Basic radio toggle button group"
                  >
                    {map(LayoutData, (item, key) => (
                      <div key={key} className="me-3">
                        <input
                          type="radio"
                          className="btn-check"
                          name="btnradio"
                          id={item?.id}
                          autoComplete="off"
                          value={item.value}
                          onChange={e => setLayout(e.target.value)}
                          checked={layout === item.value}
                        />
                        <label
                          className="btn btn-outline-info"
                          htmlFor={item?.id}
                        >
                          {item.title}
                        </label>
                      </div>
                    ))}
                  </div>
                )}
                <hr className="text-muted" />
              </div>
              <div
                className="mt-4 scroll-bar-hide px-1"
                style={{
                  maxHeight: 460,
                  overflowY: "scroll",
                }}
              >
                <label htmlFor="isCommonSetup" className="me-2 cursor-pointer">
                  Basic Setups
                </label>
                <input
                  type="checkbox"
                  className={
                    "form-check-input " + `${isCommon && "bg-success"}`
                  }
                  id="isCommonSetup"
                  onChange={e => {
                    setIsCommon(e.target.checked)
                  }}
                  checked={isCommon}
                />
                {isCommon && (
                  <div className="common-section d-flex align-items-start justify-content-between ">
                    <div className="w-50" style={{ maxWidth: "100%" }}>
                      <AvField
                        name="title"
                        label="Title"
                        type="text"
                        className="mb-3"
                        value={updateData?.title ? updateData?.title : ""}
                      />
                      {layout !== "highlight" && layout !== "wideContent" && (
                        <AvField
                          name="description"
                          label="Description"
                          type="textarea"
                          className="mb-3"
                          value={
                            updateData?.description
                              ? updateData?.description
                              : ""
                          }
                          style={{
                            height: 77,
                          }}
                        />
                      )}
                    </div>

                    <div
                      className="common-section-1  w-100"
                      style={{ maxWidth: 350 }}
                    >
                      <div className="align-items-center d-flex justify-content-between">
                        <AvField
                          type="select"
                          name="platform"
                          className="mb-3 w-100 "
                          label="Platform"
                          value={updateData?.platform}
                        >
                          <option value="common">COMMON</option>
                          <option value="mobile">MOBILE</option>
                          <option value="web">WEB</option>
                        </AvField>
                        <AvField
                          name="height"
                          className="mb-3 me-3 w-100 "
                          label="Height (%)"
                          type="text"
                          value={updateData?.height}
                        />
                        <AvField
                          type="select"
                          name="textAlign"
                          className="mb-3 w-100 "
                          label="Text Align"
                          value={updateData?.textAlign}
                        >
                          <option value="center">Center</option>
                          <option value="left">Left</option>
                          <option value="right">Right</option>
                        </AvField>
                      </div>
                      <hr className="text-muted" />
                      <div className="basic-data">
                        <div className="d-flex algin-items-center justify-content-between mb-3">
                          <div className="form-check form-switch form-switch-md me-4">
                            <label
                              className="form-check-label"
                              htmlFor="iscallToActionSwitch"
                            >
                              {cta?.isRequired ? (
                                <select
                                  onChange={e => {
                                    setCta({
                                      ...cta,
                                      action: e.target.value,
                                    })
                                  }}
                                  value={cta?.action}
                                  className="select-option"
                                >
                                  <option value={"product"}>Product</option>
                                  <option value={"checkOut"}>Check Out</option>
                                  <option value={"cart"}>Cart</option>
                                  <option value={"custom"}>Custom</option>
                                  <option value={"whatsapp_link"}>
                                    Whatsapp
                                  </option>
                                </select>
                              ) : (
                                ` Call To Action`
                              )}
                            </label>

                            <input
                              type="checkbox"
                              className={
                                "form-check-input " +
                                `${cta?.isRequired && "bg-success"}`
                              }
                              id="iscallToActionSwitch"
                              style={{
                                border: cta?.isRequired && "none",
                              }}
                              onChange={e => {
                                setCta({
                                  ...cta,
                                  isRequired: e.target.checked,
                                  // action: "product",
                                })
                              }}
                              checked={cta?.isRequired}
                            />
                          </div>

                          <div className="d-flex align-items-center ">
                            <p className="p-0 m-0 me-2">Type</p>
                            <select
                              onChange={e => {
                                setCta({
                                  ...cta,
                                  type: e.target.value,
                                })
                              }}
                              value={cta?.type}
                              className="select-option"
                            >
                              <option value={"button"}>Button</option>
                              <option value={"link"}>Link</option>
                              <option value={"text"}>Text</option>
                              <option value={"whatsapp_link"}>
                                Whatsapp Link
                              </option>
                            </select>
                          </div>
                        </div>

                        {cta?.isRequired && (
                          <AvField
                            name="ctaText"
                            className="mb-3 w-100"
                            label="Custom Text"
                            type="text"
                            value={cta?.text}
                            onChange={e =>
                              setCta({
                                ...cta,
                                text: e.target.value,
                              })
                            }
                          />
                        )}
                        <AvField
                          name="link"
                          className="mb-3 me-3 w-100"
                          label="Link"
                          type="text"
                          value={updateData?.link}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {/* //highlight */}
                {layout === "highlight" && (
                  <div>
                    <Row>
                      <Col lg={12}>
                        <div className="w-100 me-5">
                          <div className="my-1">
                            <p>Description</p>
                            <FormEditors
                              content={description}
                              setContent={setDescription}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col lg={12}>
                        <div className="d-flex mb-3 mt-3 color-picker">
                          <div className="">
                            <p>Background Color</p>
                            <ChromePicker
                              width="180px"
                              className="me-3"
                              onChange={color => setBgColor(color)}
                              color={bgColor}
                            />
                          </div>
                          <div>
                            <p>Foreground Color</p>
                            <ChromePicker
                              width="180px"
                              className="me-3"
                              onChange={color => setFgColor(color)}
                              color={fgColor}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <div>
                      {/* <Label className="mb-2">Images</Label> */}
                      <UseImageUploader
                        imageSize={4}
                        imagesPreview={highlightImage}
                        setImagesPreview={setHighlightImage}
                        uploadUrl={"/pages"}
                        onChangeUpdate={updateProductWhenImageChange}
                      />
                    </div>
                  </div>
                )}

                {/* shortcontent */}
                {layout === "shortContent" && (
                  <div>
                    <Row>
                      <Col lg={6}>
                        <div className="d-flex mb-3 mt-3 color-picker">
                          <div className="">
                            <p>Background Color</p>
                            <ChromePicker
                              width="180px"
                              className="me-3"
                              onChange={color => setBgColor(color)}
                              color={bgColor}
                            />
                          </div>
                          <div>
                            <p>Foreground Color</p>
                            <ChromePicker
                              width="180px"
                              className="me-3"
                              onChange={color => setFgColor(color)}
                              color={fgColor}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="d-flex flex-column gridSize">
                          <p className="me-4" style={{ whiteSpace: "nowrap" }}>
                            Grid Size :
                          </p>
                          <div className="d-flex">
                            <AvField
                              name="lg"
                              className="mb-3"
                              label="Large Screen"
                              type="number"
                              value={
                                updateData?.gridSize?.lg
                                  ? updateData?.gridSize?.lg
                                  : ""
                              }
                            />
                            <AvField
                              name="sm"
                              className="mb-3"
                              label="Small Screen"
                              type="number"
                              value={
                                updateData?.gridSize?.sm
                                  ? updateData?.gridSize?.sm
                                  : ""
                              }
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <div>
                      <div className="w-100 d-flex justify-content-between align-items-center mb-3"></div>
                      <div className="d-flex shortContent">
                        <div className="w-100">
                          {/* <Label className="m-0 p-0">Images</Label> */}
                          <UseImageUploader
                            imageSize={4}
                            imagesPreview={shortContentImg}
                            setImagesPreview={setShortContentImg}
                            uploadUrl={"/pages"}
                          />
                        </div>
                        <div className="w-100 p-3">
                          <AvField
                            name="shortContentTitle"
                            className="mb-3 w-100"
                            label="Title"
                            type="textarea"
                            value={shortContentTitle}
                            onChange={e => setShortContentTitle(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-end">
                        <button
                          type="button"
                          style={{ borderRadius: 10, maxWidth: 150 }}
                          className="btn btn-light w-50"
                          onClick={addShortContent}
                        >
                          + Add
                        </button>
                      </div>

                      <div className="w-100">
                        <div className="table-responsive mt-5">
                          <ReactDragListView {...dragPropsShortContent}>
                            {map(shortContents || [], (shortContent, key) => (
                              <tr key={key} className="d-flex w-100 mb-4">
                                <div
                                  className="d-flex w-50 justify-content-between mt-3 p-3 me-3"
                                  style={{
                                    backgroundColor: "#f9f9f9",
                                    minWidth: 200,
                                  }}
                                >
                                  <div className="d-flex w-100 shortContent-preview">
                                    <div className="position-relative me-3">
                                      <img
                                        src={
                                          shortContent?.image?.url ?? defaultImg
                                        }
                                        className="avatar-lg rounded "
                                      />
                                    </div>
                                    <AvField
                                      name="shortContentDescription"
                                      className="mb-3 w-100 h-100"
                                      type="textarea"
                                      value={shortContent?.title}
                                      onChange={e =>
                                        setShortContents(
                                          shortContents?.map(i =>
                                            i?.id === shortContent?.id
                                              ? { ...i, title: e.target.value }
                                              : i
                                          )
                                        )
                                      }
                                    />
                                  </div>
                                  <i
                                    className="bx bx-trash-alt ms-4 "
                                    style={{
                                      fontSize: "1rem",
                                      color: "red",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      setShortContents(
                                        shortContents?.filter(
                                          item => item?.id !== shortContent?.id
                                        )
                                      )
                                    }
                                  ></i>
                                </div>
                              </tr>
                            ))}
                          </ReactDragListView>
                          {shortContents?.length >= 1 &&
                            updateData?.isUpdate && (
                              <p className="text-muted">
                                Drag Item to rearrange
                              </p>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* wideContent */}
                {layout === "wideContent" && (
                  <>
                    <Row>
                      <Col lg={12}>
                        <div className="w-100" style={{ marginTop: 10 }}>
                          <AvField
                            type="select"
                            className="mb-3"
                            value={
                              updateData?.imagePosition
                                ? updateData?.imagePosition
                                : ""
                            }
                            name="imagePosition"
                            label="Image Position"
                          >
                            <option>Select Position</option>
                            <option value="top">Top</option>
                            <option value="bottom">Bottom</option>
                            <option value="left">Left</option>
                            <option value="right">Right</option>
                          </AvField>

                          <div>
                            <p>Description</p>
                            <FormEditors
                              content={description}
                              setContent={setDescription}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col lg={12}>
                        <div className="d-flex mt-3 mb-3">
                          <div>
                            <p>Background Color</p>
                            <ChromePicker
                              width="180px"
                              className="me-3 input-35"
                              onChange={color => setBgColor(color)}
                              color={bgColor}
                            />
                          </div>
                          <div>
                            <p>Foreground Color</p>
                            <ChromePicker
                              width="180px"
                              className="me-3 input-35"
                              onChange={color => setFgColor(color)}
                              color={fgColor}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <div>
                      {/* <Label className="mb-2">Images</Label> */}
                      <UseImageUploader
                        imageSize={4}
                        imagesPreview={shortContentImg}
                        setImagesPreview={setShortContentImg}
                        uploadUrl={"/pages"}
                      />
                    </div>
                  </>
                )}

                {/* //carousel */}
                {layout === "carousel" && (
                  <div>
                    <Row className="mb-2">
                      <label htmlFor="selectField">Carousel Count</label>
                      <Col lg={2}>
                        <AvField
                          name="carouselCountLg"
                          className="mb-3 "
                          label="Lg"
                          type="number"
                          value={
                            updateData?.carousel?.carouselCount?.lg
                              ? updateData?.carousel?.carouselCount?.lg
                              : ""
                          }
                        />
                      </Col>
                      <Col lg={2}>
                        <AvField
                          name="carouselCountSm"
                          className="mb-3 "
                          label="Sm"
                          type="number"
                          value={
                            updateData?.carousel?.carouselCount?.sm
                              ? updateData?.carousel?.carouselCount?.sm
                              : ""
                          }
                        />
                      </Col>
                      <Col lg={8}>
                        <Label className="">
                          Carousel Padding
                          {lockPadding ? (
                            <HiLockClosed
                              size={"16px"}
                              className="ms-2 cursor-pointer text-danger"
                              onClick={() => setLockPadding(!lockPadding)}
                            />
                          ) : (
                            <HiLockOpen
                              size={"16px"}
                              className="ms-2 cursor-pointer text-success"
                              onClick={() => setLockPadding(!lockPadding)}
                            />
                          )}
                        </Label>

                        <Row>
                          <Col sm={3}>
                            <div className="d-flex align-items-center ">
                              <Label className="me-1 mb-0">Top</Label>
                              <AvField
                                name="carouselPaddingTop"
                                style={{
                                  minWidth: "70px",
                                }}
                                type="number"
                                onChange={e =>
                                  handleChangeCarouselPadding(e.target.value, 0)
                                }
                                value={carouselPadding?.[0]}
                              />
                            </div>
                          </Col>
                          <Col sm={3}>
                            <div className="d-flex align-items-center ">
                              <Label className="me-1 mb-0">Right</Label>
                              <AvField
                                name="carouselPaddingRight"
                                style={{
                                  minWidth: "70px",
                                }}
                                type="number"
                                onChange={e =>
                                  handleChangeCarouselPadding(e.target.value, 1)
                                }
                                value={carouselPadding?.[1]}
                              />
                            </div>
                          </Col>
                          <Col sm={3}>
                            <div className="d-flex align-items-center ">
                              <Label className="me-1 mb-0">Bottom</Label>
                              <AvField
                                name="carouselPaddingBottom"
                                style={{
                                  minWidth: "70px",
                                }}
                                type="number"
                                onChange={e =>
                                  handleChangeCarouselPadding(e.target.value, 2)
                                }
                                value={carouselPadding?.[2]}
                              />
                            </div>
                          </Col>
                          <Col sm={3}>
                            <div className="d-flex align-items-center ">
                              <Label className="me-1 mb-0">Left</Label>
                              <AvField
                                name="carouselPaddingLeft"
                                style={{
                                  minWidth: "70px",
                                }}
                                type="number"
                                onChange={e =>
                                  handleChangeCarouselPadding(e.target.value, 3)
                                }
                                value={carouselPadding?.[3]}
                              />
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={6} className="mb-4">
                        <Label className="">
                          Carousel Links (
                          <span className="font-size-12">
                            drag item to rearrange
                          </span>
                          )
                        </Label>
                        <ReactTags
                          classNames={"w-100"}
                          tags={tags}
                          placeholder="for corresponding image, paste link and enter"
                          // suggestions={suggestions}
                          delimiters={delimiters}
                          handleDelete={handleDeleteTag}
                          handleAddition={handleAddition}
                          handleDrag={handleDrag}
                          handleTagClick={handleTagClick}
                          // inputFieldPosition="inline"
                          autocomplete
                          allowUnique
                          inline
                        />
                      </Col>
                      <Col sm={3} className="mt-2">
                        <Label check for="checkbox">
                          {" "}
                          Auto Play
                        </Label>
                        <Switch
                          uncheckedIcon={<OffSymbol />}
                          checkedIcon={<OnSymbol />}
                          className="me-1 mb-sm-8 mx-3"
                          onColor="#626ed4"
                          onChange={v => {
                            setIsAutoplay(v)
                          }}
                          checked={isAutoplay}
                        />
                      </Col>
                      <Col sm={3} className="mt-2">
                        <Label check for="checkbox">
                          {" "}
                          Pagination Dot
                        </Label>
                        <Switch
                          uncheckedIcon={<OffSymbol />}
                          checkedIcon={<OnSymbol />}
                          className="me-1 mb-sm-8 mx-3"
                          onColor="#626ed4"
                          onChange={v => {
                            setIsPaginationDot(v)
                          }}
                          checked={isPaginationDot}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={6} className="mt-2">
                        <Label check for="checkbox">
                          {" "}
                          Navigation Button
                        </Label>
                        <Switch
                          uncheckedIcon={<OffSymbol />}
                          checkedIcon={<OnSymbol />}
                          className="me-1 mb-sm-8 mx-3"
                          onColor="#626ed4"
                          onChange={v => {
                            setIsNavigationButton(v)
                          }}
                          checked={isNavigationButton}
                        />
                      </Col>
                      <Col sm={6}>
                        <AvField
                          name="speed"
                          className="mb-3 "
                          label="speed"
                          type="number"
                          value={
                            updateData?.carousel?.speed
                              ? updateData?.carousel?.speed
                              : ""
                          }
                        />
                      </Col>
                    </Row>
                    <div>
                      {/* <Label className="mb-2">Images</Label> */}
                      <UseImageUploader
                        imageSize={4}
                        imagesPreview={carouselImg}
                        setImagesPreview={setCarouselImg}
                        uploadUrl={"/pages"}
                        isMultiple
                      />
                    </div>
                  </div>
                )}

                {/* imageGrid */}
                {layout === "imageGrid" && (
                  <div>
                    <Row>
                      <Col sm={6}>
                        <div className="d-flex gridSize">
                          <p className="me-4" style={{ whiteSpace: "nowrap" }}>
                            Grid Size :
                          </p>
                          <AvField
                            name="lg"
                            className="mb-3"
                            label="Large Screen"
                            type="number"
                            value={
                              updateData?.gridSize?.lg
                                ? updateData?.gridSize?.lg
                                : ""
                            }
                          />
                          <AvField
                            name="sm"
                            className="mb-3"
                            label="Small Screen"
                            type="number"
                            value={
                              updateData?.gridSize?.sm
                                ? updateData?.gridSize?.sm
                                : ""
                            }
                          />
                        </div>
                      </Col>
                      <Col sm={6} className="mb-4">
                        <Label className="">
                          Carousel Links (
                          <span className="font-size-12">
                            drag item to rearrange
                          </span>
                          )
                        </Label>
                        <ReactTags
                          classNames={"w-100"}
                          tags={tags}
                          placeholder="for corresponding image, paste link and enter"
                          // suggestions={suggestions}
                          delimiters={delimiters}
                          handleDelete={handleDeleteTag}
                          handleAddition={handleAddition}
                          handleDrag={handleDrag}
                          handleTagClick={handleTagClick}
                          // inputFieldPosition="inline"
                          autocomplete
                          allowUnique
                          inline
                        />
                      </Col>
                    </Row>

                    <div>
                      {/* <Label className="mb-2">Images</Label> */}
                      <UseImageUploader
                        imageSize={4}
                        imagesPreview={gridImage}
                        setImagesPreview={setGridImage}
                        uploadUrl={"/pages"}
                        isMultiple
                      />
                    </div>
                  </div>
                )}

                {/* video */}
                {layout === "video" && (
                  <div>
                    <Row>
                      <Col lg={6}>
                        <AvField
                          name="videoId"
                          className="mb-3"
                          label="Video Id"
                          type="text"
                          value={updateData?.videoId ? updateData?.videoId : ""}
                        />
                      </Col>
                    </Row>
                  </div>
                )}

                {/* //product varient */}
                {layout === "productVariant" && (
                  <>
                    <div>
                      <CardBody
                        className="p-3 w-100"
                        style={{
                          backgroundColor: "#f7f7f7",
                          borderRadius: 5,
                        }}
                      >
                        <div className="w-100 d-flex align-items-start justify-content-between">
                          <div className="p-2 col-5">
                            <CardTitle className="h4 mb-2 text_deals_green ">
                              Product
                            </CardTitle>

                            <FormGroup className="mb-3">
                              <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                                <Select
                                  onInputChange={handleProductEnters}
                                  value={productSelect?.title}
                                  placeholder={productSelect?.title}
                                  onChange={handlerProductFinalValue}
                                  options={productOptions}
                                  classNamePrefix="select2-selection"
                                  isLoading={proLoading}
                                />
                              </div>
                            </FormGroup>
                            {selectedProductArray?.length >= 1 &&
                              updateData?.isUpdate && (
                                <p className="text-muted mt-4 mb-2">
                                  Drag Item to rearrange
                                </p>
                              )}

                            <ReactDragListView {...dragPropsProduct}>
                              {map(selectedProductArray, (item, key) => (
                                <tr key={key} className="d-flex mb-3">
                                  <div className="w-100">
                                    <div className="d-flex align-items-center">
                                      <img
                                        className="img-thumbnail rounded-circle "
                                        style={{
                                          height: "60px",
                                          width: "60px",
                                        }}
                                        src={item?.image}
                                        alt="product"
                                      />
                                      <p className="pb-0 mb-0 px-3">
                                        {item?.title}
                                      </p>
                                      <i
                                        className="bx bx-trash-alt "
                                        style={{
                                          fontSize: "1rem",
                                          color: "red",
                                          cursor: "pointer",
                                        }}
                                        onClick={() =>
                                          handleRemoveSeletedproduct(item?.id)
                                        }
                                      ></i>
                                    </div>
                                  </div>
                                </tr>
                              ))}
                            </ReactDragListView>
                          </div>

                          <div className="p-2 col-7 d-flex">
                            <div>
                              <p className="mb-2">Variant Layout</p>
                              <div className="d-flex align-items-center ">
                                {map(VarientLayoutData, (item, key) => (
                                  <div key={key} className="me-2">
                                    <input
                                      type="radio"
                                      className="btn-check"
                                      name="varientLayout"
                                      id={item?.id}
                                      autoComplete="off"
                                      value={item.value}
                                      onChange={e =>
                                        setProductVarientLayout(e.target.value)
                                      }
                                      checked={
                                        productVarientLayout === item.value
                                      }
                                    />
                                    <label
                                      className="btn btn-outline-info"
                                      htmlFor={item?.id}
                                    >
                                      {item.title}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </div>

                            {productVarientLayout === "list" && (
                              <div className="d-flex gridSize">
                                <p
                                  className="me-4"
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  Grid Size :
                                </p>
                                <div className="d-flex">
                                  <AvField
                                    name="lg"
                                    className="mb-3"
                                    label="Large Screen"
                                    type="number"
                                    value={
                                      updateData?.gridSize?.lg
                                        ? updateData?.gridSize?.lg
                                        : ""
                                    }
                                    style={{
                                      width: "80px",
                                    }}
                                  />
                                  <AvField
                                    name="sm"
                                    className="mb-3"
                                    label="Small Screen"
                                    type="number"
                                    value={
                                      updateData?.gridSize?.sm
                                        ? updateData?.gridSize?.sm
                                        : ""
                                    }
                                    style={{
                                      width: "80px",
                                    }}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </CardBody>
                    </div>
                  </>
                )}

                {/* //testimonials */}
                {layout === "testimonials" && (
                  <div>
                    <CardBody
                      className="p-3"
                      style={{
                        backgroundColor: "#f7f7f7",
                        borderRadius: 5,
                      }}
                    >
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="me-4" style={{ flex: 1 }}>
                          <MyRating
                            className="text-warning mb-2"
                            initialRating={testimonial?.rating}
                            onChange={value =>
                              setTestimonial({ ...testimonial, rating: value })
                            }
                          />
                          <AvField
                            name="name"
                            className="mb-3"
                            label="Name"
                            type="text"
                            value={testimonial?.name}
                            onChange={e =>
                              setTestimonial({
                                ...testimonial,
                                name: e.target.value,
                              })
                            }
                          />
                          <label>
                            Message<small>(max 200 words)</small>
                          </label>
                          <AvField
                            name="message"
                            maxLength={200}
                            className="mb-3"
                            type="textarea"
                            value={testimonial?.message}
                            onChange={e =>
                              setTestimonial({
                                ...testimonial,
                                message: e.target.value,
                              })
                            }
                            style={{
                              height: 80,
                            }}
                          />
                        </div>
                        <div style={{ flex: 1 }}>
                          <UseImageUploader
                            imageSize={4}
                            imagesPreview={testimonialImg}
                            setImagesPreview={setTestimonialImg}
                            uploadUrl={"/pages"}
                          />
                        </div>
                      </div>
                      <div className="w-100 d-flex justify-content-end">
                        <button
                          type="button"
                          style={{ borderRadius: 10, maxWidth: 150 }}
                          className="btn btn-light w-50"
                          onClick={addNewTestimonial}
                        >
                          + Add Testimonial
                        </button>
                      </div>
                    </CardBody>
                    <div className="d-flex mt-3 flex-wrap justify-content-between testimonialWrapp">
                      <ReactDragListView {...dragPropsTestimonial}>
                        {map(testimonialArray, (testimonial, key) => (
                          <tr
                            key={key}
                            className="d-flex"
                            style={{ width: "370px" }}
                          >
                            <div
                              className="media w-100 align-items-center mb-3 bg-light rounded mb-0 flex-column justify-content-center p-2"
                              style={{
                                position: "relative",
                              }}
                            >
                              <div className="me-2 w-100 align-items-center mb-2 d-flex flex-column">
                                <img
                                  src={testimonial?.image?.url}
                                  className="avatar-md d-block "
                                  style={{
                                    objectFit: "cover",
                                    borderRadius: "50%",
                                    marginBottom: 10,
                                  }}
                                />

                                <MyRating
                                  className="text-warning mb-2"
                                  size="1rem"
                                  initialRating={testimonial?.rating}
                                  onChange={value =>
                                    setTestimonialArray(
                                      testimonialArray?.map(i =>
                                        i?.id === testimonial?.id
                                          ? { ...i, rating: value }
                                          : i
                                      )
                                    )
                                  }
                                />
                              </div>

                              <div className="media-body w-100 text-center overflow-hidden px-2">
                                <AvField
                                  name="testimonialMsg"
                                  className="mb-3 w-100"
                                  type="textarea"
                                  value={testimonial?.message}
                                  onChange={e =>
                                    setTestimonialArray(
                                      testimonialArray?.map(i =>
                                        i?.id === testimonial?.id
                                          ? { ...i, message: e.target.value }
                                          : i
                                      )
                                    )
                                  }
                                />
                                <AvField
                                  name="testimonialName"
                                  className="mb-3 w-100"
                                  type="textarea"
                                  value={testimonial?.name}
                                  onChange={e =>
                                    setTestimonialArray(
                                      testimonialArray?.map(i =>
                                        i?.id === testimonial?.id
                                          ? { ...i, name: e.target.value }
                                          : i
                                      )
                                    )
                                  }
                                />
                              </div>
                              <i
                                className="bx bx-trash-alt ms-4 "
                                style={{
                                  fontSize: "1rem",
                                  color: "red",
                                  cursor: "pointer",
                                  position: "absolute",
                                  right: 8,
                                  top: 8,
                                }}
                                onClick={() =>
                                  setTestimonialArray(
                                    testimonialArray?.filter(
                                      item => item?.id !== testimonial?.id
                                    )
                                  )
                                }
                              ></i>
                            </div>
                          </tr>
                        ))}
                      </ReactDragListView>
                      {testimonialArray?.length >= 1 &&
                        updateData?.isUpdate && (
                          <p className="text-muted mt-4">
                            Drag Item to rearrange
                          </p>
                        )}
                    </div>
                  </div>
                )}

                {/* FAQ */}
                {layout === "faqData" && (
                  <div>
                    <CardBody
                      className="p-3"
                      style={{
                        backgroundColor: "#f7f7f7",
                        borderRadius: 5,
                      }}
                    >
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="me-4" style={{ flex: 1 }}>
                          <AvField
                            name="question"
                            className="mb-3"
                            label="Question"
                            type="text"
                            value={faq?.question}
                            onChange={e =>
                              setFaq({ ...faq, question: e.target.value })
                            }
                          />
                          <AvField
                            name="answer"
                            className="mb-3"
                            label="Answer"
                            type="textarea"
                            value={faq?.answer}
                            onChange={e =>
                              setFaq({ ...faq, answer: e.target.value })
                            }
                            style={{
                              height: 100,
                            }}
                          />
                          <div className="w-100 d-flex justify-content-end">
                            <button
                              type="button"
                              style={{ borderRadius: 10, maxWidth: 150 }}
                              className="btn btn-light w-50"
                              onClick={addNewFaq}
                            >
                              + Add FAQ
                            </button>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                    {faqArray?.length >= 1 && (
                      <div className="mt-4">
                        <ReactDragListView {...dragPropsFaq}>
                          {map(faqArray, (faq, key) => (
                            <tr
                              key={key}
                              className="d-flex mb-3"
                              style={{ borderRadius: 10 }}
                            >
                              <div
                                className="w-100 d-flex p-3"
                                style={{
                                  backgroundColor: "#f0f0f0",
                                  borderRadius: 10,
                                }}
                              >
                                <div style={{ flex: 1 }}>
                                  <AvField
                                    name="faqquestion"
                                    className="mb-3 w-100"
                                    type="textarea"
                                    value={faq?.question}
                                    onChange={e =>
                                      setFaqArray(
                                        faqArray?.map(i =>
                                          i?.id === faq?.id
                                            ? { ...i, question: e.target.value }
                                            : i
                                        )
                                      )
                                    }
                                  />
                                  <AvField
                                    name="faqanswer"
                                    className="w-100"
                                    type="textarea"
                                    value={faq?.answer}
                                    onChange={e =>
                                      setFaqArray(
                                        faqArray?.map(i =>
                                          i?.id === faq?.id
                                            ? { ...i, answer: e.target.value }
                                            : i
                                        )
                                      )
                                    }
                                  />
                                </div>
                                <i
                                  className="bx bx-trash-alt ms-4 "
                                  style={{
                                    fontSize: "1rem",
                                    color: "red",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    setFaqArray(
                                      faqArray?.filter(
                                        item => item?.id !== faq?.id
                                      )
                                    )
                                  }
                                ></i>
                              </div>
                            </tr>
                          ))}
                        </ReactDragListView>
                        {faqArray?.length >= 1 && updateData?.isUpdate && (
                          <p className="text-muted mt-4">
                            Drag Item to rearrange
                          </p>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </CardBody>
          </div>

          <div className="d-flex align-items-center justify-content-end mt-3 px-4 modal-footer-wrap">
            <button
              className="btn btn-outline-dark w-50 me-2"
              style={{ borderRadius: 10, maxWidth: 100 }}
              onClick={onCloseClick}
              type="button"
            >
              Cancel
            </button>
            <button
              type="submit"
              style={{ borderRadius: 10, maxWidth: 150 }}
              className="btn btn-success w-50"
            >
              {updateData?.isUpdate === true ? "Update" : "Create"}
              {sectionLoad && <Spinner />}
            </button>
          </div>
        </AvForm>
      </ModalBody>
    </Modal>
  )
}

export default AddSectionModal

AddSectionModal.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.bool,
  data: PropTypes.any,
}

const deleteModalStyle = {}
const deleteModalBodyStyle = {
  minHeight: 300,
  maxHeight: 800,
}
