import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_PAGEVIEWS,
  GET_PAGEVIEW_DETAILS,
  CREATE_PAGEVIEW,
  UPDATE_PAGEVIEW,
  DELETE_PAGEVIEW,
} from "./actionTypes"
import {
  getPageViewsFail,
  getPageViewsSuccess,
  getPageViewDetailsSuccess,
  getPageViewDetailsFail,
  createPageViewFail,
  createPageViewSuccess,
  updatePageViewSuccess,
  updatePageViewFail,
  deletePageViewSuccess,
  deletePageViewFail,
} from "./actions"

import { get, post, ApiPut, del } from "helpers/api_helper"
import { Notification } from "../../components/Common/Notification"

// PageView - This line cannot be edited or removed
function getPageViewsAPi({ page, sort, limit, searchText, groweb }) {
  return get(
    `/page-view/admin/all/?sort=${sort}&page=${page ? page : 1}&limit=${
      limit ? limit : 10
    }&search=${searchText ? searchText : ""}&groweb=${groweb ? groweb : ""}`
  )
}

const getPageViewDetailsAPi = pageviewId => {
  return get(`/pageview/admin/single/${pageviewId}`)
}

const createPageViewApi = ({ pageview }) => {
  return post("/pageview/admin/new", pageview)
}

const updatePageViewApi = ({ pageview, pageviewId }) => {
  return ApiPut(`/pageview/admin/${pageviewId}`, pageview)
}

const deletePageViewApi = pageviewId => {
  return del(`/pageview/admin/${pageviewId}`)
}

function* fetchPageViews({ payload }) {
  try {
    const response = yield call(getPageViewsAPi, payload)
    yield put(getPageViewsSuccess(response))
  } catch (error) {
    yield put(getPageViewsFail(error))
  }
}

function* fetchPageViewDetails({ payload: pageviewId }) {
  try {
    const response = yield call(getPageViewDetailsAPi, pageviewId)
    yield put(getPageViewDetailsSuccess(response))
  } catch (error) {
    yield put(getPageViewDetailsFail(error))
  }
}

function* onCreatePageView({ payload }) {
  try {
    const response = yield call(createPageViewApi, payload)
    if (response?.message) {
      Notification({
        type: "error",
        message: response?.message,
        title: "",
      })
    } else if (response) {
      yield put(createPageViewSuccess(response))
      payload?.history?.push("/pageviews")
      doneNotification("PageView Created Successfully!")
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: "error",
        message: "file size is Too Large",
        title: "",
      })
    }
    yield put(createPageViewFail(error))
  }
}

function* onUpdatePageView({ payload }) {
  try {
    const response = yield call(updatePageViewApi, payload)
    if (response) {
      Notification({
        type: "success",
        message: "PageView Updated Successfully!",
        title: "",
      })
      yield put(updatePageViewSuccess(response))
      if (payload.history) {
        payload.history.push("/pageviews")
      }
    }
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: "error",
        message: "file size is Too Large",
        title: "",
      })
    }
    yield put(updatePageViewFail(error.response.data))
  }
}

function* onDeletePageView({ PageViewId, history }) {
  try {
    const response = yield call(deletePageViewApi, PageViewId)
    yield put(deletePageViewSuccess(response))
    Notification({
      type: "success",
      message: "PageView Deleted Successfully!",
      title: "",
    })
    history.goBack()
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    yield put(deletePageViewFail(error?.response?.data))
  }
}

function* pageviewSaga() {
  yield takeEvery(GET_PAGEVIEWS, fetchPageViews)
  yield takeEvery(GET_PAGEVIEW_DETAILS, fetchPageViewDetails)
  yield takeEvery(CREATE_PAGEVIEW, onCreatePageView)
  yield takeEvery(UPDATE_PAGEVIEW, onUpdatePageView)
  yield takeEvery(DELETE_PAGEVIEW, onDeletePageView)
}

export default pageviewSaga

function doneNotification(message) {
  Notification({
    type: "success",
    message: message,
    title: "",
  })
}
