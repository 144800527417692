import {
  GET_LEADS,
  GET_LEADS_SUCCESS,
  GET_LEADS_FAIL,
  GET_LEAD_DETAILS,
  GET_LEAD_DETAILS_SUCCESS,
  GET_LEAD_DETAILS_FAIL,
  CREATE_LEAD,
  CREATE_LEAD_SUCCESS,
  CREATE_LEAD_FAIL,
  UPDATE_LEAD,
  UPDATE_LEAD_SUCCESS,
  UPDATE_LEAD_FAIL,
  DELETE_LEAD,
  DELETE_LEAD_SUCCESS,
  DELETE_LEAD_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  leads: {
    leads: [],
    total: "",
    page: "",
  },
  leadDetails: {},
  error: {},
  loading: false,
}

const Lead = (state = INIT_STATE, action) => {
  switch (action.type) {
    // Lead - This line cannot be edited or removed
    case GET_LEADS:
    case GET_LEAD_DETAILS:
    case CREATE_LEAD:
    case UPDATE_LEAD:
    case DELETE_LEAD:
      return {
        ...state,
        loading: true,
      }

    case DELETE_LEAD_FAIL:
    case GET_LEADS_FAIL:
    case GET_LEAD_DETAILS_FAIL:
    case UPDATE_LEAD_FAIL:
    case CREATE_LEAD_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_LEADS_SUCCESS:
      return {
        ...state,
        leads: action.payload,
        error: {},
        loading: false,
      }

    case GET_LEAD_DETAILS_SUCCESS:
      return {
        ...state,
        leadDetails: action.payload,
        error: {},
        loading: false,
      }

    case CREATE_LEAD_SUCCESS:
      return {
        ...state,
        leads: {
          ...state.leads,
          leads: [action.payload, ...state.leads.leads],
          total: state.leads.total + 1,
        },
        leadDetails: action.payload,
        error: {},
        loading: false,
      }

    case UPDATE_LEAD_SUCCESS:
      return {
        ...state,
        leads: {
          ...state.leads,
          leads: state.leads.leads.map(lead =>
            lead._id === action.payload._id
              ? { ...lead, ...action.payload }
              : lead
          ),
        },
        leadDetails: action.payload,
        loading: false,
        error: {},
      }

    case DELETE_LEAD_SUCCESS:
      return {
        ...state,
        leads: {
          ...state.leads,
          leads: state.leads.leads.filter(
            lead => lead._id !== action.payload._id
          ),
        },
        error: {},
        loading: false,
      }
    default:
      return state
  }
}

export default Lead
