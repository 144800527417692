import { AvField, AvForm } from "availity-reactstrap-validation"
import Switch from "react-switch"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router"
import PropTypes from "prop-types"

import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  FormGroup,
  Table,
} from "reactstrap"
import { CardTitle, TabContent, TabPane } from "reactstrap"
import { Col, Container, Label, Row } from "reactstrap"
import Select from "react-select"

import Breadcrumbs from "../../../components/Common/Breadcrumb"

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import { WithContext as ReactTags } from "react-tag-input"
import "./style.css"

const KeyCodes = {
  comma: 188,
  enter: 13,
}

const delimiters = [KeyCodes.comma, KeyCodes.enter]

//actions
import {
  createClient,
  createLead,
  createReview,
  getAllProducts,
  getGroWebs,
  getGrowPages,
  getLeadDetails,
  getRawUsers,
  getSections,
  updateClient,
  updateLead,
  updateReview,
} from "store/actions"

import LanguageTab from "components/Common/LanguageTab"
import { LanguageSwitch } from "hooks/LanguageSwitch"
import DatePikerTime from "components/Common/TimePicker"
import MetaTag from "components/Common/Meta-tag"
import UseImageUploader from "components/imageUploader/useImageUploader"
import { lastSpaceRemover } from "hooks/LastSpaceRemover"
import { useSiteVersion } from "hooks/useSiteVersion"
import { debounce } from "lodash"
import useGetStore from "hooks/useGetStore"
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min"

const CreateProduct = ({ growebId }) => {
  const dispatch = useDispatch()
  const params = useParams()
  const history = useHistory()
  const location = useLocation()
  const [imagesPreview, setImagesPreview] = useState([])
  const [productSelect, setProductSelect] = useState({
    title: "Select Product",
    id: "",
  })
  const [productSearch, setProductSearch] = useState()

  const [groWebSelect, setGroWebSelect] = useState({
    title: "Select Groweb",
    id: "",
  })
  const [groWebSearch, setGroWebSearch] = useState()

  const [groPageSelect, setGroPageSelect] = useState({
    title: "Select GroPage",
    id: "",
  })
  const [groPageSearch, setGroPageSearch] = useState()

  const [sectionSelect, setSectionSelect] = useState({})
  const [sectionSearch, setSectionSearch] = useState()

  const [isGrowebSingle, setIsGrowebSingle] = useState(false)
  useEffect(() => {
    if (location?.state?._id) {
      setIsGrowebSingle(true)
    }
  }, [location])
  console.log(groWebSelect)
  const { products, users, loading, GroWeb, growPages, updateData } =
    useSelector(state => ({
      loading: state.Products.loading,
      usersLoading: state.Users.loading,
      products: state.Products.products,
      GroWeb: state.GroWeb.growebs,
      growPages: state.GrowPage.growpages,
      // Sections: state.Section.sections,
      updateData: state.Lead.leadDetails,
    }))
  console.log(updateData)
  const handleValidSubmit = (e, v) => {
    const lead = {
      ...v,
      images: imagesPreview,
      product: productSelect?.id,
      groweb: groWebSelect?.id,
      growpage: groPageSelect?.id,
      product: productSelect?.id,
      section: sectionSelect?.id,
    }
    if (updateData?._id) {
      dispatch(updateLead(lead, updateData?._id, history)), [dispatch, history]
    } else {
      dispatch(createLead(lead, history, isGrowebSingle)),
        [dispatch, history, isGrowebSingle]
    }
  }
  useEffect(() => {
    if (params.id) {
      dispatch(getLeadDetails(params.id))
    }
  }, [dispatch])
  useEffect(() => {
    dispatch(getGroWebs(1, "", "", "", groWebSearch))
  }, [dispatch])
  const debounceGroWebSearch = debounce(value => setGroWebSearch(value), 600)
  //client dropdown
  const handleGroWebEnters = textEntered => {
    debounceGroWebSearch(textEntered)
  }

  function handlerGroWebFinalValue(event) {
    const groweb = {
      ...groWebSelect,
      title: event.value !== "loading" && event.label,
      id: event.value,
    }
    setGroWebSelect(groweb)
  }

  const groWebOptions = [
    {
      label: "All GroWeb",
      value: "",
    },
    {
      options: (GroWeb?.growebs?.length >= 1 ? GroWeb?.growebs : [])?.map(
        (results, index) => ({
          key: index,
          label: results.name,
          value: results._id,
        })
      ),
    },
  ]
  useEffect(() => {
    dispatch(getGrowPages(1, "", "", "", groPageSearch))
  }, [dispatch])
  const debounceGroPageSearch = debounce(value => setGroPageSearch(value), 600)
  //client dropdown
  const handleGroPageEnters = textEntered => {
    debounceGroPageSearch(textEntered)
  }

  function handlerGroPageFinalValue(event) {
    const growPage = {
      ...groPageSelect,
      title: event.value !== "loading" && event.label,
      id: event.value,
    }
    setGroPageSelect(growPage)
  }

  const groPageOptions = [
    {
      label: "All growPages",
      value: "",
    },
    {
      options: (growPages?.growpages?.length >= 1
        ? growPages?.growpages
        : []
      )?.map((results, index) => ({
        key: index,
        label: results.title,
        value: results._id,
      })),
    },
  ]

  // useEffect(() => {
  //   dispatch(getSections(1, "", "", sectionSearch))
  // }, [dispatch])

  const debounceSectionSearch = debounce(value => setSectionSearch(value), 600)

  const handleSectionEnters = textEntered => {
    debounceSectionSearch(textEntered)
  }

  function handlerSectionFinalValue(event) {
    const product = {
      ...sectionSelect,
      title: event.value !== "loading" && event.label,
      id: event.value,
    }
    setSectionSelect(product)
  }

  const SectionOptions = [
    {
      label: "All sections",
      value: "",
    },
    {
      options: ([]?.length >= 1 ? [] : [])?.map((results, index) => ({
        key: index,
        label: results.title,
        value: results._id,
      })),
    },
  ]
  // ..... section..........
  useEffect(() => {
    dispatch(getAllProducts(1, "all", "", productSearch))
  }, [dispatch, productSearch])

  const debounceProductSearch = debounce(value => setProductSearch(value), 600)

  const handleProductEnters = textEntered => {
    debounceProductSearch(textEntered)
  }

  function handlerProductFinalValue(event) {
    const product = {
      ...productSelect,
      title: event.value !== "loading" && event.label,
      id: event.value,
    }
    setProductSelect(product)
  }

  const productOptions = (products?.length >= 1 ? products : [])?.map(
    (result, index) => ({
      key: index,
      label: result?.primaryLang?.name,
      value: result?._id,
    })
  )
  useEffect(() => {
    setProductSelect({
      title: updateData?.product?.primaryLang?.name,
      id: updateData?.product?._id,
    })
    setGroWebSelect({
      title: updateData?.groweb?.name || location?.state?.name,
      id: updateData?.groweb?._id || location?.state?._id,
    })
    setGroPageSelect({
      ...groPageSelect,
      title: updateData?.growpage?.title,
      id: updateData?.growpage?._id,
    })
    setSectionSelect({
      title: updateData?.section?.title,
      id: updateData?.section?._id,
    })
    setImagesPreview(updateData?.images)
  }, [updateData])
  console.log(growebId)
  return (
    <>
      <MetaTag title="lead crud " />

      <div className="page-content">
        <Breadcrumbs
          title={params.id ? "Update " : "Create "}
          breadcrumbItem={params.id ? "Update Lead" : "Create Lead"}
        />
        <Container fluid>
          <div className="container-fluid">
            <AvForm
              onValidSubmit={(e, v) => {
                handleValidSubmit(e, v)
              }}
            >
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <Row className="mb-2">
                      <>
                        <Col className="col-12 mb-2">
                          <div className="d-flex justify-content-between mb-2">
                            <CardSubtitle>Name </CardSubtitle>
                          </div>
                          <AvField
                            name="name"
                            type="text"
                            placeholder="Enter Name"
                            value={updateData?.name}
                          />
                        </Col>
                      </>
                    </Row>
                    <Row className="mb-2">
                      <Col className="col-6">
                        <div className="d-flex justify-content-between mb-2">
                          <CardSubtitle>Phone </CardSubtitle>
                        </div>
                        <AvField
                          name="phone"
                          type="number"
                          placeholder="Enter Phone"
                          value={updateData?.phone}
                          validate={{
                            minLength: {
                              value: 10,
                              errorMessage: "phone number must be 10 letter ",
                            },
                            maxLength: {
                              value: 10,
                              errorMessage: "phone number must be 10 letter ",
                            },
                          }}
                        />
                      </Col>
                      <Col className="col-6 mb-2">
                        <div className="d-flex justify-content-between mb-2">
                          <CardSubtitle>Email </CardSubtitle>
                        </div>
                        <AvField
                          name="email"
                          type="email"
                          placeholder="Enter Email"
                          value={updateData?.email}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col lg={6} className="">
                        <p className="h4 mb-2 text-muted font-size-14">
                          Products
                        </p>
                        <FormGroup className="mb-3">
                          <div className="ajax-select mt-lg-0 select2-container">
                            <Select
                              onInputChange={handleProductEnters}
                              value={productSelect?.title}
                              placeholder={productSelect?.title}
                              onChange={handlerProductFinalValue}
                              options={productOptions}
                              classNamePrefix="select2-selection"
                              isLoading={loading}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm={8} lg="6">
                        <p className="h4 mb-2 text-muted font-size-14">
                          Section
                        </p>

                        <FormGroup className="mb-3">
                          <div className="ajax-select mt-lg-0 select2-container">
                            <Select
                              onInputChange={handleSectionEnters}
                              value={sectionSelect?.title}
                              placeholder={sectionSelect?.title}
                              onChange={handlerSectionFinalValue}
                              options={SectionOptions}
                              classNamePrefix="select2-selection"
                              isLoading={loading}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col sm={8} lg="6">
                        <p className="h4 mb-2 text-muted font-size-14">
                          GroPage
                        </p>

                        <FormGroup className="mb-3">
                          <div className="ajax-select mt-lg-0 select2-container">
                            <Select
                              onInputChange={handleGroPageEnters}
                              value={groPageSelect?.title}
                              placeholder={groPageSelect?.title}
                              onChange={handlerGroPageFinalValue}
                              options={groPageOptions}
                              classNamePrefix="select2-selection"
                              isLoading={loading}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm={8} lg="6">
                        <p className="h4 mb-2 text-muted font-size-14">
                          GroWeb
                        </p>
                        {location && location?.state ? (
                          <AvField
                            name="GroWeb"
                            value={location?.state?.name}
                            readOnly
                          />
                        ) : (
                          <Select
                            onInputChange={handleGroWebEnters}
                            value={groWebSelect?.title}
                            placeholder={groWebSelect?.title}
                            onChange={handlerGroWebFinalValue}
                            options={groWebOptions}
                            classNamePrefix="select2-selection"
                            isLoading={loading}
                          />
                        )}
                      </Col>
                      {/* <Col sm={8} lg="6">
                          <p className="h4 mb-2 text-muted font-size-14">
                            GroWeb
                          </p>

                          <FormGroup className="mb-3">
                            <div className="ajax-select mt-lg-0 select2-container">
                              <Select
                                onInputChange={handleGroWebEnters}
                                value={groWebSelect?.title}
                                placeholder={groWebSelect?.title}
                                onChange={handlerGroWebFinalValue}
                                options={groWebOptions}
                                classNamePrefix="select2-selection"
                                isLoading={loading}
                              />
                            </div>
                          </FormGroup>
                        </Col> */}
                    </Row>
                    <Row className="mb-2">
                      <Col className="col-12 ">
                        <div className="col-12 col mb-4">
                          <Label
                            htmlFor="horizontal-firstname-Input"
                            className="col-sm-4 col-form-label"
                          >
                            Message:
                          </Label>
                          <Col sm={12}>
                            <AvField
                              type="textarea"
                              name="message"
                              value={updateData?.message}
                            ></AvField>
                          </Col>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Card>
                        <CardBody>
                          <div className="mt-1  col-12">
                            <h6 className="">images :</h6>
                            <UseImageUploader
                              imageSize={4}
                              imagesPreview={imagesPreview}
                              setImagesPreview={setImagesPreview}
                              uploadUrl={"/growebosperb"}
                              isMultiple
                            />
                          </div>
                        </CardBody>
                      </Card>
                    </Row>

                    <Row xl="12">
                      <Col xl="12">
                        <Card>
                          <CardBody>
                            <CardTitle className="h4 mb-4 text_deals_green"></CardTitle>
                            <Row>
                              <Col>
                                <div className="text-end">
                                  <button
                                    type="submit"
                                    className="col-12 btn btn-success save-customer"
                                  >
                                    {/* {loading && (
                                      <>
                                        <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                      </>
                                    )} */}
                                    Save
                                  </button>
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </AvForm>
          </div>
        </Container>
      </div>
    </>
  )
}

export default CreateProduct
CreateProduct.propTypes = {
  updateData: PropTypes.any,
  growebId: PropTypes.string,
}
