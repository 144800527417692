import MetaTag from "components/Common/Meta-tag"
import React, { useEffect, useState } from "react"

import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Nav,
  NavItem,
  Row,
  TabContent,
  TabPane,
  NavLink,
  Container,
  Badge,
} from "reactstrap"
import classnames from "classnames"
import { useDispatch, useSelector } from "react-redux"
import GrowPages from "pages/GrowPage/DataTables"
import Leads from "pages/Lead/DataTables"
import PageViews from "pages/PageView/DataTables"
import Products from "pages/Product/DatatableTables"

import GrowebCreateForm from "../Crud/Details"
import Breadcrumbs from "components/Common/Breadcrumb"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { getGroWebDetails, getGrowebDashboardData } from "store/actions"
import SingleViewDetails from "./signleViewDetails"

function GrowebDetails() {
  const params = useParams()
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const { loading, growebDetails, growebDashboardData } = useSelector(
    state => ({
      loading: state.GroWeb.loading,
      growebDetails: state.GroWeb.growebDetails,
      growebDashboardData: state.GroWeb.growebDashboardData,
    })
  )

  console.log(location)

  useEffect(() => {
    if (params.id) {
      dispatch(getGroWebDetails(params.id))
    }
  }, [dispatch])

  const [customActiveTab, setCustomActiveTab] = useState("1")

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setCustomActiveTab(tab)
    }
  }

  useEffect(() => {
    if (location?.active) {
      setCustomActiveTab(location?.active)
    }
  }, [location?.active])

  useEffect(() => {
    if (params.id) {
      dispatch(getGrowebDashboardData(params.id))
    }
  }, [dispatch])

  return (
    <>
      <MetaTag title={"groweb"} />
      <div className="page-content">
        <Breadcrumbs title="Dashboard" breadcrumbItem="Groweb" />
        <Container fluid>
          <div className="container-fluid">
            <Card style={{ borderRadius: "15px" }}>
              <CardBody>
                <CardTitle className="h4 mb-4 pb-2">Groweb Details</CardTitle>
                <div
                  style={{
                    borderBottom: "2px solid #f6f6f6",
                  }}
                >
                  <Nav
                    tabs
                    className="nav-tabs-custom  nav-justified"
                    style={{ maxWidth: "auto" }}
                  >
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "1",
                        })}
                        onClick={() => {
                          toggleCustom("1")
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="fas fa-home"></i>
                        </span>
                        <span className="d-none d-sm-block">Home</span>
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "2",
                        })}
                        onClick={() => {
                          toggleCustom("2")
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="far fa-user"></i>
                        </span>
                        <span className="d-none d-sm-block">
                          Grow Pages&nbsp;
                          <Badge
                            className={`font-size-12 ms-2 badge-soft-success`}
                            pill
                          >
                            {growebDashboardData?.growpageCount}
                          </Badge>
                        </span>
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "3",
                        })}
                        onClick={() => {
                          toggleCustom("3")
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="far fa-envelope"></i>
                        </span>
                        <span className="d-none d-sm-block">
                          Leads
                          <Badge
                            className={`font-size-12 ms-2 badge-soft-success`}
                            pill
                          >
                            {growebDashboardData?.leadCount}
                          </Badge>
                        </span>
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "4",
                        })}
                        onClick={() => {
                          toggleCustom("4")
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="fas fa-cog"></i>
                        </span>
                        <span className="d-none d-sm-block">
                          Pageviews
                          <Badge
                            className={`font-size-12 ms-2 badge-soft-success`}
                            pill
                          >
                            {growebDashboardData?.pageViewCount}
                          </Badge>
                        </span>
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "5",
                        })}
                        onClick={() => {
                          toggleCustom("5")
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="fas fa-cog"></i>
                        </span>
                        <span className="d-none d-sm-block">
                          Product
                          <Badge
                            className={`font-size-12 ms-2 badge-soft-success`}
                            pill
                          >
                            {growebDashboardData?.productCount}
                          </Badge>
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "6",
                        })}
                        onClick={() => {
                          toggleCustom("6")
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="fas fa-cog"></i>
                        </span>
                        <span className="d-none d-sm-block">Update Groweb</span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>

                <TabContent
                  activeTab={customActiveTab}
                  className="p-3 text-muted"
                >
                  <TabPane tabId="1">
                    <Row>
                      <Col sm="12">
                        <SingleViewDetails toggleCustom={toggleCustom} />
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="2">
                    <Row>
                      <Col sm="12">
                        {customActiveTab === "2" ? (
                          <GrowPages growebId={growebDetails?._id} />
                        ) : (
                          <div>No GrowPages</div>
                        )}
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="3">
                    <Row>
                      <Col sm="12">
                        {customActiveTab === "3" ? (
                          <Leads growebDetails={growebDetails} />
                        ) : (
                          <div>No Leads</div>
                        )}
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="4">
                    <Row>
                      <Col sm="12">
                        {customActiveTab === "4" ? (
                          <PageViews growebDetails={growebDetails} />
                        ) : (
                          <div>No PageViews</div>
                        )}
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="5">
                    <Row>
                      <Col sm="12">
                        {customActiveTab === "5" ? (
                          <Products growebId={growebDetails?._id} />
                        ) : (
                          <div>No Product</div>
                        )}
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="6">
                    <Row>
                      <Col sm="12">
                        {/* {customActiveTab === "6" && (
                          <GrowebCreateForm
                            isUpdate={true}
                            growebDetails={growebDetails}
                          />
                        )} */}
                        {customActiveTab === "6" ? (
                          <GrowebCreateForm
                            isUpdate={true}
                            growebDetails={growebDetails}
                            setCustomActiveTab={setCustomActiveTab}
                          />
                        ) : (
                          <div>No Details</div>
                        )}
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </div>
        </Container>
      </div>
    </>
  )
}

export default GrowebDetails
