import { useSelector } from "react-redux"

export const useSiteVersion = () => {
  const version = {
    isBasic: false,
    isPremium: true,
    isAdvanced: false,
    currentVersion: "premium",
  }
  if ("basic") {
    return {
      ...version,
      isBasic: true,
      currentVersion: "basic",
    }
  } else if ("premium") {
    return {
      ...version,
      isPremium: true,
      currentVersion: "premium",
    }
  } else if ("advanced") {
    return {
      ...version,
      // isAdvanced: true,
      // currentVersion: "advanced",
      isPremium: true,
      currentVersion: "premium",
    }
  }
  return version
}
