import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_LEADS,
  GET_LEAD_DETAILS,
  CREATE_LEAD,
  UPDATE_LEAD,
  DELETE_LEAD,
} from "./actionTypes"
import {
  getLeadsFail,
  getLeadsSuccess,
  getLeadDetailsSuccess,
  getLeadDetailsFail,
  createLeadFail,
  createLeadSuccess,
  updateLeadSuccess,
  updateLeadFail,
  deleteLeadSuccess,
  deleteLeadFail,
} from "./actions"

import { get, post, ApiPut, del } from "helpers/api_helper"
import { Notification } from "../../components/Common/Notification"

// Lead - This line cannot be edited or removed
function getLeadsAPi({ page, sort, limit, groweb, gropage, product }) {
  return get(
    `/lead/admin/all/?sort=${sort}&page=${page ? page : 1}&limit=${
      limit ? limit : 10
    }&groweb=${groweb ? groweb : ""}&growpage=${
      gropage ? gropage : ""
    }&product=${product ? product : ""}`
  )
}

const getLeadDetailsAPi = leadId => {
  return get(`/lead/admin/single/${leadId}`)
}

const createLeadApi = ({ lead, history, isGrowebSingle }) => {
  return post(
    "/lead/admin/new",
    lead,
    { history: history ? history : "" },
    { isGrowebSingle: isGrowebSingle ? isGrowebSingle : "" }
  )
}

// const updateLeadApi = ({ lead, leadId,history }) => {
//   return ApiPut(`/lead/admin/${leadId}`, lead,history={history ? history : ""})
// }
const updateLeadApi = ({ lead, leadId, history }) => {
  return ApiPut(`/lead/admin/${leadId}`, lead, {
    history: history ? history : "",
  })
}

const deleteLeadApi = ({ leadId }) => {
  return del(`/lead/admin/${leadId}`)
}

function* fetchLeads({ payload }) {
  try {
    const response = yield call(getLeadsAPi, payload)
    yield put(getLeadsSuccess(response))
  } catch (error) {
    yield put(getLeadsFail(error))
  }
}

function* fetchLeadDetails({ payload: leadId }) {
  try {
    const response = yield call(getLeadDetailsAPi, leadId)
    yield put(getLeadDetailsSuccess(response))
  } catch (error) {
    yield put(getLeadDetailsFail(error))
  }
}
function* onCreateLead({ payload }) {
  try {
    const response = yield call(createLeadApi, payload)
    if (response) {
      Notification({
        type: "success",
        message: "Lead Create Successfully!",
        title: "",
      })
      yield put(createLeadSuccess(response))
      // if (payload.history) {
      //   payload.history.push("/leads")
      // }
      if (payload.isGrowebSingle) {
        payload.history.push("/leads")
      } else {
        payload.history.push(`/groWeb/update/${response.groweb}`)
      }
    }
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: "error",
        message: "file size is Too Large",
        title: "",
      })
    }
    yield put(createLeadFail(error))
  }
}

// function* onCreateLead({ payload }) {
//   try {
//     const response = yield call(createLeadApi, payload)
//     if (response?.message) {
//       Notification({
//         type: "error",
//         message: response?.message,
//         title: "",
//       })
//     } else if (response) {
//       yield put(createLeadSuccess(response))
//       if (payload.history) {
//         payload.history.push("/leads")
//       }
//       doneNotification("Lead Created Successfully!")
//     }
//   } catch (error) {
//     if (error?.response?.data?.message) {
//       Notification({
//         type: "error",
//         message: error?.response?.data?.message,
//         title: "",
//       })
//     }
//     if (error?.response?.status === 413) {
//       Notification({
//         type: "error",
//         message: "file size is Too Large",
//         title: "",
//       })
//     }
//     yield put(createLeadFail(error))
//   }
// }

function* onUpdateLead({ payload }) {
  try {
    const response = yield call(updateLeadApi, payload)
    if (response) {
      Notification({
        type: "success",
        message: "Lead Updated Successfully!",
        title: "",
      })
      yield put(updateLeadSuccess(response))
      if (payload.history) {
        payload.history.push("/leads")
      }
    }
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: "error",
        message: "file size is Too Large",
        title: "",
      })
    }
    yield put(updateLeadFail(error.response.data))
  }
}

function* onDeleteLead({ payload }) {
  try {
    const response = yield call(deleteLeadApi, payload)
    yield put(deleteLeadSuccess(response))
    doneNotification("Lead Deleted Successfully!")
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    yield put(deleteLeadFail(error?.response?.data))
  }
}

function* leadSaga() {
  yield takeEvery(GET_LEADS, fetchLeads)
  yield takeEvery(GET_LEAD_DETAILS, fetchLeadDetails)
  yield takeEvery(CREATE_LEAD, onCreateLead)
  yield takeEvery(UPDATE_LEAD, onUpdateLead)
  yield takeEvery(DELETE_LEAD, onDeleteLead)
}

export default leadSaga

function doneNotification(message) {
  Notification({
    type: "success",
    message: message,
    title: "",
  })
}
