import { AvField, AvForm } from "availity-reactstrap-validation"
import Switch from "react-switch"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router"
import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  FormGroup,
  Table,
} from "reactstrap"
import { CardTitle, TabContent, TabPane } from "reactstrap"
import { Col, Container, Label, Row } from "reactstrap"
import Select from "react-select"

import Breadcrumbs from "../../../../components/Common/Breadcrumb"

// Form Editor
import FormEditors from "./Editor"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import { WithContext as ReactTags } from "react-tag-input"
import "./style.css"

const KeyCodes = {
  comma: 188,
  enter: 13,
}

const delimiters = [KeyCodes.comma, KeyCodes.enter]

//actions
import {
  checkProductName,
  createProduct,
  getGroWebs,
  getPages,
  getStoreList,
} from "store/actions"

import LanguageTab from "components/Common/LanguageTab"
import { LanguageSwitch } from "hooks/LanguageSwitch"
import DatePikerTime from "components/Common/TimePicker"
import MetaTag from "components/Common/Meta-tag"
import UseImageUploader from "components/imageUploader/useImageUploader"
import { lastSpaceRemover } from "hooks/LastSpaceRemover"
import { useSiteVersion } from "hooks/useSiteVersion"
import { debounce } from "lodash"
import useGetStore from "hooks/useGetStore"

const CreateProduct = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { isPremium } = useSiteVersion()
  const { isStoreAdmin, currentStoreId } = useGetStore()
  const {
    loading,
    GroWeb,
    productNameCheck,
    // pagesList,
    pageLoading,
    StoreLoading,
  } = useSelector(state => ({
    loading: state.GroWeb.loading,
    GroWeb: state.GroWeb.growebs,
    productNameCheck: state.Products.checkProductNames,
    // pagesList: state.Pages.pages,
    pageLoading: state.GroWeb.loading,
    StoreLoading: state.GroWeb.loading,
  }))

  const [storeSearchText, setStoreSearchText] = useState("")
  const [selectedStore, setSelectedStore] = useState("All Store")
  const [finalStoreId, setFinalStoreId] = useState("")

  const [groWebSelect, setGroWebSelect] = useState({})
  const [groWebSearch, setGroWebSearch] = useState()

  useEffect(() => {
    dispatch(getGroWebs("", "", "", "", groWebSearch))
  }, [dispatch])

  const debounceGroWebSearch = debounce(value => setGroWebSearch(value), 600)
  //client dropdown
  const handleGroWebEnters = textEntered => {
    debounceGroWebSearch(textEntered)
  }

  function handlerGroWebFinalValue(event) {
    const client = {
      ...groWebSelect,
      title: event.value !== "loading" && event.label,
      id: event.value,
    }
    setGroWebSelect(client)
  }

  const groWebOptions = [
    {
      label: "All GroWeb",
      value: "",
    },
    {
      options: (GroWeb?.growebs?.length >= 1 ? GroWeb?.growebs : [])?.map(
        (results, index) => ({
          key: index,
          label: results.name,
          value: results._id,
        })
      ),
    },
  ]
  const [selectedMulti, setselectedMulti] = useState(null)
  // const optionGroup =  GroWeb?.growebs?.map(growebs => {
  //   return {
  //     value: growebs._id,
  //     label: growebs.primaryLang.name,
  //   }
  // })

  // function handleMulti(categoryData) {
  //   setselectedMulti(categoryData)
  //   const arrangedCategories = categoryData.map(a => a.value)
  //   setCategories(arrangedCategories)
  // }

  const [tags, setTags] = useState([])

  // const suggestions = GroWeb?.growebs?.map(growebs => {
  //   return {
  //     id: growebs._id,
  //     text: growebs.primaryLang.name,
  //   }
  // })

  const handleDeleteTag = i => {
    setTags(tags.filter((tag, index) => index !== i))
    const arrangedKeywords = tags.map(a => a.text)
    setKeywords(arrangedKeywords)
  }

  const handleAddition = tag => {
    setTags([...tags, tag])
    const arrangedKeywords = [...tags, tag].map(a => a.text)
    setKeywords(arrangedKeywords)
  }

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice()
    newTags.splice(currPos, 1)
    newTags.splice(newPos, 0, tag)
    // re-render
    setTags(newTags)
    const arrangedKeywords = newTags.map(a => a.text)
    setKeywords(arrangedKeywords)
  }

  const handleTagClick = index => {
    console.log("The tag at index " + index + " was clicked")
  }

  const [varients, setVarients] = useState([
    {
      value: "",
      unit: "kg",
      cost: "",
      price: "",
      offerPrice: "",
      primePrice: "",
      stock: "",
    },
  ])

  const [selectDateTimeFrom, setSelectDateTimeFrom] = useState("00:00")
  const [selectDateTimeTo, setSelectDateTimeTo] = useState("00:00")

  const [imagesPreview, setImagesPreview] = useState([])
  const [maxOrderQuantity, setMaxOrderQuantity] = useState("")
  const [minOrderQuantity, setMinOrderQuantity] = useState("")

  const [primaryLangName, setPrimaryLangName] = useState("")
  const [primaryLangDescription, setPrimaryLangDescription] = useState("")
  const [secondaryLangName, setSecondaryLangName] = useState("")
  const [secondaryLangDescription, setSecondaryLangDescription] = useState("")
  const [primaryLangHighlights, setPrimaryLangHighlights] = useState("")
  const [secondaryLangHighlights, setSecondaryLangHighlights] = useState("")

  const [keywords, setKeywords] = useState([])
  const [categories, setCategories] = useState([])

  //packageData
  const [isBulkyProduct, setIsBulkyProduct] = useState(false)

  //shippingData
  const [isExtraChargeNeeded, setIsExtraChargeNeeded] = useState(false)
  const [extraCharge, setExtraCharge] = useState()
  const [isAvailableEveryWhere, setIsAvailableEveryWhere] = useState(true)

  //fittingData
  const [fittingCharge, setFittingCharge] = useState()
  const [isFittingNeeded, setIsFittingNeeded] = useState(false)
  const [isFitted, setIsFitted] = useState(false)

  //labels
  const [isReturnViable, setIsReturnViable] = useState(false)
  const [isDailyProduct, setIsDailyProduct] = useState(false)
  const [isSpecialOffer, setIsSpecialOffer] = useState(false)
  const [isPageAttached, setIsPageAttached] = useState(false)

  const [isFeatured, setIsFeatured] = useState(false)
  const [extras, setExtras] = useState([])

  const [productName, setProductName] = useState()

  const [pageSelect, setPageSelect] = useState({
    id: "",
    title: "Search a Pages",
  })

  const [minStock, setMinStock] = useState(0)
  const [reorderQuantity, setReorderQuantity] = useState(0)

  // const taxCategories = [0, 5, 8, 12, 15, 18, 28]

  const [hasVarients, setHasVarients] = useState(false)

  const [activeTab, setActiveTab] = useState("1")

  const isMult = LanguageSwitch()?.isMult

  function handleValidSubmit(e, values) {
    setPrimaryLangName(lastSpaceRemover(primaryLangName))
    const product = {
      ...values,
      // store: finalStoreId,
      groweb: groWebSelect.id,
      images: imagesPreview,
      itemCode: values?.itemCode,
      barcode: values?.barcode,
      primaryLang: {
        name: lastSpaceRemover(primaryLangName),
        description: primaryLangDescription,
        highlights: primaryLangHighlights,
      },
      secondaryLang: {
        name: lastSpaceRemover(secondaryLangName),
        description: secondaryLangDescription,
        highlights: secondaryLangHighlights,
      },
      keywords,
      // categories,
      varients,
      hasVarients: hasVarients,

      //values
      cost: parseInt(values.cost),
      price: parseInt(values.price),
      offerPrice: parseInt(values.offerPrice),
      // primePrice: parseInt(values.primePrice),
      stock: parseInt(values.stock),

      // minStock: minStock,
      // reorderQuantity: reorderQuantity,

      // isPageAttached: isPageAttached,
      // page: pageSelect?.id || null,
      //labels
      // isReturnViable: isReturnViable,
      // isDailyProduct: isDailyProduct,
      // isSpecialOffer: isSpecialOffer,
      // isFeatured: isFeatured,
      // extras: extras,
      // maxOrderQuantity: parseInt(maxOrderQuantity),
      // minOrderQuantity: parseInt(minOrderQuantity),
      // availableTimeFrom: selectDateTimeFrom,
      // availableTimeTo: selectDateTimeTo,

      // packageData
      // isBulkyProduct: isBulkyProduct,

      // shippingData
      // isExtraChargeNeeded: isExtraChargeNeeded,
      // extraCharge: extraCharge,
      // isAvailableEveryWhere: isAvailableEveryWhere,

      // fittingData
      // isFittingNeeded: isFittingNeeded,
      // isFitted: isFitted,
      // fittingCharge: fittingCharge,
      // taxPercentage: parseInt(values?.taxPercentage),
      // packageData: {
      //   breadth: parseInt(values?.packageData?.breadth),
      //   height: parseInt(values?.packageData?.height),
      //   length: parseInt(values?.packageData?.length),
      //   weight: parseInt(values?.packageData?.weight),
      // },
    }
    // console.log(product)
    dispatch(createProduct(product, history))
  }

  const validateName = v => {
    let name = v.target.value
    name = name.replace(/-/g, "")
    name = name.replace("/", "")
    v.target.value = name

    setPrimaryLangName(name)
  }

  const [pageSearch, setPageSearch] = useState("")

  const debounceStoreSearch = debounce(value => setStoreSearchText(value), 600)

  const handleEnters = textEntered => {
    debounceStoreSearch(textEntered)
  }

  function handlerStoreFinalValue(event) {
    setSelectedStore(event.label)
    setFinalStoreId(event.id)
  }

  const storeOptions = [
    {
      label: "All Store",
      id: "",
    },
    {
      options: []?.map((result, index) => ({
        key: index,
        label: result?.storeName,
        id: result?._id,
      })),
    },
  ]

  const debounceProductSearch = debounce(value => setPageSearch(value), 600)
  const handleProductEnters = textEntered => {
    debounceProductSearch(textEntered)
  }

  function handlerPageFinalValue(event) {
    const product = {
      ...pageSelect,
      title: event.label,
      id: event.value,
      image: event.image,
      varients: event.varients,
    }
    setPageSelect(product)
  }

  const pageOptions = [
    {
      options: []?.pages?.map((result, index) => ({
        key: index,
        label: result?.title,
        value: result?._id,
      })),
    },
  ]

  // useEffect(() => {
  //   dispatch(getPages("", "", pageSearch, "", finalStoreId))
  // }, [pageSearch, finalStoreId, dispatch])

  return (
    <>
      <MetaTag title="Create Product" />

      <div className="page-content">
        <Breadcrumbs title="Products" breadcrumbItem="Create Product" />
        <Container fluid>
          <div className="container-fluid">
            <AvForm
              onValidSubmit={(e, v) => {
                handleValidSubmit(e, v)
              }}
            >
              {/* {!isStoreAdmin && (
                <Row xl="12">
                  <Col xl="12">
                    <Card>
                      <CardBody>
                        <CardTitle className="h4 mb-4 text_deals_green">
                          Store *
                        </CardTitle>
                        <Row>
                          <Col className="col-6 d-flex flex-wrap">
                            <div className="col-12 mb-3">
                              <Select
                                onInputChange={handleEnters}
                                value={selectedStore}
                                placeholder={selectedStore}
                                onChange={handlerStoreFinalValue}
                                options={storeOptions}
                                classNamePrefix="select-selection"
                                isLoading={StoreLoading}
                              />
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              )} */}

              <Row xl="12">
                {/* baseData */}
                <Col xl="12">
                  <Card>
                    <CardBody>
                      <CardTitle className="h4 mb-4 text_deals_green">
                        Base Data
                      </CardTitle>
                      <Row>
                        <Col className="" xl={6}>
                          <div className="d-flex">
                            <div className="mb-3">
                              <AvField
                                name="itemCode"
                                label="Item Code"
                                type="text"
                              />
                            </div>

                            <div className="mb-3 mx-3">
                              <AvField
                                name="barcode"
                                label="Barcode"
                                type="text"
                              />
                            </div>
                          </div>
                        </Col>
                        <Col xl="6">
                          <Label htmlFor="lbltype" className="form-label ">
                            GroWebs
                          </Label>
                          <Row>
                            <Col sm={8} lg="6">
                              <FormGroup className="mb-3">
                                <div className="ajax-select mt-lg-0 select2-container">
                                  <Select
                                    onInputChange={handleGroWebEnters}
                                    value={groWebSelect?.title}
                                    placeholder={groWebSelect?.title}
                                    onChange={handlerGroWebFinalValue}
                                    options={groWebOptions}
                                    classNamePrefix="select2-selection"
                                    isLoading={loading}
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                        {/* {isPremium && (
                          <>
                            {" "}
                            <Col className="col-8 d-flex flex-wrap flex-column  justify-content-start">
                              <div>
                                <Button
                                  color={isDailyProduct ? "success" : "light"}
                                  className="special_buttons mb-1 no-eff me-3"
                                  type="button"
                                  onClick={() => {
                                    setIsDailyProduct(!isDailyProduct)
                                  }}
                                >
                                  Daily
                                  <br /> Product
                                </Button>

                                <Button
                                  color={isSpecialOffer ? "success" : "light"}
                                  className="special_buttons mb-1 no-eff me-3"
                                  type="button"
                                  onClick={() => {
                                    setIsSpecialOffer(!isSpecialOffer)
                                  }}
                                >
                                  Special
                                  <br /> Offer
                                </Button>

                                <Button
                                  color={isPageAttached ? "success" : "light"}
                                  className="special_buttons mb-1 no-eff me-3"
                                  type="button"
                                  onClick={() => {
                                    setIsPageAttached(!isPageAttached)
                                  }}
                                >
                                  Attach
                                  <br /> Page
                                </Button>
                                <Button
                                  color="light"
                                  className="special_buttons border-none mb-1 no-eff me-3"
                                  type="button"
                                  style={{
                                    cursor: "default",
                                  }}
                                >
                                  <p className="mb-2"> Return Viable</p>
                                  <Switch
                                    uncheckedIcon={<OffSymbol />}
                                    checkedIcon={<OnSymbol />}
                                    className="w-100 ms-2 mb-sm-8"
                                    onColor="#2a9c72"
                                    onChange={v => {
                                      setIsReturnViable(v)
                                    }}
                                    checked={isReturnViable ?? false}
                                  />
                                </Button>
                              </div>
                            </Col>
                            <div className="mt-4 mx-2 d-flex">
                              {isDailyProduct ? (
                                <div className="d-flex me-4 flex-wrap flex-column align-items-start justify-content-center mb-4 timePickerWrapper">
                                  <div className="d-flex align-items-center justify-content-center ">
                                    <label className="m-0 me-2">
                                      Available Time From :{" "}
                                    </label>
                                    <DatePikerTime
                                      setState={setSelectDateTimeFrom}
                                      state={selectDateTimeFrom}
                                    />
                                  </div>
                                  <div>&nbsp;&nbsp;&nbsp;</div>
                                  <div className="d-flex align-items-center justify-content-center">
                                    <label className="m-0 me-2">
                                      Available Time To :{" "}
                                    </label>
                                    <DatePikerTime
                                      setState={setSelectDateTimeTo}
                                      state={selectDateTimeTo}
                                    />
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                              {isSpecialOffer ? (
                                <div className="me-4 d-flex  align-items-start jus">
                                  <div className="me-4">
                                    <AvField
                                      name="minOrderQuantity"
                                      label="Min Order Quantity"
                                      type="number"
                                      onChange={v => {
                                        setMinOrderQuantity(v.target.value)
                                      }}
                                      value={minOrderQuantity}
                                      min={0}
                                    />
                                  </div>
                                  <div className="">
                                    <AvField
                                      name="maxOrderQuantity"
                                      label="Max Order Quantity"
                                      type="number"
                                      onChange={v => {
                                        setMaxOrderQuantity(v.target.value)
                                      }}
                                      value={maxOrderQuantity}
                                      min={0}
                                    />
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                              {isPageAttached ? (
                                <div className="w-25">
                                  <p
                                    style={{
                                      fontWeight: 500,
                                      marginBottom: "0.5rem",
                                    }}
                                  >
                                    Pages
                                  </p>
                                  <FormGroup className="mb-3">
                                    <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                                      <Select
                                        onInputChange={handleProductEnters}
                                        value={pageSelect?.title}
                                        placeholder={pageSelect?.title}
                                        onChange={handlerPageFinalValue}
                                        options={pageOptions}
                                        classNamePrefix="select2-selection"
                                        isLoading={pageLoading}
                                      />
                                    </div>
                                  </FormGroup>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </>
                        )} */}
                        {/* <Col
                          className="col-6 d-flex flex-wrap "
                          style={{ flexDirection: "column" }}
                        >
                          {isPremium && (
                            <>
                              <Row className="dailyProductParent">
                                <Col className="parent_dateRange col-8">
                                  <div
                                    className={
                                      "align-items-center d-flex " +
                                      `${isDailyProduct ? "mb-4" : ""}`
                                    }
                                  >
                                    <Label check for="checkbox">
                                      {" "}
                                      Daily Product :
                                    </Label>
                                    <Switch
                                      uncheckedIcon={<Offsymbol />}
                                      checkedIcon={<OnSymbol />}
                                      className="me-1 mb-sm-8 mx-3"
                                      onColor="#626ed4"
                                      onChange={v => {
                                        setIsDailyProduct(v)
                                      }}
                                      checked={isDailyProduct ?? false}
                                    />
                                  </div>
                                  {isDailyProduct ? (
                                    <div className="d-flex flex-wrap align-items-center justify-content-start mb-4 timePickerWrapper">
                                      <div className="d-flex flex-column">
                                        <label>Available Time From : </label>
                                        <DatePikerTime
                                          setState={setSelectDateTimeFrom}
                                          state={selectDateTimeFrom}
                                        />
                                      </div>
                                      <div>&nbsp;&nbsp;&nbsp;</div>
                                      <div className="d-flex flex-column">
                                        <label>Available Time To : </label>
                                        <DatePikerTime
                                          setState={setSelectDateTimeTo}
                                          state={selectDateTimeTo}
                                        />
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </Col>
                              </Row>
                              <div className="mb-3 align-items-center d-flex mt-2">
                                <Label check for="checkbox">
                                  {" "}
                                  Special Offer :
                                </Label>
                                <Switch
                                  uncheckedIcon={<Offsymbol />}
                                  checkedIcon={<OnSymbol />}
                                  className="me-1 mb-sm-8 mx-3"
                                  onColor="#626ed4"
                                  onChange={v => {
                                    setIsSpecialOffer(v)
                                  }}
                                  checked={isSpecialOffer ?? false}
                                />
                              </div>
                              {isSpecialOffer ? (
                                <Row>
                                  <div className="col-4 mb-3 ">
                                    <AvField
                                      name="minOrderQuantity"
                                      label="Min Order Quantity"
                                      type="number"
                                      onChange={v => {
                                        setMinOrderQuantity(v.target.value)
                                      }}
                                      value={minOrderQuantity}
                                      min={0}
                                    />
                                  </div>
                                  <div className="col-4 mb-3 mx-3">
                                    <AvField
                                      name="maxOrderQuantity"
                                      label="Max Order Quantity"
                                      type="number"
                                      onChange={v => {
                                        setMaxOrderQuantity(v.target.value)
                                      }}
                                      value={maxOrderQuantity}
                                      min={0}
                                    />
                                  </div>
                                </Row>
                              ) : (
                                ""
                              )}
                            </>
                          )}
                          <div className="mb-3 align-items-center d-flex">
                            <Label check for="checkbox">
                              {" "}
                              Return Viable :
                            </Label>
                            <Switch
                              uncheckedIcon={<Offsymbol />}
                              checkedIcon={<OnSymbol />}
                              className="me-1 mb-sm-8 mx-3"
                              onColor="#626ed4"
                              onChange={v => {
                                setisReturnViable(v)
                              }}
                              checked={isReturnViable ?? false}
                            />
                          </div>
                        </Col> */}

                        <div className="mt-5">
                          {/* <Label className="mb-1">Images</Label> */}
                          <UseImageUploader
                            imageSize={4}
                            imagesPreview={imagesPreview}
                            setImagesPreview={setImagesPreview}
                            uploadUrl={"/products"}
                            isMultiple
                          />
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row xl="12">
                <Col className="col-12">
                  <Card>
                    <CardBody>
                      {isMult && (
                        <LanguageTab
                          setActiveTab={setActiveTab}
                          activeTab={activeTab}
                        />
                      )}
                      <TabContent activeTab={activeTab} className="">
                        <TabPane tabId="1">
                          <Row>
                            <Col className="col-12 d-flex flex-wrap">
                              <Col className="col-12 d-flex flex-wrap">
                                <div className="col-12 mb-3">
                                  <AvField
                                    name="primaryLang.name"
                                    label="Name *"
                                    type="text"
                                    onKeyUp={v => validateName(v)}
                                    errorMessage="Invalid name"
                                    validate={{
                                      required: { value: true },
                                    }}
                                    value={primaryLangName}
                                    onChange={e => {
                                      setProductName(e.target.value)
                                      dispatch(checkProductName(e.target.value))
                                    }}
                                  />
                                  {productName && (
                                    <>
                                      {productNameCheck?.response === false && (
                                        <h6
                                          className="pt-1"
                                          style={{ color: "red" }}
                                        >
                                          Not Available{" "}
                                        </h6>
                                      )}
                                    </>
                                  )}
                                </div>

                                {/* //Description */}
                                <div className="col-12 mb-3">
                                  <Label
                                    htmlFor="lbltype"
                                    className="form-label mt-2"
                                  >
                                    Description
                                  </Label>
                                  <Col sm="12" className="mt-2">
                                    {primaryLangDescription &&
                                    primaryLangDescription != undefined ? (
                                      <FormEditors
                                        content={primaryLangDescription}
                                        setContent={setPrimaryLangDescription}
                                      />
                                    ) : (
                                      <>
                                        <FormEditors
                                          content={primaryLangDescription}
                                          setContent={setPrimaryLangDescription}
                                        />
                                      </>
                                    )}
                                  </Col>
                                </div>

                                {/* <div className="col-12 mb-3">
                                  <AvField
                                    name="primaryLang.description"
                                    label="Description"
                                    type="textarea"
                                    errorMessage="Invalid description"
                                    rows="7"
                                    onChange={v => {
                                      setPrimaryLangDescription(v.target.value)
                                    }}
                                  />
                                </div> */}

                                {/* Highlights */}
                                <div className="col-12 mb-3">
                                  <Label
                                    htmlFor="lbltype"
                                    className="form-label"
                                  >
                                    Highlights
                                  </Label>
                                  <Col sm="12" className="mt-4">
                                    <FormEditors
                                      content={primaryLangHighlights}
                                      setContent={setPrimaryLangHighlights}
                                    />
                                  </Col>
                                </div>
                              </Col>
                            </Col>
                          </Row>
                        </TabPane>

                        <TabPane tabId="2">
                          <Row>
                            <Col className="col-12 d-flex flex-wrap">
                              <Col className="col-12 d-flex flex-wrap">
                                <div className="col-12 mb-3">
                                  <div className="d-flex justify-content-between mb-2">
                                    <CardSubtitle>Name</CardSubtitle>
                                    <CardSubtitle>اسم</CardSubtitle>
                                  </div>
                                  <AvField
                                    name="secondaryLang.name"
                                    label=""
                                    type="text"
                                    onChange={v => {
                                      setSecondaryLangName(v.target.value)
                                    }}
                                  />
                                </div>
                                <div className="col-12 mb-3">
                                  <div className="d-flex justify-content-between mb-2">
                                    <CardSubtitle>Description</CardSubtitle>
                                    <CardSubtitle>وصف</CardSubtitle>
                                  </div>
                                  <AvField
                                    name="secondaryLang.description"
                                    label=""
                                    type="textarea"
                                    onChange={v => {
                                      setSecondaryLangDescription(
                                        v.target.value
                                      )
                                    }}
                                    rows="7"
                                  />
                                </div>

                                <div className="col-12 mb-3">
                                  <div className="d-flex justify-content-between mb-2">
                                    <CardSubtitle>Highlights</CardSubtitle>
                                    <CardSubtitle>يسلط الضوء</CardSubtitle>
                                  </div>
                                  <Col sm="12" className="mt-4">
                                    <FormEditors
                                      content={secondaryLangHighlights}
                                      setContent={setSecondaryLangHighlights}
                                    />
                                  </Col>
                                </div>
                              </Col>
                            </Col>
                          </Row>
                        </TabPane>
                      </TabContent>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              {/* classifications */}
              <Row>
                <Col xl="12">
                  <Card>
                    <CardBody>
                      {/* <CardTitle
                        className="h4 mb-4"text_deals_green
                        
                      >
                        Classifications
                      </CardTitle> */}
                      <Row>
                        <Col xl="12">
                          <Row>
                            {/* <div className="mb-3">
                              <label className="control-label">
                                growebs
                              </label>
                              <Select
                                value={selectedMulti}
                                isMulti={true}
                                onChange={e => {
                                  handleMulti(e)
                                }}
                                options={optionGroup}
                                classNamePrefix="select2-selection"
                              />
                            </div> */}

                            <div className="col-12 mb-3">
                              <Label htmlFor="lbltype" className="form-label">
                                Keywords
                              </Label>
                              <ReactTags
                                tags={tags}
                                placeholder="Type TEXT and enter"
                                // suggestions={suggestions}
                                delimiters={delimiters}
                                handleDelete={handleDeleteTag}
                                handleAddition={handleAddition}
                                handleDrag={handleDrag}
                                handleTagClick={handleTagClick}
                                // inputFieldPosition="inline"
                                autocomplete
                                allowUnique
                                inline
                              />
                            </div>
                            {/* <Col className="col-12 d-flex flex-wrap">
                              <div className="col-3 mb-3 mx-3">
                                <AvField
                                  type="select"
                                  name="category"
                                  label="Category"
                                  onChange={v => {
                                    setCategory(v.target.value)
                                  }}
                                  // helpMessage="Select a category"
                                  // errorMessage="Select a category"
                                  // validate={{
                                  //   required: { value: true },
                                  // }}
                                >
                                  <option>Select a category</option>
                                  {categoryList?.map((item, key) => (
                                    <option key={key} value={item._id}>
                                      {item.primaryLang?.name}
                                    </option>
                                  ))}
                                </AvField>
                              </div>
                            </Col> */}
                          </Row>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              {/* varients */}
              <Row>
                <Col xl="12">
                  <Card>
                    <CardBody>
                      <Row>
                        <Row className="mb-2">
                          {isPremium && (
                            <div className="align-items-center d-flex mb-3">
                              <Label check for="checkbox">
                                {" "}
                                Has Varients ?
                              </Label>
                              <Switch
                                uncheckedIcon={<OffSymbol />}
                                checkedIcon={<OnSymbol />}
                                className="me-1 mb-sm-8 mx-3"
                                onColor="#626ed4"
                                onChange={v => {
                                  setHasVarients(v)
                                }}
                                checked={hasVarients}
                              />
                            </div>
                          )}
                          {hasVarients && hasVarients ? (
                            <Col className="col-12 d-flex flex-wrap">
                              <Col md="12" className=" mt-2 mb-2">
                                <CardTitle className="h4 mb-4 text_deals_green d-flex align-items-center">
                                  Product varients
                                  <button
                                    className="mx-2 btn bg-light d-flex align-items-center justify-content-center"
                                    onClick={e => {
                                      e.preventDefault()
                                      setVarients(currentVarient => [
                                        ...currentVarient,
                                        {
                                          value: "",
                                          unit: "kg",
                                          cost: "",
                                          price: "",
                                          offerPrice: "",
                                          primePrice: "",
                                          stock: "",
                                        },
                                      ])
                                    }}
                                  >
                                    <i
                                      className="bx bx-plus"
                                      style={{ fontSize: "20px" }}
                                    ></i>
                                  </button>
                                </CardTitle>
                                <div className="table-responsive">
                                  <AvForm>
                                    <Table
                                      responsive
                                      striped
                                      className="table-nowrap mb-0"
                                    >
                                      <thead>
                                        <tr>
                                          <th
                                            colSpan={2}
                                            className="bg-gray text-center"
                                          >
                                            Product
                                          </th>
                                          <th
                                            colSpan={4}
                                            className="text-center bg-green"
                                          >
                                            Prices ()
                                          </th>
                                          <th className="bg-yellow text-center">
                                            Stock (Count)
                                          </th>
                                          <th className="bg-lightYellow text-center">
                                            Remove
                                          </th>
                                        </tr>
                                        <tr>
                                          <th className="bg-lightGray text-center">
                                            Value
                                          </th>
                                          <th className="bg-lightGray text-center">
                                            Unit
                                          </th>

                                          <th className="bg-lightGreen">
                                            Cost
                                          </th>
                                          <th className="bg-lightGreen">
                                            Price
                                          </th>
                                          <th className="bg-lightGreen">
                                            Offer Price
                                          </th>
                                          {/* <th className="bg-lightGreen">
                                            Prime Price
                                          </th> */}

                                          <th className="bg-lightYellow text-center">
                                            Stock
                                          </th>
                                        </tr>
                                      </thead>
                                      {varients?.map((s, key) => (
                                        <tbody
                                          key={key}
                                          className="editor_input"
                                        >
                                          <td
                                            className="bg-lightGray text-center"
                                            style={{ width: "100px" }}
                                          >
                                            <AvField
                                              name="varient.value"
                                              type="text"
                                              value={s.value}
                                              onChange={e => {
                                                const value = e.target.value
                                                setVarients(currentVarient =>
                                                  currentVarient.map(x =>
                                                    x === s
                                                      ? {
                                                          ...x,
                                                          value,
                                                        }
                                                      : x
                                                  )
                                                )
                                              }}
                                            />
                                          </td>
                                          <td
                                            className="bg-lightGray text-center vertical-middle"
                                            style={{ width: "100px" }}
                                          >
                                            <div className="d-flex align-items-center unit_select">
                                              <AvField
                                                name="varient.unit"
                                                type="select"
                                                value={s.unit}
                                                onChange={e => {
                                                  const unit = e.target.value
                                                  setVarients(currentVarient =>
                                                    currentVarient.map(x =>
                                                      x === s
                                                        ? {
                                                            ...x,
                                                            unit,
                                                          }
                                                        : x
                                                    )
                                                  )
                                                }}
                                              >
                                                {UnitsList?.map((item, key) => (
                                                  <option
                                                    key={key}
                                                    value={item}
                                                  >
                                                    {item}
                                                  </option>
                                                ))}
                                              </AvField>
                                              <i className="fas fa-angle-down mx-1"></i>
                                            </div>
                                          </td>
                                          <td className="bg-lightGreen">
                                            <AvField
                                              name="varient.cost"
                                              type="number"
                                              value={s.cost}
                                              onChange={e => {
                                                const cost = e.target.value
                                                setVarients(currentVarient =>
                                                  currentVarient.map(x =>
                                                    x === s
                                                      ? {
                                                          ...x,
                                                          cost,
                                                        }
                                                      : x
                                                  )
                                                )
                                              }}
                                            />
                                          </td>
                                          <td className="cursor-pointer bg-lightGreen ">
                                            <AvField
                                              name="varient.price"
                                              type="number"
                                              value={s.price}
                                              onChange={e => {
                                                const price = e.target.value
                                                setVarients(currentVarient =>
                                                  currentVarient.map(x =>
                                                    x === s
                                                      ? {
                                                          ...x,
                                                          price,
                                                        }
                                                      : x
                                                  )
                                                )
                                              }}
                                            />
                                          </td>
                                          <td className="bg-lightGreen">
                                            <AvField
                                              name="varient.offerPrice"
                                              type="number"
                                              value={s.offerPrice}
                                              onChange={e => {
                                                const offerPrice =
                                                  e.target.value
                                                setVarients(currentVarient =>
                                                  currentVarient.map(x =>
                                                    x === s
                                                      ? {
                                                          ...x,
                                                          offerPrice,
                                                        }
                                                      : x
                                                  )
                                                )
                                              }}
                                            />
                                          </td>{" "}
                                          {/* <td className="bg-lightGreen">
                                            <AvField
                                              name="varient.primePrice"
                                              type="number"
                                              value={s.primePrice}
                                              onChange={e => {
                                                const primePrice =
                                                  e.target.value
                                                setVarients(currentVarient =>
                                                  currentVarient.map(x =>
                                                    x === s
                                                      ? {
                                                          ...x,
                                                          primePrice,
                                                        }
                                                      : x
                                                  )
                                                )
                                              }}
                                            />
                                          </td>{" "} */}
                                          <td className="bg-lightYellow text-center">
                                            <div className="d-flex align-items-center justify-content-center">
                                              <AvField
                                                name="varient.stock"
                                                type="number"
                                                value={s.stock}
                                                onChange={e => {
                                                  const stock = e.target.value
                                                  setVarients(currentVarient =>
                                                    currentVarient.map(x =>
                                                      x === s
                                                        ? {
                                                            ...x,
                                                            stock,
                                                          }
                                                        : x
                                                    )
                                                  )
                                                }}
                                              />
                                            </div>
                                          </td>
                                          <td>
                                            <button
                                              className="mx-2 btn"
                                              onClick={e => {
                                                e.preventDefault()
                                                setVarients(currentVarient =>
                                                  currentVarient.filter(
                                                    x => x !== s
                                                  )
                                                )
                                              }}
                                            >
                                              <i
                                                className="bx bx-trash"
                                                style={{
                                                  fontSize: "20px",
                                                }}
                                              ></i>
                                            </button>
                                          </td>
                                        </tbody>
                                      ))}
                                    </Table>
                                  </AvForm>
                                </div>
                              </Col>
                            </Col>
                          ) : (
                            <Row xl="12">
                              <Col xl="12">
                                <Row>
                                  <Col
                                    className="col-12 d-flex flex-wrap justify-content-space-evenly"
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div className="col-2 mb-3">
                                      <AvField
                                        name="cost"
                                        label="Cost"
                                        type="number"
                                      />
                                    </div>
                                    <div className="col-2 mb-3">
                                      <AvField
                                        name="price"
                                        label="Price"
                                        type="number"
                                      />
                                    </div>
                                    <div className="col-2 mb-3">
                                      <AvField
                                        name="offerPrice"
                                        label="Offer Price"
                                        type="number"
                                      />
                                    </div>
                                    {/* <div className="col-2 mb-3">
                                      <AvField
                                        name="primePrice"
                                        label="Prime Price"
                                        type="number"
                                      />
                                    </div> */}
                                    <div className="col-2 mb-3">
                                      <AvField
                                        name="stock"
                                        label="Stock"
                                        type="number"
                                      />
                                    </div>
                                  </Col>
                                  {/* <div className="col-2 mb-3">
                                    <AvField
                                      name="taxPercentage"
                                      label="Tax Percentage"
                                      type="number"
                                    />
                                  </div> */}

                                  {/* <Col className="col-12 d-flex flex-wrap">
                                        <div className="col-6 mb-3 mx-3">
                                          <Label check for="checkbox">
                                            Is Tax included :
                                          </Label>
                                          <Switch
                                            uncheckedIcon={<Offsymbol />}
                                            checkedIcon={<OnSymbol />}
                                            className="me-1 mb-sm-8 mb-2 mx-3"
                                            onColor="#626ed4"
                                            onChange={v => {
                                              setIsTaxIncluded(v)
                                            }}
                                            checked={isTaxIncluded}
                                          />
                                        </div>
                                      </Col>
        
                                      {isTaxIncluded && (
                                        <Col className="col-12 d-flex flex-wrap">
                                          <div className="col-4 mb-3 mx-3">
                                            <AvField
                                              type="select"
                                              name="taxCategory"
                                              label="Tax Category"
                                              onChange={v => {
                                                taxPercentageChange(v.target.value)
                                              }}
                                            >
                                              <option>Select a Tax category</option>
                                              {taxCategories?.map((item, key) => (
                                                <option key={key} value={item}>
                                                  {item}
                                                </option>
                                              ))}
                                            </AvField>
                                          </div>
                                          <div className="col-4 mb-3 mx-3">
                                            <AvField
                                              name="taxValue"
                                              label="Tax Value"
                                              type="number"
                                              value={taxValue}
                                              // onChange = {(v)=> {taxValueChange(v.target.value)}}
                                              readOnly
                                            />
                                          </div>
                                        </Col>
                                      )}
        
                                      <Col className="col-12 d-flex flex-wrap">
                                        <div className="col-6 mb-3 mx-3">
                                          <Label check for="checkbox">
                                            {" "}
                                            Is Discounted :
                                          </Label>
                                          <Switch
                                            uncheckedIcon={<Offsymbol />}
                                            checkedIcon={<OnSymbol />}
                                            className="me-1 mb-sm-8 mb-2 mx-3"
                                            onColor="#626ed4"
                                            onChange={v => {
                                              setIsDiscounted(v)
                                            }}
                                            checked={isDiscounted}
                                          />
                                        </div>
                                      </Col>
        
                                      {isDiscounted && (
                                        <Col className="col-12 d-flex flex-wrap">
                                          <div className="col-4 mb-3 mx-3">
                                            <AvField
                                              name="discountPercentage"
                                              label="Discount %"
                                              type="number"
                                              value={discountPercentage}
                                              onChange={v => {
                                                discountPercentageChange(v.target.value)
                                              }}
                                            />
                                          </div>
                                          <div className="col-4 mb-3 mx-3">
                                            <AvField
                                              name="discountValue"
                                              label="Discount Value"
                                              type="number"
                                              value={discountValue}
                                              onChange={v => {
                                                discountValueChange(v.target.value)
                                              }}
                                            />
                                          </div>
                                        </Col>
                                      )} */}

                                  {/* <Col className="col-12 d-flex flex-wrap">
                                        <div className="col-4 mb-3 mx-3">
                                          <AvField
                                            name="sellingPrice"
                                            label="Selling Price"
                                            type="number"
                                            value={sellingPrice}
                                            readOnly
                                          />
                                        </div>
                                        <div className="col-4 mb-3 mx-3">
                                          <AvField
                                            name="profit"
                                            label="Profit"
                                            type="number"
                                            value={profit}
                                            // onChange = {(v)=> {costChange(v.target.value)}}
                                            readOnly
                                          />
                                        </div>
                                      </Col> */}
                                </Row>
                              </Col>
                              {/* <Col xl="6">
                                    <Row>
                                      <Col className="col-12 d-flex flex-wrap ">
                                        <div className="col-4 mb-3 mx-3">
                                          <AvField
                                            name="stockValue"
                                            label="Stock"
                                            type="number"
                                            onChange={v => {
                                              setStockValue(v.target.value)
                                            }}
                                          />
                                        </div>
                                        <div className="col-4 mb-3 mx-3">
                                          <AvField
                                            name="minStock"
                                            label="Min Stock"
                                            type="number"
                                            onChange={v => {
                                              setMinStock(v.target.value)
                                            }}
                                          />
                                        </div>
                                      </Col>
                                      <Col className="col-12 d-flex flex-wrap">
                                        <div className="col-4 mb-3 mx-3">
                                          <AvField
                                            name="reorderQuantity"
                                            label="Reorder Quantity"
                                            type="number"
                                            onChange={v => {
                                              setReorderQuantity(v.target.value)
                                            }}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                  </Col> */}
                            </Row>
                          )}
                        </Row>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              {/* packageData */}
              {/* <Row>
                <Col xl="12">
                  <Card>
                    <CardBody>
                      <CardTitle className="h4 mb-4" text_deals_green>
                        Package Details
                      </CardTitle>
                      <Row>
                        <Col className="col-2 mb-3 me-3">
                          <AvField
                            name="packageData.length"
                            label="Length"
                            type="number"
                          />
                        </Col>
                        <Col className="col-2 mb-3 me-3">
                          <AvField
                            name="packageData.breadth"
                            label="Breadth"
                            type="number"
                          />
                        </Col>
                        <Col className="col-2 mb-3 me-3">
                          <AvField
                            name="packageData.height"
                            label="Height"
                            type="number"
                          />
                        </Col>
                        <Col className="col-2 mb-3 me-3">
                          <AvField
                            name="packageData.weight"
                            label="weight"
                            type="number"
                          />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col> */}
              {/* fittingData */}
              {/* <Col xl="6">
                  <Card style={{ minHeight: "200px" }}>
                    <CardBody>
                      <CardTitle
                        className="h4 mb-4"text_deals_green
                        
                      >
                        Fitting Details
                      </CardTitle>
                      <Row>
                        <div className="col-6 mb-3 align-items-center d-flex">
                          <Label check for="checkbox">
                            Is fitting needed :
                          </Label>
                          <Switch
                            uncheckedIcon={<Offsymbol />}
                            checkedIcon={<OnSymbol />}
                            className="me-1 mb-sm-8 mx-3"
                            onColor="#626ed4"
                            onChange={v => {
                              setIsFittingNeeded(v)
                            }}
                            checked={isFittingNeeded}
                          />
                        </div>

                        <div className="col-4 mb-3 align-items-center d-flex ">
                          <Label check for="checkbox">
                            {" "}
                            Is fitted :
                          </Label>
                          <Switch
                            uncheckedIcon={<Offsymbol />}
                            checkedIcon={<OnSymbol />}
                            className="me-1 mb-sm-8 mx-3"
                            onColor="#626ed4"
                            onChange={v => {
                              setIsFitted(v)
                            }}
                            checked={isFitted}
                          />
                        </div>

                        <div className="col-6 mb-3">
                          <AvField
                            name="fittingCharge"
                            placeholder="Fitting charge"
                            type="number"
                            onChange={v => {
                              setFittingCharge(v.target.value)
                            }}
                          />
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col> */}
              {/* </Row> */}

              {/* shippingData */}
              {/* <Row>
                <Col xl="8">
                  <Card>
                    <CardBody>
                      <CardTitle
                        className="h4 mb-4"text_deals_green
                        
                      >
                        Shipping Details
                      </CardTitle>
                      <Row>
                        <Col xl="12">
                          <Row>
                            <Col className="col-12 d-flex flex-wrap">
                              <div className="col-12 mb-3 align-items-center d-flex">
                                <Label check for="checkbox">
                                  {" "}
                                  Is ReturnViable :
                                </Label>
                                <Switch
                                  uncheckedIcon={<Offsymbol />}
                                  checkedIcon={<OnSymbol />}
                                  className="me-1 mb-sm-8 mx-3"
                                  onColor="#626ed4"
                                  onChange={v => {
                                    setisReturnViable(v)
                                  }}
                                  checked={isReturnViable}
                                />
                              </div>
                              <div className="col-6 mb-3 align-items-center d-flex">
                                <Label check for="checkbox">
                                  {" "}
                                  Is extra charge needed :
                                </Label>
                                <Switch
                                  uncheckedIcon={<Offsymbol />}
                                  checkedIcon={<OnSymbol />}
                                  className="me-1 mb-sm-8 mx-3"
                                  onColor="#626ed4"
                                  onChange={v => {
                                    setIsExtraChargeNeeded(v)
                                  }}
                                  checked={isExtraChargeNeeded}
                                />
                              </div>
                              <div className="col-4 mb-3">
                                <AvField
                                  name="extraCharge"
                                  placeholder="Extra charge"
                                  type="number"
                                  onChange={v => {
                                    setExtraCharge(v.target.value)
                                  }}
                                />
                              </div>

                              <div className="col-6 mb-3 align-items-center d-flex">
                                <Label check for="checkbox">
                                  {" "}
                                  isAvailableEveryWhere :
                                </Label>
                                <Switch
                                  uncheckedIcon={<Offsymbol />}
                                  checkedIcon={<OnSymbol />}
                                  className="me-1 mb-sm-8 mx-3"
                                  onColor="#626ed4"
                                  onChange={v => {
                                    setIsAvailableEveryWhere(v)
                                  }}
                                  checked={isAvailableEveryWhere}
                                />
                              </div>

                              {!isAvailableEveryWhere ? (
                                <Col sm="12" md="12" className="mb-3">
                                  <Label for="basicpill-cstno-input7">
                                    Area(multiple)
                                  </Label>
                                  <div className="d-flex  flex-wrap">
                                    {map(area, (item, key) => (
                                      <div key={key} className="m-1">
                                        <input
                                          type="radio"
                                          className="btn-check"
                                          name={item?.name}
                                          checked={areaList?.includes(
                                            item?._id
                                          )}
                                          id={item?._id}
                                          autoComplete="off"
                                          value={item?._id}
                                          onClick={e =>
                                            addOrRemoveItem(
                                              areaList,
                                              setAreaList,
                                              e.target.value
                                            )
                                          }
                                        />
                                        <label
                                          className="btn btn-outline-info"
                                          htmlFor={item?._id}
                                        >
                                          {item?.name}
                                        </label>
                                      </div>
                                    ))}
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                              <div className="col-4 mb-3 align-items-center justify-content-between d-flex">
                                <Label check for="checkbox">
                                  Area :
                                </Label>
                                <AvField
                                  type="select"
                                  name="area"
                                  onChange={v => {
                                    setAreaList(v.target.value)
                                  }}
                                >
                                  <option>Select a Area</option>
                                  {area?.map((item, key) => (
                                    <option key={key} value={item._id}>
                                      {item.name}
                                    </option>
                                  ))}
                                </AvField>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row> */}

              {/* Save */}
              <Row xl="12">
                <Col xl="12">
                  <Card>
                    <CardBody>
                      <CardTitle className="h4 mb-4 text_deals_green"></CardTitle>
                      <Row>
                        <Col>
                          <div className="text-end">
                            <button
                              // disabled={!finalStoreId}
                              type="submit"
                              className="col-12 btn btn-success save-customer"
                            >
                              {loading && (
                                <>
                                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                </>
                              )}
                              Create Product
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </AvForm>
          </div>
        </Container>
      </div>
    </>
  )
}

export default CreateProduct

export const OffSymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      No
    </div>
  )
}

export const OnSymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      Yes
    </div>
  )
}

export const UnitsList = ["kg", "g", "L", "mL", "pcs", "nos", "item"]
