import {
  GET_GROWEBS,
  GET_GROWEBS_SUCCESS,
  GET_GROWEBS_FAIL,
  GET_GROWEB_DETAILS,
  GET_GROWEB_DETAILS_SUCCESS,
  GET_GROWEB_DETAILS_FAIL,
  CREATE_GROWEB,
  CREATE_GROWEB_SUCCESS,
  CREATE_GROWEB_FAIL,
  UPDATE_GROWEB,
  UPDATE_GROWEB_SUCCESS,
  UPDATE_GROWEB_FAIL,
  DELETE_GROWEB,
  DELETE_GROWEB_SUCCESS,
  DELETE_GROWEB_FAIL,
  CLEAR_GROWEB_DETAILS,
  GET_GROWEB_DASHBOARD_DATA,
  GET_GROWEB_DASHBOARD_DATA_FAIL,
  GET_GROWEB_DASHBOARD_DATA_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  growebs: {
    growebs: [],
    total: "",
    page: "",
  },
  growebDetails: {},
  growebDashboardData: {},
  error: {},
  loading: false,
}

const GroWeb = (state = INIT_STATE, action) => {
  switch (action.type) {
    // GroWeb - This line cannot be edited or removed
    case GET_GROWEBS:
    case GET_GROWEB_DETAILS:
    case CREATE_GROWEB:
    case UPDATE_GROWEB:
    case DELETE_GROWEB:
      return {
        ...state,
        loading: true,
      }

    case DELETE_GROWEB_FAIL:
    case GET_GROWEBS_FAIL:
    case GET_GROWEB_DETAILS_FAIL:
    case UPDATE_GROWEB_FAIL:
    case CREATE_GROWEB_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_GROWEBS_SUCCESS:
      return {
        ...state,
        growebs: action.payload,
        error: {},
        loading: false,
      }

    case GET_GROWEB_DETAILS_SUCCESS:
      return {
        ...state,
        growebDetails: action.payload,
        error: {},
        loading: false,
      }

    case CLEAR_GROWEB_DETAILS:
      return {
        ...state,
        growebDetails: {},
        error: {},
        loading: false,
      }

    case CREATE_GROWEB_SUCCESS:
      return {
        ...state,
        growebs: {
          ...state.growebs,
          growebs: [...state.growebs.growebs, action.payload],
          total: state.growebs.total + 1,
        },
        growebDetails: action.payload,
        error: {},
        loading: false,
      }

    case UPDATE_GROWEB_SUCCESS:
      return {
        ...state,
        growebs: {
          ...state.growebs,
          growebs: state.growebs.growebs.map(groweb =>
            groweb._id === action.payload._id
              ? { ...groweb, ...action.payload }
              : groweb
          ),
        },
        growebDetails: action.payload,
        loading: false,
        error: {},
      }

    case DELETE_GROWEB_SUCCESS:
      return {
        ...state,
        growebs: {
          ...state.growebs,
          growebs: state.growebs.growebs.filter(
            groweb => groweb._id !== action.payload._id
          ),
        },
        error: {},
        loading: false,
      }

    //Dashboard Data
    case GET_GROWEB_DASHBOARD_DATA:
      return {
        ...state,
        loading: true,
      }

    case GET_GROWEB_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        growebDashboardData: action.payload,
        loading: false,
      }

    case GET_GROWEB_DASHBOARD_DATA_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    default:
      return state
  }
}

export default GroWeb
