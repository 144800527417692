import {
  GET_CLIENTS,
  GET_CLIENTS_SUCCESS,
  GET_CLIENTS_FAIL,
  GET_CLIENT_DETAILS,
  GET_CLIENT_DETAILS_SUCCESS,
  GET_CLIENT_DETAILS_FAIL,
  CREATE_CLIENT,
  CREATE_CLIENT_SUCCESS,
  CREATE_CLIENT_FAIL,
  UPDATE_CLIENT,
  UPDATE_CLIENT_SUCCESS,
  UPDATE_CLIENT_FAIL,
  DELETE_CLIENT,
  DELETE_CLIENT_SUCCESS,
  DELETE_CLIENT_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  clients: {
    clients: [],
    total: "",
    page: "",
  },
  clientDetails: {},
  error: {},
  loading: false,
}

const Client = (state = INIT_STATE, action) => {
  switch (action.type) {
    // Client - This line cannot be edited or removed
    case GET_CLIENTS:
    case GET_CLIENT_DETAILS:
    case CREATE_CLIENT:
    case UPDATE_CLIENT:
    case DELETE_CLIENT:
      return {
        ...state,
        loading: true,
      }

    case DELETE_CLIENT_FAIL:
    case GET_CLIENTS_FAIL:
    case GET_CLIENT_DETAILS_FAIL:
    case UPDATE_CLIENT_FAIL:
    case CREATE_CLIENT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_CLIENTS_SUCCESS:
      return {
        ...state,
        clients: action.payload,
        error: {},
        loading: false,
      }

    case GET_CLIENT_DETAILS_SUCCESS:
      return {
        ...state,
        clientDetails: action.payload,
        error: {},
        loading: false,
      }

    case CREATE_CLIENT_SUCCESS:
      return {
        ...state,
        clients: {
          ...state.clients,
          clients: [action.payload, ...state.clients.clients],
          total: state.clients.total + 1,
        },
        clientDetails: action.payload,
        error: {},
        loading: false,
      }

    case UPDATE_CLIENT_SUCCESS:
      return {
        ...state,
        clients: {
          ...state.clients,
          clients: state.clients.clients.map(client =>
            client._id === action.payload._id
              ? { ...client, ...action.payload }
              : client
          ),
        },
        clientDetails: action.payload,
        loading: false,
        error: {},
      }

    case DELETE_CLIENT_SUCCESS:
      return {
        ...state,
        clients: {
          ...state.clients,
          clients: state.clients.clients.filter(
            client => client._id !== action.payload._id
          ),
        },
        error: {},
        loading: false,
      }
    default:
      return state
  }
}

export default Client
