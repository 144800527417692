import React, { Fragment, useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Spinner,
  Badge,
  FormGroup,
  UncontrolledTooltip,
} from "reactstrap"
import { debounce, isEmpty } from "lodash"

import { Link, useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import Select from "react-select"
import PropTypes from "prop-types"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"

import { map, range } from "lodash"
import { defaultImg } from "assets/images"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { useCopyText } from "hooks/useCopyText"

//actions
import {
  getCategories,
  addNewCategory as createCategory,
  deleteCategory,
  getGroWebs,
  getClients,
  deleteGrowPage,
  deleteGroWeb,
} from "store/actions"

import "../../assets/scss/datatables.scss"

import MyPagination from "components/Common/MyPagination"
import DeleteModal from "components/Common/DeleteModal"
import moment from "moment"

const GroWebs = ({ clientDetail }) => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [searchText, setSearchText] = useState("")
  const [clientSelect, setClientSelect] = useState({
    title: "Select Client",
    id: "",
  })
  const [clientSearch, setClientSearch] = useState()

  const { loading, GroWeb, Clients } = useSelector(state => ({
    GroWeb: state.GroWeb.growebs,
    loading: state.GroWeb.loading,
    Clients: state.Client.clients,
  }))
  useEffect(() => {
    dispatch(
      getGroWebs(page, "active", "", clientSelect.id || clientDetail?._id, "")
    )
  }, [dispatch, page, clientSelect.id, clientDetail?._id])

  const totalPages = Math.ceil(GroWeb?.total / 10)
  const pages = range(1, totalPages + 1)

  const [isOpen, setIsOpen] = useState({ stauts: false, id: "" })

  const handleDelete = id => {
    setIsOpen({ stauts: true, id: id })
  }

  const handleDeleteEvent = () => {
    setIsOpen({ ...isOpen, stauts: false })
    dispatch(deleteGroWeb(isOpen?.id))
  }

  const debounceClientSearch = debounce(value => setClientSearch(value), 600)
  //client dropdown
  const handleClientEnters = textEntered => {
    debounceClientSearch(textEntered)
  }

  function handlerClientFinalValue(event) {
    const client = {
      ...clientSelect,
      title: event.value !== "loading" && event.label,
      id: event.value,
    }
    setClientSelect(client)
  }
  useEffect(() => {
    dispatch(getClients(1, "", 10, clientSearch))
  }, [dispatch])

  const clientOptions = [
    {
      label: "All Clients",
      value: "",
    },
    {
      options: (Clients?.clients?.length >= 1 ? Clients?.clients : [])?.map(
        (results, index) => ({
          key: index,
          label: results.name,
          value: results._id,
        })
      ),
    },
    //   label: "All Clients",
    //   value: "",
    // },
    // Clients?.clients?.length >= 1 ? Clients?.clients : [])?.map(
    //   (result, index) => ({
    //     key: index,
    //     label: result?.name,
    //     value: result?._id,
    //   })
  ]
  const { copyText } = useCopyText()

  const handleCopy = text => {
    copyText(text)
  }
  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "client",
      text: "Client Name",
      sort: true,
    },
    {
      dataField: "company",
      text: "Company Name",
      sort: true,
    },
    // {
    //   dataField: "api",
    //   text: "Api Key",
    //   sort: true,
    // },
    {
      dataField: "createdAt",
      text: "Created At",
      sort: true,
    },
    {
      dataField: "imageData",
      text: "Logo",
      sort: true,
    },
    {
      dataField: "action",
      text: "Action",
    },
  ]

  const tableData = map(GroWeb?.growebs, (item, index) => ({
    ...item,
    createdAt: moment(item.createdAt).format("DD/MM/YYYY - h:mm a"),
    imageData: (
      <div>
        <img
          src={
            item?.setup?.logo && item?.setup?.logo[0]
              ? item?.setup?.logo[0]?.url
              : defaultImg
          }
          width="50px"
        />
      </div>
    ),

    nameData: (
      <>
        {item?.primaryLang?.name}

        {item?.isMain ? (
          <Badge className="me-1 badge-soft-primary ms-1"> {"main"} </Badge>
        ) : item?.mainCategory ? (
          <Badge className="me-1 badge-soft-success ms-1"> {"sub"} </Badge>
        ) : (
          <Badge className="me-1 badge-soft-info ms-1"> {"base"} </Badge>
        )}
      </>
    ),
    apiKey: (
      <>
        <h6 className="mb-1">
          {item?.apiKey}{" "}
          <i
            onClick={() => handleCopy(item?.apiKey)}
            className="bx bx-copy mx-2 "
            style={{ cursor: "copy" }}
            title="Copy url link"
          ></i>
        </h6>
      </>
    ),
    action: (
      <div>
        <>
          <i
            onClick={() => handleCopy(item?.apiKey)}
            className="bx bx-copy me-3 "
            style={{ cursor: "copy", fontSize: "1.2rem" }}
            title="Copy Api key"
          ></i>
        </>
        <Link
          to={{ pathname: `/groWeb/update/${item?._id}`, active: "6" }}
          id={`edit${index}`}
          className="btn-md"
        >
          <i className="bx bx-edit me-3 " style={{ fontSize: "1.3rem" }} />
        </Link>
        <Link
          to={{ pathname: `/groWeb/update/${item?._id}` }}
          id={`view${index}`}
          className="btn-md me-3"
          // onClick={() => setClientModal({ status: true, data: item })}
        >
          <i
            className="mdi mdi-eye"
            style={{ color: "green", fontSize: "20px" }}
          />
        </Link>
        <Link
          id={`delete${index}`}
          to={"#"}
          className="btn mb-1"
          onClick={() => {
            handleDelete(item._id)
          }}
        >
          <i
            className="bx bx-trash me-0"
            style={{ color: "red", fontSize: "1.3rem" }}
          />
        </Link>
        <UncontrolledTooltip placement="bottom" target={`edit${index}`}>
          Edit
        </UncontrolledTooltip>
        <UncontrolledTooltip placement="bottom" target={`delete${index}`}>
          Delete
        </UncontrolledTooltip>
      </div>
    ),
    name: item?.name,
    client: item?.client?.name,
    company: item?.company?.name,
    // api: item?.apiKey,
  }))

  const defaultSorted = [
    {
      dataField: "_id",
      order: "desc",
    },
  ]

  const pageOptions = {
    sizePerPage: 10,
    totalSize: 10,
    custom: true,
  }

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const handleSearch = e => {
    setSearchText(e.target.value)
  }

  const { SearchBar } = Search

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page, searchText }) => {
    setZproductList(
      banners.filter(zproduct =>
        Object.keys(zproduct).some(key =>
          zproduct[key].toLowerCase().includes(searchText.toLowerCase())
        )
      )
    )
  }

  const handleSwitchChange = (e, id) => {
    // TODO: API needed for status change
  }

  return (
    <React.Fragment>
      <Row>
        <DeleteModal
          show={isOpen?.stauts}
          onCloseClick={() => setIsOpen({ ...isOpen, stauts: false })}
          onDeleteClick={handleDeleteEvent}
        />
        <Col className="col-12">
          <Card>
            <CardBody>
              <ToolkitProvider
                keyField="_id"
                key={"id"}
                columns={columns}
                data={tableData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col sm="4" lg="4">
                        <div className="search-box ms-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <SearchBar {...toolkitProps.searchProps} />
                            <i className="bx bx-search-alt search-icon" />
                          </div>
                        </div>
                      </Col>
                      {!clientDetail?._id && Clients?.clients?.length > 0 && (
                        <Col sm={8} lg="4">
                          <FormGroup className="mb-3">
                            <div className="ajax-select mt-lg-0 select2-container">
                              <Select
                                onInputChange={handleClientEnters}
                                value={clientSelect?.title}
                                placeholder={clientSelect?.title}
                                onChange={handlerClientFinalValue}
                                options={clientOptions}
                                classNamePrefix="select2-selection"
                                isLoading={loading}
                              />
                            </div>
                          </FormGroup>
                        </Col>
                      )}
                      <Col sm="8" lg={clientDetail?._id ? "8" : "4"}>
                        <div className="text-sm-end">
                          <Link
                            to={{
                              pathname: "/groWeb/create/new",
                              state: clientDetail,
                            }}
                          >
                            <Button
                              type="button"
                              color="success"
                              className="btn-rounded  mb-2 me-2"
                            >
                              <i className="mdi mdi-plus me-1" />
                              Create GroWeb
                            </Button>
                          </Link>
                        </div>
                      </Col>
                    </Row>
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        {GroWeb?.growebs?.length >= 1 ? (
                          <>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    key={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    selectRow={selectRow}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    // onTableChange={handleTableChange}
                                    // {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <MyPagination
                              totalPages={pages}
                              page={page}
                              setPage={setPage}
                            />
                          </>
                        ) : (
                          <p className="my-4 mx-3 ">No GroWebs !</p>
                        )}
                      </>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default GroWebs

GroWebs.propTypes = {
  clientDetail: PropTypes.any,
}
