import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Card, CardBody, Media, Spinner } from "reactstrap"

//actions
import { getDashboardData, getGraphData } from "store/dashboard/actions"
import MetaTag from "components/Common/Meta-tag"
import { Link } from "react-router-dom"
import useGetStore from "hooks/useGetStore"
import ReactApexChart from "react-apexcharts"
import nullimg from "../../assets/images/testimg/nullimg.jpg"

const Dashboard = () => {
  const dispatch = useDispatch()
  const { isStoreAdmin } = useGetStore()
  const { dashboardData, loading, earningChartData } = useSelector(state => ({
    dashboardData: state.Dashboard.dashboardData,
    loading: state.Dashboard.loading,
    earningChartData: state.Dashboard.graphData,
  }))

  useEffect(() => {
    dispatch(getDashboardData({ isStoreAdmin }))
  }, [dispatch, isStoreAdmin])

  useEffect(() => {
    dispatch(getGraphData(""))
  }, [dispatch])

  const series1 = [
    {
      name: "GROWEB",
      data: earningChartData?.length >= 1 ? earningChartData : [],
    },
  ]
  const options1 = {
    chart: { sparkline: { enabled: !0 } },
    stroke: { curve: "smooth", width: 2 },
    colors: ["#f1b44c"],
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: !1,
        opacityFrom: 0.45,
        opacityTo: 0.05,
        stops: [25, 100, 100, 100],
      },
    },
    tooltip: {
      fixed: { enabled: !1 },
      x: { show: !1 },
      marker: { show: !1 },
    },
  }

  return (
    <React.Fragment>
      <div className="new-page">
        <MetaTag title="Dashboard" />
        <Container fluid>
          {loading ? (
            <Container fluid>
              <Row className="equal-height-row">
                <Spinner color="secondary" className="d-block m-auto" />
              </Row>
            </Container>
          ) : (
            <>
              <Container fluid>
                <Row className="equal-height-row">
                  <Col lg={5}>
                    <Row className="mb-4">
                      <Col className="col-6">
                        <Link to="/groweb">
                          <Card className="h-100 custom-border-radius">
                            <CardBody className="d-flex flex-column align-items-center justify-content-center text-center">
                              <h5 className="mb-3"> Todays GroWeb</h5>
                              <div className="h3">
                                {dashboardData?.todayGrowebCount}
                              </div>
                            </CardBody>{" "}
                          </Card>
                        </Link>
                      </Col>
                      <Col className="col-6">
                        <Link to="/groweb">
                          <Card className=" bg-warning h-100 custom-border-radius">
                            <CardBody className="d-flex flex-column align-items-center justify-content-center text-center">
                              <h5 className="mb-3"> Total GroWeb</h5>
                              <div className="h3">
                                {dashboardData?.totalGrowebCount}
                              </div>
                            </CardBody>{" "}
                          </Card>
                        </Link>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="col-12">
                        <Card
                          className="custom-border-radius graph-card"
                          style={{ backgroundColor: "#d0d0d0" }}
                        >
                          <CardBody>
                            <p className="text-muted mb-4">
                              <i
                                className={
                                  "bx bx-layer" +
                                  " h2 text-" +
                                  "warning" +
                                  " align-middle mb-0 me-3"
                                }
                              />{" "}
                              Groweb Analytics
                            </p>
                            <Row>
                              <Col xs="6">
                                <div>
                                  <h5> Groweb yearly report</h5>
                                  <p className="text-muted text-truncate mb-0">
                                    We have created{" "}
                                    {dashboardData?.totalGrowebCount} Growebs so
                                    far
                                  </p>
                                </div>
                              </Col>
                              <Col xs="6">
                                <div>
                                  <div>
                                    <ReactApexChart
                                      options={options1}
                                      series={series1}
                                      type="area"
                                      height={100}
                                      className="apex-charts"
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={5} className="height">
                    <Card className="custom-border-radius">
                      <img
                        className="custom-border-radius img-null"
                        src={nullimg}
                      ></img>
                    </Card>
                  </Col>
                  <Col lg={2}>
                    <Row className="">
                      <Col className="col-12">
                        <Link to="/client">
                          <Card className="custom-border-radius">
                            <CardBody>
                              <div className="earing">
                                <h6 className="text-muted">Todays Client </h6>
                                <h3 className="text-black bold">
                                  {dashboardData?.todayClientsCount}
                                </h3>
                              </div>

                              <div className="pt-1">
                                <h6 className="text-muted">Total Client </h6>
                                <h3 className="text-black bold count-inNo">
                                  {dashboardData?.totalClientsCount}
                                </h3>
                              </div>
                            </CardBody>
                          </Card>
                        </Link>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Dashboard
