/* GrowPages - This line cannot be edited or removed */
export const GET_GROWPAGES = "GET_GROWPAGES"
export const GET_GROWPAGES_SUCCESS = "GET_GROWPAGES_SUCCESS"
export const GET_GROWPAGES_FAIL = "GET_GROWPAGES_FAIL"

/* GrowPage_DETAILS */
export const GET_GROWPAGE_DETAILS = "GET_GROWPAGE_DETAILS"
export const GET_GROWPAGE_DETAILS_SUCCESS = "GET_GROWPAGE_DETAILS_SUCCESS"
export const GET_GROWPAGE_DETAILS_FAIL = "GET_GROWPAGE_DETAILS_FAIL"

/* CREATE GrowPage */
export const CREATE_GROWPAGE = "CREATE_GROWPAGE"
export const CREATE_GROWPAGE_SUCCESS = "CREATE_GROWPAGE_SUCCESS"
export const CREATE_GROWPAGE_FAIL = "CREATE_GROWPAGE_FAIL"

/* Edit GrowPage */
export const UPDATE_GROWPAGE = "UPDATE_GROWPAGE"
export const UPDATE_GROWPAGE_SUCCESS = "UPDATE_GROWPAGE_SUCCESS"
export const UPDATE_GROWPAGE_FAIL = "UPDATE_GROWPAGE_FAIL"

/* Delete GrowPage */
export const DELETE_GROWPAGE = "DELETE_GROWPAGE"
export const DELETE_GROWPAGE_SUCCESS = "DELETE_GROWPAGE_SUCCESS"
export const DELETE_GROWPAGE_FAIL = "DELETE_GROWPAGE_FAIL"

//sections
export const GET_DEFAULT_SECTION = "GET_DEFAULT_SECTION"
export const GET_DEFAULT_SECTION_SUCCESS = "GET_DEFAULT_SECTION_SUCCESS"
export const GET_DEFAULT_SECTION_FAIL = "GET_DEFAULT_SECTION_FAIL"

export const CREATE_SECTION = "CREATE_SECTION"
export const CREATE_SECTION_SUCCESS = "CREATE_SECTION_SUCCESS"
export const CREATE_SECTION_FAIL = "CREATE_SECTION_FAIL"

export const UPDATE_SECTION = "UPDATE_SECTION"
export const UPDATE_SECTION_SUCCESS = "UPDATE_SECTION_SUCCESS"
export const UPDATE_SECTION_FAIL = "UPDATE_SECTION_FAIL"

export const DELETE_SECTION = "DELETE_SECTION"
export const DELETE_SECTION_SUCCESS = "DELETE_SECTION_SUCCESS"
export const DELETE_SECTION_FAIL = "DELETE_SECTION_FAIL"

export const CHECK_PAGE_TITLE = "CHECK_PAGE_TITLE"
export const CHECK_PAGE_TITLE_SUCCESS = "CHECK_PAGE_TITLE_SUCCESS"
export const CHECK_PAGE_TITLE_FAIL = "CHECK_PAGE_TITLE_FAIL"

export const GET_DUPLICATE_SECTION = "GET_DUPLICATE_SECTION"
export const GET_DUPLICATE_SECTION_SUCCESS = "GET_DUPLICATE_SECTION_SUCCESS"
export const GET_DUPLICATE_SECTION_FAIL = "GET_DUPLICATE_SECTION_FAIL"
