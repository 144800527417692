import { call, put, takeEvery, all, fork } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_CHARTS_DATA,
  GET_DASHBOARD_DATA,
  GET_GRAPH_DATA,
  GET_PROMOTER_DASHBOARD_DATA,
  GET_PROMOTER_PROFILE_DETAILS,
} from "./actionTypes"
import {
  apiSuccess,
  apiFail,
  getDashboardDataSuccess,
  getDashboardDataFail,
  getPromoterProfileDetailsSuccess,
  getPromoterProfileDetailsFail,
  getGraphDataSuccess,
  getGraphDataFail,
} from "./actions"

import { get } from "helpers/api_helper"
import {
  getPromoterDashboardDataFail,
  getPromoterDashboardDataSuccess,
} from "store/actions"

const role = sessionStorage?.getItem("role")

// function getDashboardDataAPi({ isStoreAdmin }) {
//   if (!isStoreAdmin) {
//     return get("/static/admin/dashboard/data")
//   } else return get("/static/store-admin/dashboard/data")
// }
function getDashboardDataAPi() {
  return get(`/static/admin/dashboard/data`)
}
// promoter profile api
function promoterProfileApi() {
  return get(`/promoter`)
}

// promoter dashboard api

function promoterDashboardDataApi() {
  return get(`/static/promoter/dashboard/data`)
}
// graph data api

function graphDataApi() {
  return get(`groweb/admin/graph/monthly`)
}
function* getChartsData({ payload: periodType }) {
  try {
    var response
    yield put(apiSuccess(GET_CHARTS_DATA, response))
  } catch (error) {
    yield put(apiFail(GET_CHARTS_DATA, error))
  }
}

//promoter dashboard data

function* promoterDashboardData({ payload }) {
  try {
    const response = yield call(promoterDashboardDataApi, payload)
    yield put(getPromoterDashboardDataSuccess(response))
  } catch (error) {
    yield put(getPromoterDashboardDataFail(error))
  }
}
//graph data

function* graphData({ payload }) {
  try {
    const response = yield call(graphDataApi, payload)
    yield put(getGraphDataSuccess(response))
  } catch (error) {
    yield put(getGraphDataFail(error))
  }
}

function* fetchDashboardData({ payload }) {
  try {
    const response = yield call(getDashboardDataAPi, payload)
    yield put(getDashboardDataSuccess(response))
  } catch (error) {
    yield put(getDashboardDataFail(error))
  }
}
function* promoterProfile({ payload }) {
  try {
    const response = yield call(promoterProfileApi, payload)
    yield put(getPromoterProfileDetailsSuccess(response))
  } catch (error) {
    yield put(getPromoterProfileDetailsFail(error))
  }
}
export function* watchGetChartsData() {
  yield takeEvery(GET_CHARTS_DATA, getChartsData)
}

function* dashboardSaga() {
  yield all([fork(watchGetChartsData)])

  yield takeEvery(GET_DASHBOARD_DATA, fetchDashboardData)
  yield takeEvery(GET_PROMOTER_PROFILE_DETAILS, promoterProfile)
  yield takeEvery(GET_PROMOTER_DASHBOARD_DATA, promoterDashboardData)
  yield takeEvery(GET_GRAPH_DATA, graphData)
}

export default dashboardSaga
