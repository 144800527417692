import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import SidebarContent from "./SidebarContent"

import { Link } from "react-router-dom"

//redux
import { useSelector } from "react-redux"
import { LocalLogo } from "assets/images"

const Sidebar = props => {
  const { sideBarSm } = useSelector(state => ({
    sideBarSm: state.Layout.sideBarSm,
  }))

  const primaryColor = "white"
  return (
    <React.Fragment>
      <div
        className="vertical-menu"
        style={{ backgroundColor: primaryColor || "#ffff" }}
      >
        <div className="navbar-brand-box ">
          <Link to="/" className="logo logo-dark">
            <span className="logo-sm">
              <img
                src={sideBarSm === false ? LocalLogo : LocalLogo}
                alt="company logo"
                height="40"
              />
            </span>
            <span className="logo-lg">
              <img src={LocalLogo} alt="" height="50" />
            </span>
          </Link>

          <Link to="/" className="logo logo-light"></Link>
        </div>
        <div data-simplebar className="h-100">
          {props.type !== "condensed" ? (
            <SidebarContent props />
          ) : (
            <SidebarContent />
          )}
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  )
}

Sidebar.propTypes = {
  type: PropTypes.string,
}

const mapStatetoProps = state => {
  return {
    layout: state.Layout,
  }
}
export default connect(
  mapStatetoProps,
  {}
)(withRouter(withTranslation()(Sidebar)))
