import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Card, Modal, ModalBody, ModalHeader } from "reactstrap"
import { getDefaultSection } from "store/actions"
import SectionUi from "./SectionUi"
import { IoClose } from "react-icons/io5"
import { handleLayoutText } from "./Update"

function AddDefaultSectionModal({ onCloseClick, show, updatePage }) {
  const dispatch = useDispatch()
  const { defaultSections, sectionLoad } = useSelector(state => ({
    sectionLoad: state.GrowPage.sectionLoad,
    defaultSections: state.GrowPage.defaultSections,
  }))

  useEffect(() => {
    if (show) {
      dispatch(getDefaultSection())
    }
  }, [show])

  return (
    <Modal
      isOpen={show}
      toggle={onCloseClick}
      centered={true}
      style={deleteModalStyle}
      className="section-modal"
    >
      <div className="d-flex justify-content-between align-items-center w-100">
        <ModalHeader>
          {" "}
          Select a section ({defaultSections?.length}){" "}
        </ModalHeader>
        <IoClose
          color="red"
          className="mx-3 cursor-pointer"
          onClick={onCloseClick}
          size={"1.3rem"}
        />
      </div>
      <ModalBody style={deleteModalBodyStyle} className="p-0">
        {defaultSections?.length >= 1 ? (
          (defaultSections || [])?.map((item, index) => (
            <Card
              key={index}
              outline
              color="light"
              className="border w-100 m-0 defaultSectionCard"
              style={{ cursor: "pointer" }}
              onClick={() => updatePage(item?._id)}
            >
              <h5
                className="mt-2 p-0 m-0 text-center "
                style={{ minWidth: 150 }}
              >
                {handleLayoutText(item?.layout)}
              </h5>
              <SectionUi className="mini-widget mt-0" item={item} />
            </Card>
          ))
        ) : (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ minHeight: 300 }}
          >
            <p className="fs-3 text-primary">No Default Sections !</p>
          </div>
        )}
      </ModalBody>
    </Modal>
  )
}

export default AddDefaultSectionModal

AddDefaultSectionModal.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.bool,
  updatePage: PropTypes.func,
}

const deleteModalStyle = {}
const deleteModalBodyStyle = {
  minHeight: 300,
  maxHeight: 700,
  overflow: "scroll",
}
