/* PageViews - This line cannot be edited or removed */
export const GET_PAGEVIEWS = "GET_PAGEVIEWS"
export const GET_PAGEVIEWS_SUCCESS = "GET_PAGEVIEWS_SUCCESS"
export const GET_PAGEVIEWS_FAIL = "GET_PAGEVIEWS_FAIL"

/* PageView_DETAILS */
export const GET_PAGEVIEW_DETAILS = "GET_PAGEVIEW_DETAILS"
export const GET_PAGEVIEW_DETAILS_SUCCESS = "GET_PAGEVIEW_DETAILS_SUCCESS"
export const GET_PAGEVIEW_DETAILS_FAIL = "GET_PAGEVIEW_DETAILS_FAIL"

/* CREATE PageView */
export const CREATE_PAGEVIEW = "CREATE_PAGEVIEW"
export const CREATE_PAGEVIEW_SUCCESS = "CREATE_PAGEVIEW_SUCCESS"
export const CREATE_PAGEVIEW_FAIL = "CREATE_PAGEVIEW_FAIL"

/* Edit PageView */
export const UPDATE_PAGEVIEW = "UPDATE_PAGEVIEW"
export const UPDATE_PAGEVIEW_SUCCESS = "UPDATE_PAGEVIEW_SUCCESS"
export const UPDATE_PAGEVIEW_FAIL = "UPDATE_PAGEVIEW_FAIL"

/* Delete PageView */
export const DELETE_PAGEVIEW = "DELETE_PAGEVIEW"
export const DELETE_PAGEVIEW_SUCCESS = "DELETE_PAGEVIEW_SUCCESS"
export const DELETE_PAGEVIEW_FAIL = "DELETE_PAGEVIEW_FAIL"
