import {
  GET_PAGEVIEWS,
  GET_PAGEVIEWS_SUCCESS,
  GET_PAGEVIEWS_FAIL,
  GET_PAGEVIEW_DETAILS,
  GET_PAGEVIEW_DETAILS_SUCCESS,
  GET_PAGEVIEW_DETAILS_FAIL,
  CREATE_PAGEVIEW,
  CREATE_PAGEVIEW_SUCCESS,
  CREATE_PAGEVIEW_FAIL,
  UPDATE_PAGEVIEW,
  UPDATE_PAGEVIEW_SUCCESS,
  UPDATE_PAGEVIEW_FAIL,
  DELETE_PAGEVIEW_SUCCESS,
  DELETE_PAGEVIEW_FAIL,
  DELETE_PAGEVIEW,
} from "./actionTypes"

// PageView - This line cannot be edited or removed
export const getPageViews = (page, sort, limit, searchText, groweb) => ({
  type: GET_PAGEVIEWS,
  payload: { page, sort, limit, searchText, groweb },
})

export const getPageViewsSuccess = pageviews => ({
  type: GET_PAGEVIEWS_SUCCESS,
  payload: pageviews,
})

export const getPageViewsFail = error => ({
  type: GET_PAGEVIEWS_FAIL,
  payload: error,
})

export const getPageViewDetails = pageviewId => ({
  type: GET_PAGEVIEW_DETAILS,
  payload: pageviewId,
})

export const getPageViewDetailsSuccess = pageviewDetails => ({
  type: GET_PAGEVIEW_DETAILS_SUCCESS,
  payload: pageviewDetails,
})

export const getPageViewDetailsFail = error => ({
  type: GET_PAGEVIEW_DETAILS_FAIL,
  payload: error,
})

export const createPageView = (pageview, history) => ({
  type: CREATE_PAGEVIEW,
  payload: { pageview, history },
})

export const createPageViewSuccess = pageview => ({
  type: CREATE_PAGEVIEW_SUCCESS,
  payload: pageview,
})

export const createPageViewFail = error => ({
  type: CREATE_PAGEVIEW_FAIL,
  payload: error,
})

export const getPageViewDetail = pageviewId => ({
  type: GET_PAGEVIEW_DETAILS,
  payload: pageviewId,
})

export const updatePageView = (pageview, pageviewId, history) => ({
  type: UPDATE_PAGEVIEW,
  payload: { pageview, pageviewId, history },
})

export const updatePageViewSuccess = pageview => ({
  type: UPDATE_PAGEVIEW_SUCCESS,
  payload: pageview,
})

export const updatePageViewFail = error => ({
  type: UPDATE_PAGEVIEW_FAIL,
  payload: error,
})

export const deletePageView = (pageviewId, history) => ({
  type: DELETE_PAGEVIEW,
  payload: { pageviewId, history },
})

export const deletePageViewSuccess = pageview => ({
  type: DELETE_PAGEVIEW_SUCCESS,
  payload: pageview,
})

export const deletePageViewFail = error => ({
  type: DELETE_PAGEVIEW_FAIL,
  payload: error,
})
