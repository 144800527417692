/* Leads - This line cannot be edited or removed */
export const GET_LEADS = "GET_LEADS"
export const GET_LEADS_SUCCESS = "GET_LEADS_SUCCESS"
export const GET_LEADS_FAIL = "GET_LEADS_FAIL"

/* Lead_DETAILS */
export const GET_LEAD_DETAILS = "GET_LEAD_DETAILS"
export const GET_LEAD_DETAILS_SUCCESS = "GET_LEAD_DETAILS_SUCCESS"
export const GET_LEAD_DETAILS_FAIL = "GET_LEAD_DETAILS_FAIL"

/* CREATE Lead */
export const CREATE_LEAD = "CREATE_LEAD"
export const CREATE_LEAD_SUCCESS = "CREATE_LEAD_SUCCESS"
export const CREATE_LEAD_FAIL = "CREATE_LEAD_FAIL"

/* Edit Lead */
export const UPDATE_LEAD = "UPDATE_LEAD"
export const UPDATE_LEAD_SUCCESS = "UPDATE_LEAD_SUCCESS"
export const UPDATE_LEAD_FAIL = "UPDATE_LEAD_FAIL"

/* Delete Lead */
export const DELETE_LEAD = "DELETE_LEAD"
export const DELETE_LEAD_SUCCESS = "DELETE_LEAD_SUCCESS"
export const DELETE_LEAD_FAIL = "DELETE_LEAD_FAIL"
