import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_CLIENTS,
  GET_CLIENT_DETAILS,
  CREATE_CLIENT,
  UPDATE_CLIENT,
  DELETE_CLIENT,
} from "./actionTypes"
import {
  getClientsFail,
  getClientsSuccess,
  getClientDetailsSuccess,
  getClientDetailsFail,
  createClientFail,
  createClientSuccess,
  updateClientSuccess,
  updateClientFail,
  deleteClientSuccess,
  deleteClientFail,
} from "./actions"

import { get, post, ApiPut, del } from "helpers/api_helper"
import { Notification } from "../../components/Common/Notification"

// Client - This line cannot be edited or removed
function getClientsAPi({ page, sort, limit, searchText }) {
  return get(
    `/client/admin/all/?sort=${sort}&page=${page ? page : 1}&limit=${
      limit ? limit : 10
    }&search=${searchText ? searchText : ""}`
  )
}

const getClientDetailsAPi = clientId => {
  return get(`/client/admin/${clientId}`)
}

const createClientApi = ({ client }) => {
  return post("/client/admin/new", client)
}

const updateClientApi = ({ client, clientId }) => {
  return ApiPut(`/client/admin/${clientId}`, client)
}

const deleteClientApi = ({ clientId }) => {
  return del(`/client/admin/${clientId}`)
}

function* fetchClients({ payload }) {
  try {
    const response = yield call(getClientsAPi, payload)
    yield put(getClientsSuccess(response))
  } catch (error) {
    yield put(getClientsFail(error))
  }
}

function* fetchClientDetails({ payload: clientId }) {
  try {
    const response = yield call(getClientDetailsAPi, clientId)
    yield put(getClientDetailsSuccess(response))
  } catch (error) {
    yield put(getClientDetailsFail(error))
  }
}

function* onCreateClient({ payload }) {
  try {
    const response = yield call(createClientApi, payload)
    if (response?.message) {
      Notification({
        type: "error",
        message: response?.message,
        title: "",
      })
    } else if (response) {
      yield put(createClientSuccess(response))
      payload?.history?.push("/clients")
      doneNotification("Client Created Successfully!")
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: "error",
        message: "file size is Too Large",
        title: "",
      })
    }
    yield put(createClientFail(error))
  }
}

function* onUpdateClient({ payload }) {
  try {
    const response = yield call(updateClientApi, payload)
    if (response) {
      Notification({
        type: "success",
        message: "Client Updated Successfully!",
        title: "",
      })
      yield put(updateClientSuccess(response))
      if (payload.history) {
        payload.history.push("/clients")
      }
    }
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: "error",
        message: "file size is Too Large",
        title: "",
      })
    }
    yield put(updateClientFail(error.response.data))
  }
}

function* onDeleteClient({ payload }) {
  try {
    const response = yield call(deleteClientApi, payload)
    yield put(deleteClientSuccess(response))
    doneNotification("Client Deleted Successfully!")
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    yield put(deleteClientFail(error?.response?.data))
  }
}

function* clientSaga() {
  yield takeEvery(GET_CLIENTS, fetchClients)
  yield takeEvery(GET_CLIENT_DETAILS, fetchClientDetails)
  yield takeEvery(CREATE_CLIENT, onCreateClient)
  yield takeEvery(UPDATE_CLIENT, onUpdateClient)
  yield takeEvery(DELETE_CLIENT, onDeleteClient)
}

export default clientSaga

function doneNotification(message) {
  Notification({
    type: "success",
    message: message,
    title: "",
  })
}
