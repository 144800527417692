import { debounce } from "lodash"
import PropTypes from "prop-types"
import classnames from "classnames"
import { useHistory } from "react-router"
import React, { useEffect, useState } from "react"
import { Col, Container, FormGroup, Label } from "reactstrap"
import {
  AvField,
  AvForm,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation"
import { Card, CardBody, CardText, CardTitle } from "reactstrap"
import { Row, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap"
import Select from "react-select"
import { BsEnvelopePaperFill, BsFillBuildingsFill } from "react-icons/bs"
import { GiSatelliteCommunication } from "react-icons/gi"
import { MdOutlinePayment, MdPhonelinkSetup } from "react-icons/md"
import { isEmpty } from "lodash"
import { ChromePicker } from "react-color"
import ReactDragListView from "react-drag-listview/lib/index.js"
//actions
import {
  createGroWeb,
  getClients,
  getGroWebDetails,
  updateGroWeb,
} from "store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"

import FormEditors from "./Editor"
import Switch from "react-switch"
import { BiMessageSquareDetail } from "react-icons/bi"
import UseImageUploader from "components/imageUploader/useImageUploader"
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min"
import { OffSymbol, OnSymbol } from "pages/Product/Crud/Create/Create"
import rgbHex from "rgb-hex"
import { WithContext as ReactTags } from "react-tag-input"

const GrowebCreateForm = ({ isUpdate, growebDetails, setCustomActiveTab }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams()
  const location = useLocation()

  const { loading, Clients } = useSelector(state => ({
    Clients: state.Client.clients,
    loading: state.GroWeb.loading,
  }))
  // console.log(headerValues)

  const [activeTabVartical, setoggleTabVertical] = useState(1)
  const [passedStepsVertical, setPassedStepsVertical] = useState([1])
  const [clientSelect, setClientSelect] = useState({})
  const [clientSearch, setClientSearch] = useState()
  const [imagesPreview, setImagesPreview] = useState([])
  const [imagesFooterPreview, setImagesFooterPreview] = useState([])
  const [leadBanner, setLeadBanner] = useState([])
  const [isBottomNavigationBarNeeded, setisBottomNavigationBarNeeded] =
    useState(false)
  const [isFooterNeeded, setisFooterNeeded] = useState(false)
  const [isHeaderNeeded, setisHeaderNeeded] = useState(false)
  const [footerBackgroundColor, setFooterBackgroundColor] = useState("")
  const [footerForegroundColor, setFooterForegroundColor] = useState("")

  const [headerBackgroundColor, setHeaderBackgroundColor] = useState("")
  const [headerForegroundColor, setHeaderForegroundColor] = useState("")

  console.log("ameen", headerBackgroundColor)
  console.log("ameen", headerForegroundColor)

  const [primaryColor, setPrimaryColor] = useState("")
  const [secondaryColor, setSecondaryColor] = useState("")

  const [tags, setTags] = useState([])
  const [keywords, setKeywords] = useState([])

  const handleDeleteTag = i => {
    setTags(tags.filter((tag, index) => index !== i))
    const arrangedKeywords = tags.map(a => a.text)
    setKeywords(arrangedKeywords)
  }

  const handleAddition = tag => {
    setTags([...tags, tag])
    const arrangedKeywords = [...tags, tag].map(a => a.text)
    setKeywords(arrangedKeywords)
  }

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice()
    newTags.splice(currPos, 1)
    newTags.splice(newPos, 0, tag)
    // re-render
    setTags(newTags)
    const arrangedKeywords = newTags.map(a => a.text)
    setKeywords(arrangedKeywords)
  }

  const handleTagClick = index => {
    console.log("The tag at index " + index + " was clicked")
  }
  console.log(imagesFooterPreview)
  const debounceClientSearch = debounce(value => setClientSearch(value), 600)
  function toggleTabVertical(tab) {
    if (activeTabVartical !== tab) {
      let modifiedSteps = [...passedStepsVertical, tab]
      console.log(modifiedSteps)
      if (tab >= 1 && tab <= 7) {
        setoggleTabVertical(tab)
        setPassedStepsVertical(modifiedSteps)
      }
    }
  }
  //client dropdown
  const handleClientEnters = textEntered => {
    debounceClientSearch(textEntered)
  }

  function handlerClientFinalValue(event) {
    const client = {
      ...clientSelect,
      title: event.value !== "loading" && event.label,
      id: event.value,
    }
    setClientSelect(client)
  }
  useEffect(() => {
    dispatch(getClients(1, "", 10, clientSearch))
  }, [dispatch])

  useEffect(() => {
    if (isUpdate) {
      dispatch(getGroWebDetails(params.id))
    }
  }, [dispatch])

  const clientOptions = [
    {
      label: "All Clients",
      value: "",
    },
    {
      options: (Clients?.clients?.length >= 1 ? Clients?.clients : [])?.map(
        (results, index) => ({
          key: index,
          label: results.name,
          value: results._id,
        })
      ),
    },
  ]

  const KeyCodes = {
    comma: 188,
    enter: 13,
  }
  const delimiters = [KeyCodes.comma, KeyCodes.enter]
  const [customIconActiveTab, setcustomIconActiveTab] = useState("1")

  const shippingInputData = [
    "name",
    "email",
    "contactNumber",
    "whatsappNumber",
    "address",
    "message",
    "budget",
  ]

  const toggleIconCustom = tab => {
    if (customIconActiveTab !== tab) {
      setcustomIconActiveTab(tab)
    }
  }
  // useEffect(() => {
  //   setTaxCategories(
  //    [{ name: "", percentage: 0 }]
  //   )
  //   setLeadInputs(
  //      [{ name: "", required: "" }]
  //   )
  //   setBottomValues(
  //     [{ title: "", icon: "", link: "" }]
  //   )
  //   setHeaderValues(
  //     [{ title: "", icon: "", link: "" }]
  //   )
  //   setClassificationInputs(
  //     settings?.setup?.classificationInputs || [{ name: "", required: "" }]
  //   )

  //   setPrivacyPolicy(settings?.legal?.privacyPolicy || "")
  //   setTerms(settings?.legal?.terms || "")
  //   setReturnPolicy(settings?.legal?.returnPolicy || "")
  //   setWhatsappApiConnected(
  //     settings?.communications?.isWhatsappApiConnected || false
  //   )

  // }, [ loading])

  const [privacyPolicy, setPrivacyPolicy] = useState(
    growebDetails?.legal?.privacyPolicy || ""
  )
  const [terms, setTerms] = useState(growebDetails?.legal?.terms || "")
  const [returnPolicy, setReturnPolicy] = useState(
    growebDetails?.legal?.returnPolicy || ""
  )

  const [leadInputs, setLeadInputs] = useState([
    {
      value: "",
      isRequired: false,
    },
  ])

  const [bottomValues, setBottomValues] = useState([
    {
      title: "",
      icon: "",
      link: "",
      iconSVG: [],
    },
  ])

  const [headerValues, setHeaderValues] = useState([
    {
      title: "",
      icon: "",
      link: "",
      iconSVG: [],
    },
  ])

  const rgbtohex = c => {
    console.log(c)
    if (c?.r) {
      return "#" + rgbHex(c?.r, c?.g, c?.b, c?.a)
    }
  }
  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const modifiedData = [...headerValues]
      const item = modifiedData.splice(fromIndex, 1)[0]
      modifiedData.splice(toIndex, 0, item)
      setHeaderValues(modifiedData)
    },
    nodeSelector: "tr",
    handleSelector: "tr",
  }
  const dragProps2 = {
    onDragEnd(fromIndex, toIndex) {
      const modifiedData = [...bottomValues]
      const item = modifiedData.splice(fromIndex, 1)[0]
      modifiedData.splice(toIndex, 0, item)
      setBottomValues(modifiedData)
    },
    nodeSelector: "tr",
    handleSelector: "tr",
  }

  const dragProps3 = {
    onDragEnd(fromIndex, toIndex) {
      const modifiedData = [...leadInputs]
      const item = modifiedData.splice(fromIndex, 1)[0]
      modifiedData.splice(toIndex, 0, item)
      setLeadInputs(modifiedData)
    },
    nodeSelector: "tr",
    handleSelector: "tr",
  }
  function handleValidSubmit(values) {
    const GroWebData = {
      // ...values,
      client: clientSelect.id,
      name: values.name,
      description: values.description,
      keywords,
      setup: {
        leadData: {
          ...values?.leadData,
          banner: leadBanner,
          leadInputs: leadInputs,
        },

        footerBackgroundColor:
          footerBackgroundColor?.hex ||
          growebDetails?.setup?.footerBackgroundColor,

        footerForegroundColor:
          footerForegroundColor?.hex ||
          growebDetails?.setup?.footerForegroundColor,

        headerBackgroundColor:
          headerBackgroundColor?.hex ||
          growebDetails?.setup?.headerBackgroundColor,

        headerForegroundColor:
          headerForegroundColor?.hex ||
          growebDetails?.setup?.headerForegroundColor,

        headerValues: headerValues,
        bottomNavigationBarValues: bottomValues,
        logo: imagesPreview,
        footerLogo: imagesFooterPreview,
        logoLink: values?.logoLink,
        isFooterNeeded: isFooterNeeded,
        isHeaderNeeded: isHeaderNeeded,
        isBottomNavigationBarNeeded: isBottomNavigationBarNeeded,
        theme: {
          primaryColor:
            primaryColor?.hex || growebDetails?.setup?.theme?.primaryColor,
          secondaryColor:
            secondaryColor?.hex || growebDetails?.setup?.theme?.secondaryColor,
        },
      },

      company: {
        ...values?.company,
        address: {
          addressLine1: values.addressLine1,
          addressLine2: values.addressLine2,
          district: values.district,
          state: values.state,
          country: values.country,
          pincode: values.pincode,
        },
        location: [0, 0],
        contactDetails: values.contactDetails,
        socialLinks: values.socialLinks,
      },
      legal: {
        ...values?.legal,
        establishmentName: values.establishmentName,
        privacyPolicy: privacyPolicy,
        returnPolicy: returnPolicy,
        terms: terms,
      },
    }
    if (params?.id) {
      dispatch(updateGroWeb(GroWebData, growebDetails?._id, setCustomActiveTab))
    } else {
      dispatch(createGroWeb(GroWebData, history))
    }
  }

  const Offsymbol1 = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 9,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        Not Required
      </div>
    )
  }

  const OnSymbol1 = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 9,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        Required
      </div>
    )
  }

  const dropdownOptions = [
    { title: "Home", value: "home" },
    { title: "About", value: "user-pin" },
    { title: "Account", value: "user" },
    { title: "Service", value: "briefcase" },
  ]
  const dropdownOptionsHeader = [
    { title: "Home", value: "home" },
    { title: "About", value: "user-pin" },
    { title: "Account", value: "user" },
    { title: "Service", value: "briefcase" },
  ]
  console.log(clientSelect?.title)
  useEffect(() => {
    if (growebDetails) {
      setClientSelect({
        ...clientSelect,
        title:
          growebDetails?.client?.name || location?.state?.name
            ? growebDetails?.client?.name || location?.state?.name
            : "Select client",
        id:
          growebDetails?.client?._id || location?.state?._id
            ? growebDetails?.client?._id || location?.state?._id
            : "",
      })
      setHeaderValues(
        growebDetails?.setup?.headerValues.length > 0
          ? growebDetails?.setup?.headerValues
          : [
              {
                title: "",
                icon: "",
                link: "",
              },
            ]
      )
      setBottomValues(
        growebDetails?.setup?.bottomNavigationBarValues.length > 0
          ? growebDetails?.setup?.bottomNavigationBarValues
          : [
              {
                title: "",
                icon: "",
                link: "",
              },
            ]
      )
      setLeadInputs(
        growebDetails?.setup?.leadData?.leadInputs.length > 0
          ? growebDetails?.setup?.leadData?.leadInputs
          : [
              {
                value: "",
                isRequired: false,
              },
            ]
      )
      setPrivacyPolicy(growebDetails?.legal?.privacyPolicy)
      setTerms(growebDetails?.legal?.terms)
      setReturnPolicy(growebDetails?.legal?.returnPolicy)
      setImagesPreview(growebDetails?.setup?.logo)
      setImagesFooterPreview(growebDetails?.setup?.footerLogo)
      setLeadBanner(growebDetails?.setup?.leadData?.banner)
      setisFooterNeeded(growebDetails?.setup?.isFooterNeeded)
      setisHeaderNeeded(growebDetails?.setup?.isHeaderNeeded)
      setisBottomNavigationBarNeeded(
        growebDetails?.setup?.isBottomNavigationBarNeeded
      )

      const keyWordList = growebDetails?.keywords
      const modifiedList = []
      keyWordList?.map(i => modifiedList?.push({ id: i, text: i }))

      setTags(modifiedList)
      setKeywords(keyWordList)
    }
  }, [growebDetails])

  useEffect(() => {
    setPrimaryColor(growebDetails?.setup?.theme?.primaryColor)
    setSecondaryColor(growebDetails?.setup?.theme?.secondaryColor)

    setFooterForegroundColor(growebDetails?.setup?.footerForegroundColor)
    setFooterBackgroundColor(growebDetails?.setup?.footerBackgroundColor)
    setHeaderForegroundColor(growebDetails?.setup?.headerForegroundColor)
    setHeaderBackgroundColor(growebDetails?.setup?.headerBackgroundColor)
  }, [growebDetails])

  return (
    <React.Fragment>
      <div className="">
        <Container fluid>
          <div className="container-fluid">
            <AvForm
              onValidSubmit={(e, v) => {
                handleValidSubmit(v)
              }}
            >
              <Row xl="12">
                <Col xl="12">
                  <div className="vertical-wizard wizard clearfix vertical mt-4">
                    <div className="steps clearfix">
                      <ul className="steps-new">
                        <NavItem
                          className={classnames({
                            current: activeTabVartical === 1,
                          })}
                        >
                          <NavLink
                            className={classnames({
                              active: activeTabVartical === 1,
                              isActive: (passedStepsVertical || []).includes(1),
                            })}
                            onClick={() => {
                              toggleTabVertical(1)
                            }}
                            disabled={!(passedStepsVertical || []).includes(1)}
                          >
                            GroWeb
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({
                            current: activeTabVartical === 2,
                          })}
                        >
                          <NavLink
                            className={classnames({
                              active: activeTabVartical === 2,
                              isActive: (passedStepsVertical || []).includes(2),
                            })}
                            onClick={() => {
                              toggleTabVertical(2)
                            }}
                            disabled={
                              !isUpdate &&
                              !(passedStepsVertical || []).includes(2)
                            }
                          >
                            <span>Layout </span>
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({
                            current: activeTabVartical === 3,
                          })}
                        >
                          <NavLink
                            className={classnames({
                              active: activeTabVartical === 3,
                              isActive: (passedStepsVertical || []).includes(3),
                            })}
                            onClick={() => {
                              toggleTabVertical(3)
                            }}
                            disabled={
                              !isUpdate &&
                              !(passedStepsVertical || []).includes(3)
                            }
                          >
                            Lead Setup
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({
                            current: activeTabVartical === 4,
                          })}
                        >
                          <NavLink
                            className={classnames({
                              active: activeTabVartical === 4,
                              isActive: (passedStepsVertical || []).includes(4),
                            })}
                            onClick={() => {
                              toggleTabVertical(4)
                            }}
                            disabled={
                              !isUpdate &&
                              !(passedStepsVertical || []).includes(4)
                            }
                          >
                            Company
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({
                            current: activeTabVartical === 5,
                          })}
                        >
                          <NavLink
                            className={classnames({
                              active: activeTabVartical === 5,
                              isActive: (passedStepsVertical || []).includes(5),
                            })}
                            onClick={() => {
                              toggleTabVertical(5)
                            }}
                            disabled={
                              !isUpdate &&
                              !(passedStepsVertical || []).includes(5)
                            }
                          >
                            <span>Communications</span>
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({
                            current: activeTabVartical === 6,
                          })}
                        >
                          <NavLink
                            className={classnames({
                              active: activeTabVartical === 6,
                              isActive: (passedStepsVertical || []).includes(6),
                            })}
                            onClick={() => {
                              toggleTabVertical(6)
                            }}
                            disabled={
                              !isUpdate &&
                              !(passedStepsVertical || []).includes(6)
                            }
                          >
                            <span>Legal</span>
                          </NavLink>
                        </NavItem>
                      </ul>
                    </div>
                    <div className="content clearfix pt-0 md:pt-20">
                      <TabContent
                        activeTab={activeTabVartical}
                        className="body"
                      >
                        <TabPane tabId={1}>
                          <Row className="mt-3 ml-5">
                            <Col sm="12">
                              <CardText className="mb-0">
                                <Row className="mt-3">
                                  <Col className="col-10 mx-5">
                                    {/* <h5 className="mb-4" style={{color: '#1e81b0'}}>Basic Data</h5> */}
                                    <div className=" col-12 row mb-4">
                                      <Label
                                        htmlFor="horizontal-firstname-Input"
                                        className="col-sm-4 col-form-label"
                                      >
                                        {" "}
                                        Client :
                                      </Label>
                                      <Col sm={6}>
                                        <FormGroup className="mb-">
                                          <div className="ajax-select mt-lg-0 select2-container">
                                            {
                                              // location && location?.state ? (
                                              //   <AvField
                                              //     name="client"
                                              //     value={location?.state?.name}
                                              //     readOnly
                                              //   />
                                              // ) :

                                              growebDetails?._id ? (
                                                <AvField
                                                  name="client"
                                                  value={clientSelect?.title}
                                                  readOnly
                                                />
                                              ) : (
                                                <Select
                                                  onInputChange={
                                                    handleClientEnters
                                                  }
                                                  value={clientSelect?.title}
                                                  placeholder={
                                                    clientSelect?.title
                                                  }
                                                  onChange={
                                                    handlerClientFinalValue
                                                  }
                                                  options={clientOptions}
                                                  classNamePrefix="select2-selection"
                                                  isLoading={loading}
                                                />
                                              )
                                            }
                                          </div>
                                        </FormGroup>
                                      </Col>
                                    </div>
                                    <div className=" col-12 row mb-4">
                                      <Label
                                        htmlFor="horizontal-firstname-Input"
                                        className="col-sm-4 col-form-label"
                                      >
                                        {" "}
                                        Name :
                                      </Label>
                                      <Col sm={6}>
                                        <AvField
                                          name="name"
                                          type="text"
                                          value={growebDetails?.name}
                                        />
                                      </Col>
                                    </div>
                                    <div className=" col-12 row mb-4">
                                      <Label
                                        htmlFor="horizontal-firstname-Input"
                                        className="col-sm-4 col-form-label"
                                      >
                                        {" "}
                                        Description:
                                      </Label>
                                      <Col sm={8}>
                                        <AvField
                                          name="description"
                                          type="textarea"
                                          value={growebDetails?.description}
                                        />
                                      </Col>
                                    </div>
                                    <div className=" col-12 row mb-4">
                                      <Label
                                        htmlFor="horizontal-firstname-Input"
                                        className="col-sm-4 col-form-label"
                                      >
                                        {" "}
                                        Keywords:
                                      </Label>
                                      <Col sm={8}>
                                        <ReactTags
                                          tags={tags}
                                          placeholder="Type TEXT and enter"
                                          // suggestions={suggestions}
                                          delimiters={delimiters}
                                          handleDelete={handleDeleteTag}
                                          handleAddition={handleAddition}
                                          handleDrag={handleDrag}
                                          handleTagClick={handleTagClick}
                                          // inputFieldPosition="inline"
                                          autocomplete
                                          allowUnique
                                          inline
                                        />
                                      </Col>
                                    </div>
                                  </Col>
                                </Row>
                              </CardText>
                            </Col>
                          </Row>
                        </TabPane>

                        <TabPane tabId={2}>
                          <Row className="mt-0">
                            <Col sm="12" className="mx-5">
                              <Row>
                                <h5>Layout</h5>
                                <Col lg={4}>
                                  <div className="align-items-center d-flex mb-3 mt-3">
                                    <Label check for="checkbox">
                                      {" "}
                                      Bottom Navigation Bar
                                    </Label>
                                    <Switch
                                      uncheckedIcon={<OffSymbol />}
                                      checkedIcon={<OnSymbol />}
                                      className="me-1 mb-sm-8 mx-3"
                                      onColor="#626ed4"
                                      onChange={v => {
                                        setisBottomNavigationBarNeeded(v)
                                      }}
                                      checked={isBottomNavigationBarNeeded}
                                    />
                                  </div>
                                </Col>
                                <Col lg={4}>
                                  <div className="align-items-center d-flex mb-3 mt-3">
                                    <Label check for="checkbox">
                                      {" "}
                                      Footer
                                    </Label>
                                    <Switch
                                      uncheckedIcon={<OffSymbol />}
                                      checkedIcon={<OnSymbol />}
                                      className="me-1 mb-sm-8 mx-3"
                                      onColor="#626ed4"
                                      onChange={v => {
                                        setisFooterNeeded(v)
                                      }}
                                      checked={isFooterNeeded}
                                    />
                                  </div>
                                </Col>
                                <Col lg={4}>
                                  <div className="align-items-center d-flex mb-3 mt-3">
                                    <Label check for="checkbox">
                                      {" "}
                                      Header
                                    </Label>
                                    <Switch
                                      uncheckedIcon={<OffSymbol />}
                                      checkedIcon={<OnSymbol />}
                                      className="me-1 mb-sm-8 mx-3"
                                      onColor="#626ed4"
                                      onChange={v => {
                                        setisHeaderNeeded(v)
                                      }}
                                      checked={isHeaderNeeded}
                                    />
                                  </div>
                                </Col>
                                {isHeaderNeeded && (
                                  <>
                                    <Row>
                                      <Col
                                        className="col-12 mt-3 p-3"
                                        style={{
                                          border: "1px solid #f2f2f2",
                                          borderRadius: "15px",
                                        }}
                                      >
                                        <h5 className="mb-4 text_deals_green">
                                          Header Values
                                        </h5>

                                        {/* Shipping Inputs */}
                                        <div className="col-12 mb-4">
                                          <Row>
                                            <Col md="4" className="m-2 mb-4">
                                              <button
                                                className="mx-2 btn btn-success"
                                                onClick={e => {
                                                  e.preventDefault()
                                                  setHeaderValues(
                                                    currentShippingInput => [
                                                      ...currentShippingInput,
                                                      {
                                                        title: "",
                                                        icon: "",
                                                        link: "",
                                                        iconSVG: [],
                                                      },
                                                    ]
                                                  )
                                                }}
                                              >
                                                ADD{" "}
                                                <i
                                                  className="bx bx-plus"
                                                  style={{
                                                    fontSize: "20px",
                                                  }}
                                                ></i>
                                              </button>
                                            </Col>
                                          </Row>

                                          <Col md="12" className="mx-3">
                                            {/* <div className="mb-2">
                                              <Row>
                                                <Col>
                                                  <h6 className="text_deals_green mb-0">
                                                    Title
                                                  </h6>
                                                </Col>
                                                <Col>
                                                  <h6 className="text_deals_green mb-0">
                                                    Icon
                                                  </h6>
                                                </Col>
                                                <Col>
                                                  <h6 className="text_deals_green mb-0">
                                                    Link
                                                  </h6>
                                                </Col>
                                                <Col></Col>
                                              </Row>
                                            </div> */}
                                            <ReactDragListView {...dragProps}>
                                              {headerValues?.map((s, index) => {
                                                return (
                                                  <tr
                                                    key={index}
                                                    className="d-flex w-100 mb-4"
                                                  >
                                                    <div
                                                      className="mb-4 p-3 w-75 rounded drag-pointer drag-pointer grabbable"
                                                      style={{
                                                        backgroundColor:
                                                          "#d9dbe5",
                                                        position: "relative",
                                                      }}
                                                    >
                                                      <Row>
                                                        <Col>
                                                          <AvField
                                                            type="text"
                                                            name="headerValues.title"
                                                            label="Title"
                                                            value={s?.title}
                                                            placeholder="Title"
                                                            onChange={e => {
                                                              const title =
                                                                e.target.value
                                                              setHeaderValues(
                                                                currentProductInput =>
                                                                  currentProductInput.map(
                                                                    x =>
                                                                      x === s
                                                                        ? {
                                                                            ...x,
                                                                            title,
                                                                          }
                                                                        : x
                                                                  )
                                                              )
                                                            }}
                                                          ></AvField>
                                                        </Col>
                                                        <Col>
                                                          {/* <AvField
                                                          type="text"
                                                          name="headerValues.icon"
                                                          value={s?.icon}
                                                          placeholder="Icon"
                                                          onChange={e => {
                                                            const icon =
                                                              e.target.value
                                                            setHeaderValues(
                                                              currentProductInput =>
                                                                currentProductInput.map(
                                                                  x =>
                                                                    x === s
                                                                      ? {
                                                                          ...x,
                                                                          icon,
                                                                        }
                                                                      : x
                                                                )
                                                            )
                                                          }}
                                                        ></AvField> */}

                                                          <AvField
                                                            type="select"
                                                            label="Icon"
                                                            name="bottomNavigationBarValues.icon"
                                                            value={s?.icon}
                                                            onChange={e => {
                                                              const selectedValue =
                                                                e.target.value
                                                              console.log(
                                                                "Selected value:",
                                                                selectedValue
                                                              )
                                                              setHeaderValues(
                                                                currentProductInput =>
                                                                  currentProductInput.map(
                                                                    x =>
                                                                      x === s
                                                                        ? {
                                                                            ...x,
                                                                            icon: selectedValue,
                                                                          }
                                                                        : x
                                                                  )
                                                              )
                                                            }}
                                                          >
                                                            <option value="">
                                                              Select an option
                                                            </option>
                                                            {dropdownOptionsHeader.map(
                                                              option => (
                                                                <option
                                                                  key={
                                                                    option.value
                                                                  }
                                                                  value={
                                                                    option.value
                                                                  }
                                                                >
                                                                  {option.title}
                                                                </option>
                                                              )
                                                            )}
                                                          </AvField>
                                                        </Col>
                                                        <Col>
                                                          <AvField
                                                            type="text"
                                                            label="Link"
                                                            name="headerValues.link"
                                                            value={s?.link}
                                                            placeholder="Link"
                                                            onChange={e => {
                                                              const link =
                                                                e.target.value
                                                              setHeaderValues(
                                                                currentProductInput =>
                                                                  currentProductInput.map(
                                                                    x =>
                                                                      x === s
                                                                        ? {
                                                                            ...x,
                                                                            link,
                                                                          }
                                                                        : x
                                                                  )
                                                              )
                                                            }}
                                                          ></AvField>
                                                        </Col>

                                                        <button
                                                          style={{
                                                            position:
                                                              "absolute",
                                                            top: 0,
                                                            right: -15,
                                                            width:
                                                              "fit-content",
                                                          }}
                                                          className="mx-2 btn text-danger"
                                                          onClick={e => {
                                                            e.preventDefault()
                                                            setHeaderValues(
                                                              currentShippingInput =>
                                                                currentShippingInput.filter(
                                                                  x => x !== s
                                                                )
                                                            )
                                                          }}
                                                        >
                                                          <i
                                                            className="bx bx-trash"
                                                            style={{
                                                              fontSize: "20px",
                                                            }}
                                                          ></i>
                                                        </button>
                                                      </Row>
                                                      <Col>
                                                        <h6 className="text_deals_green mb-2 mt-2">
                                                          SVG Icon
                                                        </h6>
                                                        <UseImageUploader
                                                          imageSize={4}
                                                          imagesPreview={
                                                            s.iconSVG
                                                          }
                                                          setImagesPreview={selectedValue =>
                                                            setHeaderValues(
                                                              currentProductInput =>
                                                                currentProductInput.map(
                                                                  x =>
                                                                    x === s
                                                                      ? {
                                                                          ...x,
                                                                          iconSVG:
                                                                            selectedValue,
                                                                        }
                                                                      : x
                                                                )
                                                            )
                                                          }
                                                          uploadUrl={
                                                            "/growebosperb"
                                                          }
                                                          isSmall
                                                        />
                                                      </Col>
                                                    </div>
                                                  </tr>
                                                )
                                              })}
                                            </ReactDragListView>
                                          </Col>
                                        </div>
                                        <Col lg={12}>
                                          <div className="d-flex mt-5 color-picker mx-4">
                                            <div className="">
                                              <p>Header Background Color</p>
                                              <ChromePicker
                                                width="180px"
                                                className="me-3"
                                                onChange={color =>
                                                  setHeaderBackgroundColor(
                                                    color
                                                  )
                                                }
                                                color={headerBackgroundColor}
                                              />
                                            </div>
                                            <div>
                                              <p>Header Foreground Color</p>
                                              <ChromePicker
                                                width="180px"
                                                className="me-3"
                                                onChange={color =>
                                                  setHeaderForegroundColor(
                                                    color
                                                  )
                                                }
                                                color={headerForegroundColor}
                                              />
                                            </div>
                                          </div>
                                        </Col>
                                      </Col>
                                    </Row>
                                  </>
                                )}
                                {isFooterNeeded && (
                                  <>
                                    <Row>
                                      <Col
                                        className="col-12 mt-3 p-3"
                                        style={{
                                          border: "1px solid #f2f2f2",
                                          borderRadius: "15px",
                                        }}
                                      >
                                        <h5 className="mb-4 text_deals_green">
                                          Footer Values
                                        </h5>

                                        {/* Product Inputs */}
                                        <div className="col-12 mb-4">
                                          <Row>
                                            <Col md="4" className="m-2 mb-4">
                                              <button
                                                className="mx-2 btn btn-success"
                                                onClick={e => {
                                                  e.preventDefault()
                                                  setBottomValues(
                                                    currentProductInput => [
                                                      ...currentProductInput,
                                                      {
                                                        title: "",
                                                        icon: "",
                                                        link: "",
                                                        iconSVG: [],
                                                      },
                                                    ]
                                                  )
                                                }}
                                              >
                                                ADD{" "}
                                                <i
                                                  className="bx bx-plus"
                                                  style={{
                                                    fontSize: "20px",
                                                  }}
                                                ></i>
                                              </button>
                                            </Col>
                                          </Row>

                                          <Col md="12" className="mx-3">
                                            {/* <div className="mb-2">
                                              <Row>
                                                <Col>
                                                  <h6 className="text_deals_green mb-0">
                                                    Title
                                                  </h6>
                                                </Col>
                                                <Col>
                                                  <h6 className="text_deals_green mb-0">
                                                    Icon
                                                  </h6>
                                                </Col>

                                                <Col>
                                                  <h6 className="text_deals_green mb-0">
                                                    Link
                                                  </h6>
                                                </Col>
                                                <Col></Col>
                                              </Row>
                                            </div> */}

                                            <ReactDragListView {...dragProps2}>
                                              {bottomValues?.map((s, index) => {
                                                return (
                                                  <tr
                                                    key={index}
                                                    className="d-flex w-100 mb-4"
                                                  >
                                                    <div
                                                      className="mb-4 p-3 w-75 rounded drag-pointer grabbable"
                                                      style={{
                                                        backgroundColor:
                                                          "#c5e4e6",
                                                        position: "relative",
                                                      }}
                                                    >
                                                      <Row>
                                                        <Col>
                                                          <AvField
                                                            type="text"
                                                            name="bottomNavigationBarValues.title"
                                                            value={s?.title}
                                                            placeholder="Title"
                                                            onChange={e => {
                                                              const title =
                                                                e.target.value
                                                              setBottomValues(
                                                                currentProductInput =>
                                                                  currentProductInput.map(
                                                                    x =>
                                                                      x === s
                                                                        ? {
                                                                            ...x,
                                                                            title,
                                                                          }
                                                                        : x
                                                                  )
                                                              )
                                                            }}
                                                          ></AvField>
                                                        </Col>
                                                        <Col>
                                                          {/* <AvField
                                                          type="text"
                                                          name="bottomNavigationBarValues.icon"
                                                          value={s?.icon}
                                                          placeholder="Icon"
                                                          onChange={e => {
                                                            const icon =
                                                              e.target.value
                                                            setBottomValues(
                                                              currentProductInput =>
                                                                currentProductInput.map(
                                                                  x =>
                                                                    x === s
                                                                      ? {
                                                                          ...x,
                                                                          icon,
                                                                        }
                                                                      : x
                                                                )
                                                            )
                                                          }}
                                                        ></AvField> */}
                                                          <AvInput
                                                            type="select"
                                                            name="bottomNavigationBarValues.icon"
                                                            value={s?.icon}
                                                            onChange={e => {
                                                              const selectedValue =
                                                                e.target.value

                                                              setBottomValues(
                                                                currentProductInput =>
                                                                  currentProductInput.map(
                                                                    x =>
                                                                      x === s
                                                                        ? {
                                                                            ...x,
                                                                            icon: selectedValue,
                                                                          }
                                                                        : x
                                                                  )
                                                              )
                                                            }}
                                                          >
                                                            <option value="">
                                                              Select an option
                                                            </option>
                                                            {dropdownOptions.map(
                                                              option => (
                                                                <option
                                                                  key={
                                                                    option.value
                                                                  }
                                                                  value={
                                                                    option.value
                                                                  }
                                                                >
                                                                  {option.title}
                                                                </option>
                                                              )
                                                            )}
                                                          </AvInput>
                                                        </Col>
                                                        <Col>
                                                          <AvField
                                                            type="text"
                                                            name="bottomNavigationBarValues.link"
                                                            value={s?.link}
                                                            placeholder="Link"
                                                            onChange={e => {
                                                              const link =
                                                                e.target.value
                                                              setBottomValues(
                                                                currentProductInput =>
                                                                  currentProductInput.map(
                                                                    x =>
                                                                      x === s
                                                                        ? {
                                                                            ...x,
                                                                            link,
                                                                          }
                                                                        : x
                                                                  )
                                                              )
                                                            }}
                                                          ></AvField>
                                                        </Col>
                                                        <Col>
                                                          <button
                                                            className="mx-2 btn"
                                                            onClick={e => {
                                                              e.preventDefault()
                                                              setBottomValues(
                                                                currentProductInput =>
                                                                  currentProductInput.filter(
                                                                    x => x !== s
                                                                  )
                                                              )
                                                            }}
                                                          >
                                                            <i
                                                              className="bx bx-trash"
                                                              style={{
                                                                fontSize:
                                                                  "20px",
                                                              }}
                                                            ></i>
                                                          </button>
                                                        </Col>
                                                      </Row>
                                                      <Col>
                                                        <h6 className="text_deals_green mb-2 mt-2">
                                                          SVG Icon
                                                        </h6>
                                                        <UseImageUploader
                                                          imageSize={4}
                                                          imagesPreview={
                                                            s.iconSVG
                                                          }
                                                          setImagesPreview={selectedValue =>
                                                            setBottomValues(
                                                              currentProductInput =>
                                                                currentProductInput.map(
                                                                  x =>
                                                                    x === s
                                                                      ? {
                                                                          ...x,
                                                                          iconSVG:
                                                                            selectedValue,
                                                                        }
                                                                      : x
                                                                )
                                                            )
                                                          }
                                                          uploadUrl={
                                                            "/growebosperb"
                                                          }
                                                          isSmall
                                                        />
                                                      </Col>
                                                    </div>
                                                  </tr>
                                                )
                                              })}
                                            </ReactDragListView>
                                          </Col>
                                        </div>
                                        <Col lg={12}>
                                          <div className="d-flex mt-5 color-picker mx-4">
                                            <div className="">
                                              <p>Footer Background Color</p>
                                              <ChromePicker
                                                width="180px"
                                                className="me-3"
                                                onChange={color =>
                                                  setFooterBackgroundColor(
                                                    color
                                                  )
                                                }
                                                color={footerBackgroundColor}
                                              />
                                            </div>
                                            <div>
                                              <p>Footer Foreground Color</p>
                                              <ChromePicker
                                                width="180px"
                                                className="me-3"
                                                onChange={color =>
                                                  setFooterForegroundColor(
                                                    color
                                                  )
                                                }
                                                color={footerForegroundColor}
                                              />
                                            </div>
                                          </div>
                                        </Col>
                                      </Col>
                                    </Row>
                                  </>
                                )}
                              </Row>
                            </Col>
                            <Col sm="12" className="mx-5">
                              <div className="mt-4">
                                <h5>Logo</h5>

                                <UseImageUploader
                                  imageSize={4}
                                  imagesPreview={imagesPreview}
                                  setImagesPreview={setImagesPreview}
                                  uploadUrl={"/growebosperb"}
                                  isMultiple
                                  isLogo={true}
                                />
                                <Col sm="12" lg={4} className="mt-3">
                                  <div className="col-12 row mb-2">
                                    <Label
                                      htmlFor="horizontal-firstname-Input"
                                      className="col-sm-4 col-form-label"
                                    >
                                      {" "}
                                      Link :
                                    </Label>
                                    <AvField
                                      name="logoLink"
                                      type="text"
                                      value={growebDetails?.setup?.logoLink}
                                    />
                                  </div>
                                </Col>
                              </div>
                              <Col lg={12}>
                                <div className="d-flex mt-5 color-picker">
                                  <div className="">
                                    <p>Primary Color</p>
                                    <ChromePicker
                                      width="180px"
                                      className="me-3"
                                      onChange={color => setPrimaryColor(color)}
                                      color={primaryColor}
                                    />
                                  </div>
                                  <div>
                                    <p>Secondary Color</p>
                                    <ChromePicker
                                      width="180px"
                                      className="me-3"
                                      onChange={color =>
                                        setSecondaryColor(color)
                                      }
                                      color={secondaryColor}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Col>
                            <Col sm="12">
                              <div className="mt-4 mx-5">
                                <h5>Footer Logo</h5>

                                <UseImageUploader
                                  imageSize={4}
                                  imagesPreview={imagesFooterPreview}
                                  setImagesPreview={setImagesFooterPreview}
                                  uploadUrl={"/growebosperb"}
                                  isMultiple
                                  isLogo={true}
                                />
                              </div>
                            </Col>

                            <hr
                              className="mt-3 "
                              style={{
                                color: "#ccc",
                              }}
                            />
                          </Row>
                        </TabPane>

                        <TabPane tabId={3}>
                          <Row className="mx-5">
                            <Col sm="12">
                              <CardText className="mb-0">
                                <Row>
                                  <div className="col-6 mb-2">
                                    <Label
                                      htmlFor="horizontal-firstname-Input"
                                      className="col-sm-4 col-form-label"
                                    >
                                      Title:
                                    </Label>
                                    <AvField
                                      type="text"
                                      name="leadData.title"
                                      value={
                                        growebDetails?.setup?.leadData?.title
                                      }
                                    />
                                  </div>
                                </Row>
                                <Row>
                                  <div className="col-12 mb-2">
                                    <Label
                                      htmlFor="horizontal-firstname-Input"
                                      className="col-sm-4 col-form-label"
                                    >
                                      Description:
                                    </Label>
                                    <AvField
                                      type="textarea"
                                      name="leadData.description"
                                      value={
                                        growebDetails?.setup?.leadData
                                          ?.description
                                      }
                                      style={{
                                        height: "80px",
                                      }}
                                    />
                                  </div>

                                  <div className="col-12 mb-2 mt-3">
                                    <p>Banner Image</p>
                                    <UseImageUploader
                                      imageSize={4}
                                      imagesPreview={leadBanner}
                                      setImagesPreview={setLeadBanner}
                                      uploadUrl={"/growebosperb"}
                                      isMultiple
                                    />
                                  </div>
                                  <div className="col-12 mb-2">
                                    <Label
                                      htmlFor="horizontal-firstname-Input"
                                      className="col-sm-4 col-form-label"
                                    >
                                      Reply Message:
                                    </Label>
                                    <AvField
                                      type="textarea"
                                      name="leadData.replyMessage"
                                      value={
                                        growebDetails?.setup?.leadData
                                          ?.replyMessage
                                      }
                                      style={{
                                        height: "80px",
                                      }}
                                    />
                                  </div>
                                </Row>
                                <Row>
                                  <Col
                                    className="col-8 p-3 mt-3 mx-3"
                                    style={{
                                      border: "1px solid #f2f2f2",
                                      borderRadius: "15px",
                                    }}
                                  >
                                    <h5 className="my-2 text_deals_green">
                                      Lead Setup
                                    </h5>

                                    <div className="col-12 mb-4">
                                      <Row>
                                        <Col md="4" className="m-2">
                                          <button
                                            className="mx-2 btn btn-success d-flex align-items-center"
                                            onClick={e => {
                                              e.preventDefault()
                                              setLeadInputs(
                                                currentShippingInput => [
                                                  ...currentShippingInput,
                                                  {
                                                    value: "",
                                                    isRequired: false,
                                                  },
                                                ]
                                              )
                                            }}
                                          >
                                            ADD{" "}
                                            <i
                                              className="bx bx-plus mx-1"
                                              style={{ fontSize: "18px" }}
                                            ></i>
                                          </button>
                                        </Col>
                                      </Row>

                                      <Col md="12" className="mx-3">
                                        <ReactDragListView {...dragProps3}>
                                          {leadInputs?.map(
                                            (shipping, index) => {
                                              return (
                                                <tr
                                                  key={index}
                                                  className="d-flex w-100 mb-4"
                                                >
                                                  <div className="drag-pointer grabbable">
                                                    <Row>
                                                      <Col className="col-6">
                                                        <AvField
                                                          type="select"
                                                          name="shipping.name"
                                                          value={shipping.value}
                                                          onChange={e => {
                                                            const value =
                                                              e.target.value
                                                            setLeadInputs(
                                                              currentShippingInput =>
                                                                currentShippingInput.map(
                                                                  x =>
                                                                    x ===
                                                                    shipping
                                                                      ? {
                                                                          ...x,
                                                                          value,
                                                                        }
                                                                      : x
                                                                )
                                                            )
                                                          }}

                                                          // helpMessage="Select a Shipping Input"
                                                        >
                                                          <option>
                                                            Select a Lead Input
                                                          </option>
                                                          {shippingInputData?.map(
                                                            (item, key) => (
                                                              <option
                                                                key={key}
                                                                value={item}
                                                              >
                                                                {item}
                                                              </option>
                                                            )
                                                          )}
                                                        </AvField>
                                                      </Col>

                                                      <Col>
                                                        <Switch
                                                          uncheckedIcon={
                                                            <Offsymbol1 />
                                                          }
                                                          checkedIcon={
                                                            <OnSymbol1 />
                                                          }
                                                          className="me-1 mb-sm-8 mb-2"
                                                          onColor="#626ed4"
                                                          height={30} // Set the height of the Switch
                                                          width={80}
                                                          onChange={e => {
                                                            setLeadInputs(
                                                              currentShippingInput =>
                                                                currentShippingInput.map(
                                                                  x =>
                                                                    x ===
                                                                    shipping
                                                                      ? {
                                                                          ...x,
                                                                          isRequired:
                                                                            e,
                                                                        }
                                                                      : x
                                                                )
                                                            )
                                                          }}
                                                          checked={
                                                            shipping?.isRequired
                                                          }
                                                        />
                                                      </Col>

                                                      <Col>
                                                        <button
                                                          className="mx-2 btn"
                                                          onClick={e => {
                                                            e.preventDefault()
                                                            setLeadInputs(
                                                              currentShippingInput =>
                                                                currentShippingInput.filter(
                                                                  x =>
                                                                    x !==
                                                                    shipping
                                                                )
                                                            )
                                                          }}
                                                        >
                                                          <i
                                                            className="bx bx-trash text-danger"
                                                            style={{
                                                              fontSize: "20px",
                                                            }}
                                                          ></i>
                                                        </button>
                                                      </Col>
                                                    </Row>
                                                  </div>
                                                </tr>
                                              )
                                            }
                                          )}
                                        </ReactDragListView>
                                      </Col>
                                    </div>
                                  </Col>
                                </Row>
                              </CardText>
                            </Col>
                          </Row>
                        </TabPane>

                        <TabPane tabId={4}>
                          <Row className="mt-0">
                            <Col sm="12">
                              <CardText className="mb-0">
                                <Row>
                                  {/* Basic Data */}
                                  <Col className="col-6 d-flex flex-wrap">
                                    <h5 className="col-12 text_deals_green">
                                      Basic Data
                                    </h5>
                                    <p className="mb-4">
                                      Fill the basic company details.
                                    </p>
                                    <div className=" col-12 row mb-2">
                                      <Label
                                        htmlFor="horizontal-firstname-Input"
                                        className="col-sm-4 col-form-label"
                                      >
                                        {" "}
                                        Company Name :
                                      </Label>
                                      <Col sm={8}>
                                        <AvField
                                          name="company.name"
                                          type="text"
                                          value={growebDetails?.company?.name}
                                        />
                                      </Col>
                                    </div>
                                    <div className=" col-12 row mb-2">
                                      <Label
                                        htmlFor="horizontal-firstname-Input"
                                        className="col-sm-4 col-form-label"
                                      >
                                        {" "}
                                        Mobile Number :
                                      </Label>
                                      <Col sm={8}>
                                        <AvField
                                          name="contactDetails.phone"
                                          type="tel"
                                          pattern="[0-9]*"
                                          value={
                                            growebDetails?.company
                                              ?.contactDetails?.phone
                                          }
                                        />
                                      </Col>
                                    </div>

                                    <div className=" col-12 row mb-2">
                                      <Label
                                        htmlFor="horizontal-firstname-Input"
                                        className="col-sm-4 col-form-label"
                                      >
                                        {" "}
                                        Email :
                                      </Label>
                                      <Col sm={8}>
                                        <AvField
                                          name="contactDetails.email"
                                          type="email"
                                          value={
                                            growebDetails?.company
                                              ?.contactDetails?.email
                                          }
                                        />
                                      </Col>
                                    </div>

                                    <div className=" col-12 row mb-2">
                                      <Label
                                        htmlFor="horizontal-firstname-Input"
                                        className="col-sm-4 col-form-label"
                                      >
                                        {" "}
                                        Website :
                                      </Label>
                                      <Col sm={8}>
                                        <AvField
                                          name="contactDetails.website"
                                          type="text"
                                          value={
                                            growebDetails?.company
                                              ?.contactDetails?.website
                                          }
                                        />
                                      </Col>
                                    </div>
                                  </Col>
                                  {/* Address */}
                                  <Col className="col-6 d-flex flex-wrap">
                                    <h5 className="col-12 text_deals_green">
                                      Address
                                    </h5>
                                    <p className="mb-4">
                                      Fill the company address.
                                    </p>
                                    <div className=" col-12 row mb-4">
                                      <Label
                                        htmlFor="horizontal-firstname-Input"
                                        className="col-sm-4 col-form-label"
                                      >
                                        {" "}
                                        Address Line 1 :
                                      </Label>
                                      <Col sm={8}>
                                        <AvField
                                          name="addressLine1"
                                          type="text"
                                          value={
                                            growebDetails?.company?.address
                                              ?.addressLine1
                                          }
                                        />
                                      </Col>
                                    </div>

                                    <div className=" col-12 row mb-4">
                                      <Label
                                        htmlFor="horizontal-firstname-Input"
                                        className="col-sm-4 col-form-label"
                                      >
                                        {" "}
                                        Address Line 2 :
                                      </Label>
                                      <Col sm={8}>
                                        <AvField
                                          name="addressLine2"
                                          type="text"
                                          value={
                                            growebDetails?.company?.address
                                              ?.addressLine2
                                          }
                                        />
                                      </Col>
                                    </div>

                                    <div className=" col-12 row mb-4">
                                      <Label
                                        htmlFor="horizontal-firstname-Input"
                                        className="col-sm-4 col-form-label"
                                      >
                                        {" "}
                                        District :
                                      </Label>
                                      <Col sm={8}>
                                        <AvField
                                          name="district"
                                          type="text"
                                          value={
                                            growebDetails?.company?.address
                                              ?.district
                                          }
                                        />
                                      </Col>
                                    </div>

                                    <div className=" col-12 row mb-4">
                                      <Label
                                        htmlFor="horizontal-firstname-Input"
                                        className="col-sm-4 col-form-label"
                                      >
                                        {" "}
                                        State :
                                      </Label>
                                      <Col sm={8}>
                                        <AvField
                                          name="state"
                                          type="text"
                                          value={
                                            growebDetails?.company?.address
                                              ?.state
                                          }
                                        />
                                      </Col>
                                    </div>

                                    <div className=" col-12 row mb-4">
                                      <Label
                                        htmlFor="horizontal-firstname-Input"
                                        className="col-sm-4 col-form-label"
                                      >
                                        {" "}
                                        Country :
                                      </Label>
                                      <Col sm={8}>
                                        <AvField
                                          name="country"
                                          type="text"
                                          value={
                                            growebDetails?.company?.address
                                              ?.country
                                          }
                                        />
                                      </Col>
                                    </div>

                                    <div className=" col-12 row mb-4">
                                      <Label
                                        htmlFor="horizontal-firstname-Input"
                                        className="col-sm-4 col-form-label"
                                      >
                                        {" "}
                                        Pin Code :
                                      </Label>
                                      <Col sm={8}>
                                        <AvField
                                          name="pinCode"
                                          type="number"
                                          value={
                                            growebDetails?.company?.address
                                              ?.pinCode
                                          }
                                        />
                                      </Col>
                                    </div>
                                    {/* <div className=" col-12 row mb-4">
                                      <div className="col-12  mb-3">
                                        <Label
                                          htmlFor="lbltype"
                                          className="form-label mb-3"
                                        >
                                          Pickup Locations:
                                        </Label>
                                        <ReactTags
                                          tags={tags}
                                          placeholder="Type Location and enter"
                                          // suggestions={suggestions}
                                          delimiters={delimiters}
                                          handleDelete={handleDeleteTag}
                                          handleAddition={handleAddition}
                                          handleDrag={handleDrag}
                                          handleTagClick={handleTagClick}
                                          // inputFieldPosition="inline"
                                          autocomplete
                                          allowUnique
                                          inline
                                        />
                                      </div>
                                    </div> */}
                                  </Col>
                                </Row>
                              </CardText>
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId={5}>
                          <Row className="mt-0">
                            {/* Social Links */}
                            <hr className="mt-4" />
                            <h5 className="mt-3 text_deals_green">
                              Social Media
                            </h5>
                            <p>
                              Enter the links to social media profiles (url)
                            </p>
                            <Col className="col-12 mt-3 d-flex flex-wrap">
                              <div className="col-3 mb-3 mx-3">
                                <AvField
                                  name="socialLinks.facebook"
                                  label="Facebook"
                                  type="text"
                                  value={
                                    growebDetails?.company?.socialLinks
                                      ?.facebook
                                  }
                                />
                              </div>
                              <div className="col-3 mb-3 mx-3">
                                <AvField
                                  name="socialLinks.instagram"
                                  label="Instagram"
                                  type="text"
                                  value={
                                    growebDetails?.company?.socialLinks
                                      ?.instagram
                                  }
                                />
                              </div>
                              <div className="col-3 mb-3 mx-3">
                                <AvField
                                  name="socialLinks.twitter"
                                  label="Twitter"
                                  type="text"
                                  value={
                                    growebDetails?.company?.socialLinks?.twitter
                                  }
                                />
                              </div>
                              <div className="col-3 mb-3 mx-3">
                                <AvField
                                  name="socialLinks.linkedin"
                                  label="Linkedin"
                                  type="text"
                                  value={
                                    growebDetails?.company?.socialLinks
                                      ?.linkedin
                                  }
                                />
                              </div>

                              <div className="col-3 mb-3 mx-3">
                                <AvField
                                  name="socialLinks.whatsApp"
                                  label="WhatsApp"
                                  type="text"
                                  value={
                                    growebDetails?.company?.socialLinks
                                      ?.whatsApp
                                  }
                                />
                              </div>
                              <div className="col-3 mb-3 mx-3">
                                <AvField
                                  name="socialLinks.snapchat"
                                  label="Snapchat"
                                  type="text"
                                  value={
                                    growebDetails?.company?.socialLinks
                                      ?.snapchat
                                  }
                                />
                              </div>
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId={6}>
                          <Row>
                            <Col sm="12">
                              <div className=" col-12 row mb-4">
                                <Label
                                  htmlFor="horizontal-firstname-Input"
                                  className="col-sm-2 col-form-label"
                                >
                                  {" "}
                                  Establishment Name :
                                </Label>
                                <Col sm={10}>
                                  <AvField
                                    name="establishmentName"
                                    type="text"
                                    value={
                                      growebDetails?.legal?.establishmentName
                                    }
                                  />
                                </Col>
                              </div>
                            </Col>
                            <Col sm="12" className="mt-4">
                              <h5 className=" mb-4 text_deals_green">
                                Terms & Conditions :
                              </h5>
                              {!loading &&
                              growebDetails &&
                              growebDetails?.legal &&
                              growebDetails?.legal?.terms &&
                              growebDetails?.legal?.terms != undefined ? (
                                <FormEditors
                                  content={growebDetails?.legal?.terms}
                                  setContent={setTerms}
                                />
                              ) : (
                                <></>
                              )}
                              {(!growebDetails?._id ||
                                !growebDetails?.legal?.terms) && (
                                <FormEditors
                                  content={terms}
                                  setContent={setTerms}
                                />
                              )}
                            </Col>

                            <Col sm="12" className="mt-4">
                              <h5 className="mb-4 text_deals_green">
                                Privacy Policy :
                              </h5>

                              {!loading &&
                              growebDetails &&
                              growebDetails?.legal &&
                              growebDetails?.legal?.privacyPolicy &&
                              growebDetails?.legal?.privacyPolicy !=
                                undefined ? (
                                <FormEditors
                                  content={growebDetails?.legal?.privacyPolicy}
                                  setContent={setPrivacyPolicy}
                                />
                              ) : (
                                <></>
                              )}

                              {(!growebDetails?._id ||
                                !growebDetails?.legal?.privacyPolicy) && (
                                <FormEditors
                                  content={privacyPolicy}
                                  setContent={setPrivacyPolicy}
                                />
                              )}
                            </Col>

                            <Col sm="12" className="mt-4">
                              <h5 className="mb-4 text_deals_green">
                                Return Policy :
                              </h5>
                              {!loading &&
                              growebDetails &&
                              growebDetails?.legal &&
                              growebDetails?.legal?.returnPolicy &&
                              growebDetails?.legal?.returnPolicy !=
                                undefined ? (
                                <FormEditors
                                  content={growebDetails?.legal?.returnPolicy}
                                  setContent={setReturnPolicy}
                                />
                              ) : (
                                <></>
                              )}
                              {(!growebDetails?._id ||
                                !growebDetails?.legal?.returnPolicy) && (
                                <FormEditors
                                  content={returnPolicy}
                                  setContent={setReturnPolicy}
                                />
                              )}
                            </Col>
                          </Row>
                        </TabPane>
                      </TabContent>
                    </div>

                    <div className="actions clearfix navigation-btn mt-4 me-4">
                      <ul>
                        {/* {activeTabVartical === 6 && (
                              <>
                                <li
                                  className={"previous"}
                                  onClick={() => {
                                    toggleTabVertical(1)
                                  }}
                                >
                                  <span>Add New Order</span>
                                </li>
                                <li
                                  className={"previous bg-success text-white"}
                                  onClick={() => history.push("/orders")}
                                >
                                  <span>All Orders</span>
                                </li>
                              </>
                            )} */}

                        {!isUpdate && activeTabVartical !== 7 && (
                          <li
                            className={
                              activeTabVartical === 1
                                ? "previous disabled"
                                : "previous"
                            }
                            onClick={() => {
                              toggleTabVertical(activeTabVartical - 1)
                            }}
                          >
                            <span>Previous</span>
                          </li>
                        )}

                        {!isUpdate && activeTabVartical === 6 && (
                          <button
                            type="submit"
                            className="btn btn-success save-customer"
                            style={{ minWidth: "200px" }}
                          >
                            {loading && (
                              <>
                                <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                              </>
                            )}
                            Submit
                          </button>
                        )}

                        {!isUpdate && activeTabVartical < 6 && (
                          <>
                            {activeTabVartical === 1 ? (
                              <>
                                {isEmpty(clientSelect?.id) ? (
                                  <li className={"next disabled"}>
                                    <span>Next</span>
                                  </li>
                                ) : (
                                  <li
                                    className={"next"}
                                    onClick={() => {
                                      toggleTabVertical(activeTabVartical + 1)
                                    }}
                                  >
                                    <span>Next</span>
                                  </li>
                                )}
                              </>
                            ) : (
                              <>
                                {/* {isEmpty(clientSelect?.id) ? (
                                      <li className={"next disabled"}>
                                        <span>Next</span>
                                      </li>
                                    ) : ( */}
                                <li
                                  className={"next"}
                                  onClick={() => {
                                    toggleTabVertical(activeTabVartical + 1)
                                  }}
                                >
                                  <span>Next</span>
                                </li>
                                {/* )} */}
                              </>
                            )}
                          </>
                        )}
                        {isUpdate && (
                          <button
                            type="submit"
                            className="btn btn-success save-customer"
                            style={{ minWidth: "200px" }}
                          >
                            {loading && (
                              <>
                                <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                              </>
                            )}
                            Save
                          </button>
                        )}
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
            </AvForm>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

GrowebCreateForm.propTypes = {
  isUpdate: PropTypes.bool,
  growebDetails: PropTypes.any,
  setCustomActiveTab: PropTypes.any,
}

export default GrowebCreateForm
