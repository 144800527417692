import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"
import productSaga from "./products/saga"
import userSaga from "./users/saga"
import bannerSaga from "./banner/saga"
import dashboardSaga from "./dashboard/saga"
// import areaSaga from "./area_list/saga"
// import pagesSaga from "./productPages/saga"
import blogSaga from "./blogs/saga"

import ClientSaga from "./client/saga"

import GroWebSaga from "./groweb/saga"

import GrowPageSaga from "./growpage/saga"

// import SectionSaga from "./section/saga"

import LeadSaga from "./lead/saga"

import PageViewSaga from "./pageview/saga"

export default function* rootSaga() {
  yield all([
    fork(PageViewSaga),
    fork(LeadSaga),
    // fork(SectionSaga),
    fork(GrowPageSaga),
    fork(GroWebSaga),
    fork(ClientSaga),
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(productSaga),

    fork(bannerSaga),
    // fork(pagesSaga),
    // fork(areaSaga),

    fork(userSaga),
    fork(dashboardSaga),
    fork(blogSaga),
  ])
}
