import {
  GET_BLOGS,
  GET_BLOGS_FAIL,
  GET_BLOGS_SUCCESS,
  GET_BLOG_DETAILS,
  GET_BLOG_DETAILS_SUCCESS,
  GET_BLOG_DETAILS_FAIL,
  CREATE_BLOG_SUCCESS,
  CREATE_BLOG_FAIL,
  UPDATE_BLOG_SUCCESS,
  UPDATE_BLOG,
  UPDATE_BLOG_FAIL,
  DELETE_BLOG_SUCCESS,
  DELETE_BLOG_FAIL,
  CREATE_BLOG,
} from "./actionTypes"

const INIT_STATE = {
  blogs: [],
  blogDetails: {},
  error: {},
  loading: false,
}

const Blogs = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_BLOGS:
    case GET_BLOG_DETAILS:
    // case UPDATE_BLOG:
    case CREATE_BLOG:
      return {
        ...state,
        loading: true,
      }
    case GET_BLOGS_SUCCESS:
      return {
        ...state,
        blogs: action.payload,
        error: {},
        loading: false,
      }

    case GET_BLOGS_FAIL:
    case UPDATE_BLOG_FAIL:
    case DELETE_BLOG_FAIL:
    case CREATE_BLOG_FAIL:
    case GET_BLOG_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_BLOG_DETAILS_SUCCESS:
      return {
        ...state,
        blogDetails: action.payload,
        error: {},
        loading: false,
      }

    case CREATE_BLOG_SUCCESS:
      return {
        ...state,
        blogs: {
          ...state.blogs,
          blogs: [...state.blogs?.blogs, action.payload],
        },
        error: {},
        loading: false,
      }

    case UPDATE_BLOG_SUCCESS:
      return {
        ...state,
        blogs: {
          ...state.blogs,
          blogs: state.blogs?.blogs?.map(blog =>
            blog._id.toString() === action.payload._id.toString()
              ? { ...blog, ...action.payload }
              : blog
          ),
        },
        error: {},
        loading: false,
      }

    case DELETE_BLOG_SUCCESS:
      return {
        ...state,
        blogs: {
          ...state.blogs,
          blogs: state.blogs?.blogs?.filter(
            blog => blog._id.toString() !== action.payload._id.toString()
          ),
        },
        error: {},
        loading: false,
      }

    default:
      return state
  }
}

export default Blogs
