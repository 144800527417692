import React, { Fragment, useEffect, useState } from "react"
import { Row, Col, Button, Spinner, Badge } from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import { debounce, map, range } from "lodash"

import ToolkitProvider from "react-bootstrap-table2-toolkit"

//actions
import { deleteGrowPage, getGrowPages } from "store/actions"

import "../../assets/scss/datatables.scss"

import MyPagination from "components/Common/MyPagination"
import DeleteModal from "components/Common/DeleteModal"
import moment from "moment"
import ClientModal from "./Crud/Modal"
import { defaultImg } from "assets/images"

const GrowPages = ({ growebId }) => {
  const dispatch = useDispatch()

  const [sort, setSort] = useState("all")
  const limit = 10

  const [page, setPage] = useState(1)
  const [clientModal, setClientModal] = useState({ status: false, data: {} })

  const { loading, growPages } = useSelector(state => ({
    loading: state.GrowPage.loading,
    growPages: state.GrowPage.growpages,
  }))

  const totalPages = Math.ceil(growPages?.total / limit)
  const pages = range(1, totalPages + 1)
  console.log(growPages)
  console.log(growebId)
  useEffect(() => {
    dispatch(getGrowPages(page, sort, limit, growebId, ""))
  }, [dispatch, page, sort, limit, growebId])

  const [isOpen, setIsOpen] = useState({ status: false, id: "" })

  const handleDelete = id => {
    setIsOpen({ status: true, id: id })
  }

  const handleDeleteEvent = () => {
    setIsOpen({ ...isOpen, status: false })
    dispatch(deleteGrowPage(isOpen?.id))
  }

  const columns = [
    {
      dataField: "title",
      text: "Title",
      sort: true,
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
    },
    {
      dataField: "createdAt",
      text: "Created At",
    },
    {
      dataField: "imageData",
      text: "Image",
      sort: true,
    },
    {
      dataField: "action",
      text: "Actions",
    },
  ]

  const tableData = map(growPages?.growpages, (item, index) => ({
    ...item,

    message: (
      <div style={{ whiteSpace: "break-spaces" }}>
        {item?.message?.slice(0, 110)}
        {item?.message?.length > 110 ? "..." : ""}
      </div>
    ),
    createdAt: moment(item.createdAt).format("DD/MM/YYYY - h:mm a"),
    imageData: (
      <div>
        <img
          src={
            item?.images && item?.images[0] ? item?.images[0]?.url : defaultImg
          }
          width="50px"
        />
      </div>
    ),
    action: (
      <div>
        <Link to={`/pages/product/${item?._id}`} className="btn-md me-3">
          <i
            className="mdi mdi-eye"
            style={{ color: "green", fontSize: "20px" }}
          />
        </Link>
        <Button
          id={`Delete${index}`}
          type="button"
          color="white"
          className="ms-1 btn "
          onClick={() => {
            handleDelete(item._id)
          }}
        >
          <i
            className="bx bx-trash me-2"
            style={{ color: "red", marginBottom: "10px", fontSize: "1.3rem" }}
          />
        </Button>
      </div>
    ),
  }))

  const tableTitles = [
    {
      title: "All",
      value: "all",
    },
    {
      title: "Home",
      value: "home",
    },
    {
      title: "Active",
      value: "active",
    },

    {
      title: "Inactive",
      value: "inactive",
    },

    {
      title: "Deleted",
      value: "deleted",
    },
  ]

  return (
    <React.Fragment>
      <Row>
        <DeleteModal
          show={isOpen?.status}
          onCloseClick={() => setIsOpen({ ...isOpen, status: false })}
          onDeleteClick={handleDeleteEvent}
        />
        <ClientModal
          onCloseClick={() => setClientModal({ status: false, data: {} })}
          show={clientModal?.status}
          updateData={clientModal?.data}
        />
        <Col className="col-12">
          <Row>
            <div className="d-flex align-items-center justify-content-between p-1">
              <div
                className="btn-group mt-2 mt-xl-0"
                role="group"
                aria-label="Basic radio toggle button group"
              >
                {map(tableTitles, (head, key) => (
                  <Fragment key={key}>
                    <input
                      type="radio"
                      className="btn-check"
                      name="btnradio"
                      id={head.value + key}
                      autoComplete="off"
                      checked={sort === head.value}
                      onChange={() => setSort(head?.value || "all")}
                    />
                    <label
                      className="btn btn-success d-flex align-items-center w-100"
                      htmlFor={head.value + key}
                      style={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      {head?.title}

                      <Badge
                        className={`font-size-12 ms-2 ${
                          sort === head.value
                            ? "badge-soft-warning"
                            : "badge-soft-light"
                        }`}
                        pill
                      >
                        {head?.count}
                      </Badge>
                    </label>
                  </Fragment>
                ))}
              </div>

              <div className="mt-3 text-sm-end">
                {" "}
                {/* Change the alignment class to 'text-sm-start' */}
                <Button
                  type="button"
                  color="success"
                  className="btn-rounded  mb-2 me-2"
                  onClick={() => setClientModal({ status: true })}
                >
                  <i className="mdi mdi-plus me-1" />
                  Create Grow Page
                </Button>
              </div>
            </div>
          </Row>
          <ToolkitProvider
            keyField="_id"
            key={"id"}
            columns={columns}
            data={tableData}
            search
          >
            {toolkitProps => (
              <React.Fragment>
                <Row className="mb-2 justify-content-end"></Row>
                {loading ? (
                  <Spinner color="secondary" className="d-block m-auto" />
                ) : (
                  <>
                    {growPages?.total >= 1 ? (
                      <>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField={"id"}
                                key={"id"}
                                responsive
                                bordered={false}
                                striped={false}
                                // selectRow={selectRow}
                                classes={"table align-middle table-nowrap"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                                // onTableChange={handleTableChange}
                                // {...paginationTableProps}
                              />
                            </div>
                          </Col>
                        </Row>

                        <MyPagination
                          totalPages={pages}
                          page={page}
                          setPage={setPage}
                        />
                      </>
                    ) : (
                      <p className="my-4 mx-3 ">No Grow Pages !</p>
                    )}
                  </>
                )}
              </React.Fragment>
            )}
          </ToolkitProvider>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default GrowPages

GrowPages.propTypes = {
  growebId: PropTypes.object,
}
