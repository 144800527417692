import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_GROWEBS,
  GET_GROWEB_DETAILS,
  CREATE_GROWEB,
  UPDATE_GROWEB,
  DELETE_GROWEB,
  GET_GROWEB_DASHBOARD_DATA,
} from "./actionTypes"
import {
  getGroWebsFail,
  getGroWebsSuccess,
  getGroWebDetailsSuccess,
  getGroWebDetailsFail,
  createGroWebFail,
  createGroWebSuccess,
  updateGroWebSuccess,
  updateGroWebFail,
  deleteGroWebSuccess,
  deleteGroWebFail,
  getGrowebDashboardDataSuccess,
  getGrowebDashboardDataFail,
} from "./actions"

import { get, post, ApiPut, del } from "helpers/api_helper"
import { Notification } from "../../components/Common/Notification"

// GroWeb - This line cannot be edited or removed
function getGroWebsAPi({ page, sort, limit, client, searchText }) {
  return get(
    `/groweb/admin/all/?sort=${sort}&page=${page ? page : 1}&limit=${
      limit ? limit : 10
    }&client=${client ? client : ""}&searchText=${searchText ? searchText : ""}`
  )
}

const getGroWebDetailsAPi = growebId => {
  return get(`/groweb/admin/single/${growebId}`)
}

const createGroWebApi = ({ groweb }) => {
  return post("/groweb/admin/new", groweb)
}

const updateGroWebApi = ({ groweb, growebId }) => {
  return ApiPut(`/groweb/admin/${growebId}`, groweb)
}

const deleteGroWebApi = ({ growebId }) => {
  return del(`/groweb/admin/${growebId}`)
}
function getGrowebDashboardDataAPi({ growebId }) {
  return get(`/groweb/admin/count/${growebId}`)
}

function* fetchGroWebs({ payload }) {
  try {
    const response = yield call(getGroWebsAPi, payload)
    yield put(getGroWebsSuccess(response))
  } catch (error) {
    yield put(getGroWebsFail(error))
  }
}

function* fetchGroWebDetails({ payload: growebId }) {
  try {
    const response = yield call(getGroWebDetailsAPi, growebId)
    yield put(getGroWebDetailsSuccess(response))
  } catch (error) {
    yield put(getGroWebDetailsFail(error))
  }
}

function* onCreateGroWeb({ payload }) {
  try {
    const response = yield call(createGroWebApi, payload)
    if (response?.message) {
      Notification({
        type: "error",
        message: response?.message,
        title: "",
      })
    } else if (response) {
      yield put(createGroWebSuccess(response))
      payload?.history?.push("/groWeb")
      doneNotification("GroWeb Created Successfully!")
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: "error",
        message: "file size is Too Large",
        title: "",
      })
    }
    yield put(createGroWebFail(error))
  }
}

function* onUpdateGroWeb({ payload }) {
  try {
    const response = yield call(updateGroWebApi, payload)
    if (response) {
      Notification({
        type: "success",
        message: "GroWeb Updated Successfully!",
        title: "",
      })
      yield put(updateGroWebSuccess(response))
      payload.setCustomActiveTab("1")
      // if (payload.history) {
      //   payload?.history?.push("/groWeb")
      // }
      // history.goBack()
    }
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: "error",
        message: "file size is Too Large",
        title: "",
      })
    }
    yield put(updateGroWebFail(error.response.data))
  }
}

function* onDeleteGroWeb({ payload }) {
  try {
    const response = yield call(deleteGroWebApi, payload)
    yield put(deleteGroWebSuccess(response))
    Notification({
      type: "success",
      message: "GroWeb Deleted Successfully!",
      title: "",
    })
    history.goBack()
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    yield put(deleteGroWebFail(error?.response?.data))
  }
}
function* fetchGrowebDashboardData({ payload }) {
  try {
    const response = yield call(getGrowebDashboardDataAPi, payload)
    yield put(getGrowebDashboardDataSuccess(response))
  } catch (error) {
    yield put(getGrowebDashboardDataFail(error))
  }
}

function* growebSaga() {
  yield takeEvery(GET_GROWEBS, fetchGroWebs)
  yield takeEvery(GET_GROWEB_DETAILS, fetchGroWebDetails)
  yield takeEvery(CREATE_GROWEB, onCreateGroWeb)
  yield takeEvery(UPDATE_GROWEB, onUpdateGroWeb)
  yield takeEvery(DELETE_GROWEB, onDeleteGroWeb)
  yield takeEvery(GET_GROWEB_DASHBOARD_DATA, fetchGrowebDashboardData)
}

export default growebSaga

function doneNotification(message) {
  Notification({
    type: "success",
    message: message,
    title: "",
  })
}
