export const LanguageSwitch = () => {
  const multiLanguange = localStorage?.getItem("multiLang")

  if (multiLanguange?.isMultiLanguage) {
    return {
      isMult: true,
      lang1: multiLanguange?.primaryLanguage,
      lang2: multiLanguange?.secondaryLanguage,
    }
  } else {
    return {
      isMult: false,
      lang1: multiLanguange?.primaryLanguage,
    }
  }
}
