import React, { useEffect } from "react"
import { Card, CardBody, Container } from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import DataTables from "../Groweb/DatatableTables"
import MetaTag from "components/Common/Meta-tag"
import { getClientDetails } from "../../store/actions"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"

function ClientView() {
  const dispatch = useDispatch()
  const params = useParams()

  const { clientDetail } = useSelector(state => ({
    clientDetail: state.Client.clientDetails,
  }))

  useEffect(() => {
    dispatch(getClientDetails(params?.id))
  }, [])

  return (
    <>
      <MetaTag title={"Client Detail"} />

      <div className="page-content">
        <Breadcrumbs title="Clients" breadcrumbItem="Client Details" />
        <Container fluid>
          <div className="container-fluid">
            <div className="col-4">
              <Card>
                <CardBody>
                  <h4>{clientDetail?.name}</h4>
                  <p className="d-flex align-items-center m-0 pb-2 ">
                    <i className="bx bx-phone me-1 text-primary" />
                    <span>{clientDetail?.phone}</span>
                  </p>
                  <p className="d-flex align-items-center m-0">
                    <i className="bx bxl-whatsapp me-1 text-success" />
                    <span>
                      {clientDetail?.countryCode}-{clientDetail?.whatsappNumber}
                    </span>
                  </p>
                </CardBody>
              </Card>
            </div>
            <p className="m-0 pb-2 fs-5">Growebs :</p>
            <DataTables clientDetail={clientDetail} />
          </div>
        </Container>
      </div>
    </>
  )
}

export default ClientView
