import React from "react"
import { Card, CardBody, CardTitle, Container } from "reactstrap"

import Breadcrumbs from "components/Common/Breadcrumb"
import MetaTag from "components/Common/Meta-tag"
import GrowebCreateForm from "./Details"
import PropTypes from "prop-types"

function GrowebCreate() {
  return (
    <>
      <MetaTag title={" Groweb"} />

      <div className="page-content">
        <Breadcrumbs title="Dashboard" breadcrumbItem="Create Groweb" />
        <Container fluid>
          <div className="container-fluid">
            <Card>
              <CardBody>
                <CardTitle>Create Groweb</CardTitle>
                <GrowebCreateForm />
              </CardBody>
            </Card>
          </div>
        </Container>
      </div>
    </>
  )
}

export default GrowebCreate

GrowebCreate.propTypes = {
  isUpdate: PropTypes.bool,
}
