import React from "react"
import DataTables from "./DataTables"
import MetaTag from "components/Common/Meta-tag"

function Leads() {
  return (
    <>
      <MetaTag title={"All Leads"} />
      <DataTables />

      {/* <div className="page-content">
        <Breadcrumbs title="Dashboard" breadcrumbItem="All Leads" />
        <Container fluid>
          <div className="container-fluid">
            <DataTables />
          </div>
        </Container>
      </div> */}
    </>
  )
}

export default Leads
