import Breadcrumbs from "../../components/Common/Breadcrumb"
import DataTables from "./DataTables"
import MetaTag from "components/Common/Meta-tag"
import React, { useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import "bootstrap/dist/css/bootstrap.min.css"
import GroWeb from "pages/Groweb"
import DatatableTables from "../Product/DatatableTables"

function ActiveTabs() {
  const [activeTab, setActiveTab] = useState("tab1")

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  return (
    <>
      <MetaTag title={"All Tabs"} />

      <div className="page-content">
        <Breadcrumbs title="Dashboard" breadcrumbItem="All Tabs" />
        <Container fluid>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={activeTab === "tab1" ? "active" : ""}
                onClick={() => toggleTab("tab1")}
              >
                GroPages
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === "tab2" ? "active" : ""}
                onClick={() => toggleTab("tab2")}
              >
                Product
              </NavLink>
            </NavItem>
            {/* Add more NavItems for additional tabs if needed */}
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="tab1">
              <Row>
                <Col sm="12">
                  <Card>
                    <CardBody>
                      {/* Content for Tab 1 */}
                      <DataTables /> {/* <GroWeb/> */}
                      {/* Assuming DataTables component is the content for Tab 1 */}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="tab2">
              <Row>
                <Col sm="12">
                  <Card>
                    <CardBody>
                      {/* Content for Tab 2 */}
                      <DatatableTables />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </TabPane>
            {/* Add more TabPanes for additional tabs if needed */}
          </TabContent>
        </Container>
      </div>
    </>
  )
}

export default ActiveTabs
