import React, { useEffect, useState } from "react"
import { Row, Col, Spinner, FormGroup } from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import Select from "react-select"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import { debounce, map, range } from "lodash"

import ToolkitProvider from "react-bootstrap-table2-toolkit"

//actions
import { deleteClient, getGroWebs, getPageViews } from "store/actions"

import "../../assets/scss/datatables.scss"

import MyPagination from "components/Common/MyPagination"
import DeleteModal from "components/Common/DeleteModal"
import moment from "moment"
import PropTypes from "prop-types"

const PageViews = ({ growebDetails }) => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)

  const { loading, Clients, pageviews, GroWeb } = useSelector(state => ({
    loading: state.PageView.loading,
    pageviews: state.PageView.pageviews,
    Clients: state.Client.clients,
    GroWeb: state.GroWeb.growebs,
  }))
  const [groWebSelect, setGroWebSelect] = useState({
    title: "Select Groweb",
    id: "",
  })
  const [groWebSearch, setGroWebSearch] = useState("")

  const totalPages = Math.ceil(Clients?.total / 10)
  const pages = range(1, totalPages + 1)

  useEffect(() => {
    dispatch(
      getPageViews(page, "", 10, "", groWebSelect?.id || growebDetails?._id)
    )
  }, [dispatch, page, groWebSelect, growebDetails])

  useEffect(() => {
    if (!growebDetails?._id) {
      dispatch(getGroWebs("", "", "", "", groWebSearch))
    }
  }, [dispatch, groWebSearch, growebDetails])

  const debounceGroWebSearch = debounce(value => setGroWebSearch(value), 600)
  //client dropdown
  const handleGroWebEnters = textEntered => {
    debounceGroWebSearch(textEntered)
  }

  function handlerGroWebFinalValue(event) {
    const client = {
      ...groWebSelect,
      title: event.value !== "loading" && event.label,
      id: event.value,
    }
    setGroWebSelect(client)
  }

  const groWebOptions = [
    {
      label: "All GroWeb",
      value: "",
    },
    {
      options: (GroWeb?.growebs?.length >= 1 ? GroWeb?.growebs : [])?.map(
        (results, index) => ({
          key: index,
          label: results.name,
          value: results._id,
        })
      ),
    },
  ]

  const [isOpen, setIsOpen] = useState({ status: false, id: "" })

  const handleDelete = id => {
    setIsOpen({ status: true, id: id })
  }

  const handleDeleteEvent = () => {
    setIsOpen({ ...isOpen, status: false })
    dispatch(deleteClient(isOpen?.id))
  }

  const columns = [
    {
      dataField: "name",
      text: "User Name",
      sort: true,
    },
    {
      dataField: "phone",
      text: "Phone",
      sort: true,
    },
    {
      dataField: "createdAt",
      text: "Created At",
    },
  ]

  const tableData = map(pageviews?.pageViews, (item, index) => ({
    ...item,

    message: (
      <div style={{ whiteSpace: "break-spaces" }}>
        {item?.message?.slice(0, 110)}
        {item?.message?.length > 110 ? "..." : ""}
      </div>
    ),
    createdAt: moment(item.createdAt).format("DD/MM/YYYY - h:mm a"),
    action: (
      <div>
        <Link to={`#`} className="btn-md me-3">
          <i className="bx bx-edit" style={{ fontSize: "1.3rem" }} />
        </Link>

        <Link
          to={"#"}
          className="btn-md "
          onClick={() => {
            handleDelete(item._id)
          }}
        >
          <i
            className="bx bx-trash"
            style={{ color: "red", fontSize: "1.3rem" }}
          />
        </Link>
      </div>
    ),
  }))

  return (
    <React.Fragment>
      <Row>
        <DeleteModal
          show={isOpen?.status}
          onCloseClick={() => setIsOpen({ ...isOpen, status: false })}
          onDeleteClick={handleDeleteEvent}
        />

        <Col className="col-12">
          <ToolkitProvider
            keyField="_id"
            key={"id"}
            columns={columns}
            data={tableData}
            search
          >
            {toolkitProps => (
              <React.Fragment>
                <Row className="mb-2">
                  <Col sm="4"></Col>
                  {!growebDetails?._id && pageviews?.pageViews?.length > 0 && (
                    <>
                      <Col sm={8} lg="4">
                        <FormGroup className="mb-3">
                          <div className="ajax-select mt-lg-0 select2-container">
                            <Select
                              onInputChange={handleGroWebEnters}
                              value={groWebSelect?.title}
                              placeholder={groWebSelect?.title}
                              onChange={handlerGroWebFinalValue}
                              options={groWebOptions}
                              classNamePrefix="select2-selection"
                              isLoading={loading}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                    </>
                  )}
                </Row>
                {loading ? (
                  <Spinner color="secondary" className="d-block m-auto" />
                ) : (
                  <>
                    {pageviews?.total >= 1 ? (
                      <>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField={"id"}
                                key={"id"}
                                responsive
                                bordered={false}
                                striped={false}
                                // selectRow={selectRow}
                                classes={"table align-middle table-nowrap"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                                // onTableChange={handleTableChange}
                                // {...paginationTableProps}
                              />
                            </div>
                          </Col>
                        </Row>

                        <MyPagination
                          totalPages={pages}
                          page={page}
                          setPage={setPage}
                        />
                      </>
                    ) : (
                      <p className="my-4 mx-3 ">No pageviews !</p>
                    )}
                  </>
                )}
              </React.Fragment>
            )}
          </ToolkitProvider>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default PageViews

PageViews.propTypes = {
  growebDetails: PropTypes.any,
}
