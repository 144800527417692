import {
  GET_PAGEVIEWS,
  GET_PAGEVIEWS_SUCCESS,
  GET_PAGEVIEWS_FAIL,
  GET_PAGEVIEW_DETAILS,
  GET_PAGEVIEW_DETAILS_SUCCESS,
  GET_PAGEVIEW_DETAILS_FAIL,
  CREATE_PAGEVIEW,
  CREATE_PAGEVIEW_SUCCESS,
  CREATE_PAGEVIEW_FAIL,
  UPDATE_PAGEVIEW,
  UPDATE_PAGEVIEW_SUCCESS,
  UPDATE_PAGEVIEW_FAIL,
  DELETE_PAGEVIEW,
  DELETE_PAGEVIEW_SUCCESS,
  DELETE_PAGEVIEW_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  pageviews: {
    pageviews: [],
    total: "",
    page: "",
  },
  pageviewDetails: {},
  error: {},
  loading: false,
}

const PageView = (state = INIT_STATE, action) => {
  switch (action.type) {
    // PageView - This line cannot be edited or removed
    case GET_PAGEVIEWS:
    case GET_PAGEVIEW_DETAILS:
    case CREATE_PAGEVIEW:
    case UPDATE_PAGEVIEW:
    case DELETE_PAGEVIEW:
      return {
        ...state,
        loading: true,
      }

    case DELETE_PAGEVIEW_FAIL:
    case GET_PAGEVIEWS_FAIL:
    case GET_PAGEVIEW_DETAILS_FAIL:
    case UPDATE_PAGEVIEW_FAIL:
    case CREATE_PAGEVIEW_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_PAGEVIEWS_SUCCESS:
      return {
        ...state,
        pageviews: action.payload,
        error: {},
        loading: false,
      }

    case GET_PAGEVIEW_DETAILS_SUCCESS:
      return {
        ...state,
        pageviewDetails: action.payload,
        error: {},
        loading: false,
      }

    case CREATE_PAGEVIEW_SUCCESS:
      return {
        ...state,
        pageviews: {
          ...state.pageviews,
          pageviews: [...state.pageviews.pageviews, action.payload],
          total: state.pageviews.total + 1,
        },
        pageviewDetails: action.payload,
        error: {},
        loading: false,
      }

    case UPDATE_PAGEVIEW_SUCCESS:
      return {
        ...state,
        pageviews: {
          ...state.pageviews,
          pageviews: state.pageviews.pageviews.map(pageview =>
            pageview._id === action.payload._id
              ? { ...pageview, ...action.payload }
              : pageview
          ),
        },
        pageviewDetails: action.payload,
        loading: false,
        error: {},
      }

    case DELETE_PAGEVIEW_SUCCESS:
      return {
        ...state,
        pageviews: {
          ...state.pageviews,
          pageviews: state.pageviews.pageviews.filter(
            pageview => pageview._id !== action.payload._id
          ),
        },
        error: {},
        loading: false,
      }
    default:
      return state
  }
}

export default PageView
