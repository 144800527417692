import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Spinner, FormGroup } from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import Select from "react-select"
import PropTypes from "prop-types"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import { debounce, map, range } from "lodash"

import ToolkitProvider from "react-bootstrap-table2-toolkit"
import { defaultImg } from "assets/images"

//actions
import {
  deleteLead,
  getAllProducts,
  getGroWebs,
  getGrowPages,
  getLeads,
} from "store/actions"

import "../../assets/scss/datatables.scss"

import MyPagination from "components/Common/MyPagination"
import DeleteModal from "components/Common/DeleteModal"
import { AvField, AvForm } from "availity-reactstrap-validation"
import moment from "moment"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"

const Leads = ({ growebDetails }) => {
  const dispatch = useDispatch()
  const params = useParams()
  const [page, setPage] = useState(1)
  const [leadModal, setLeadModal] = useState({ status: false, data: {} })

  const [groWebSelect, setGroWebSelect] = useState({
    title: "Select Groweb",
    id: "",
  })
  const [groWebSearch, setGroWebSearch] = useState()

  const [growPageSelect, setGrowPageSelect] = useState({
    title: "Select GrowPage",
    id: "",
  })
  const [growPageSearch, setGrowPageSearch] = useState()
  const [productSelect, setProductSelect] = useState({
    title: "Select Product",
    id: "",
  })
  const [productSearch, setProductSearch] = useState()
  const { loading, prodLoading, leads, GroWeb, growPages, products } =
    useSelector(state => ({
      loading: state.Lead.loading,
      leads: state.Lead.leads,
      GroWeb: state.GroWeb.growebs,
      growPages: state.GrowPage.growpages,
      prodLoading: state.Products.loading,
      products: state.Products.products,
    }))
  const totalPages = Math.ceil(leads?.total / 10)
  const pages = range(1, totalPages + 1)

  useEffect(() => {
    dispatch(
      getLeads(
        page,
        "",
        10,
        growebDetails?._id,
        growPageSelect.id,
        productSelect.id
      )
    )
  }, [
    dispatch,
    page,
    groWebSelect,
    growebDetails,
    growPageSelect,
    productSelect,
  ])

  const [isOpen, setIsOpen] = useState({ status: false, id: "" })

  const handleDelete = id => {
    setIsOpen({ status: true, id: id })
  }

  const handleDeleteEvent = () => {
    setIsOpen({ ...isOpen, status: false })
    dispatch(deleteLead(isOpen?.id))
  }

  useEffect(() => {
    if (!growebDetails?._id) {
      dispatch(getGroWebs("", "", "", "", groWebSearch))
    }
  }, [dispatch, groWebSearch, growebDetails?._id])

  const debounceGroWebSearch = debounce(value => setGroWebSearch(value), 600)
  //client dropdown
  const handleGroWebEnters = textEntered => {
    debounceGroWebSearch(textEntered)
  }

  function handlerGroWebFinalValue(event) {
    const client = {
      ...groWebSelect,
      title: event.value !== "loading" && event.label,
      id: event.value,
    }
    setGroWebSelect(client)
  }

  const groWebOptions = [
    {
      label: "All GroWeb",
      value: "",
    },
    {
      options: (GroWeb?.growebs?.length >= 1 ? GroWeb?.growebs : [])?.map(
        (results, index) => ({
          key: index,
          label: results.name,
          value: results._id,
        })
      ),
    },
  ]
  // useEffect(() => {
  //   if (!growebDetails?._id) {
  //     dispatch(getGrowPages("", "", "", "", growPageSearch))
  //   }
  // }, [dispatch, growPageSearch, growebDetails?._id])

  const debounceGrowPageSearch = debounce(
    value => setGrowPageSearch(value),
    600
  )
  //client dropdown
  const handleGrowPageEnters = textEntered => {
    debounceGrowPageSearch(textEntered)
  }

  function handlerGrowPageFinalValue(event) {
    const growPage = {
      ...growPageSelect,
      title: event.value !== "loading" && event.label,
      id: event.value,
    }
    setGrowPageSelect(growPage)
  }

  const growPageOptions = [
    {
      label: "All growPages",
      value: "",
    },
    {
      options: (growPages?.growpages?.length >= 1
        ? growPages?.growpages
        : []
      )?.map((results, index) => ({
        key: index,
        label: results.title,
        value: results._id,
      })),
    },
  ]
  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "phone",
      text: "Phone",
      sort: true,
    },
    {
      dataField: "createdAt",
      text: "Created At",
    },
    {
      dataField: "imageData",
      text: "Image",
      sort: true,
    },
    {
      dataField: "action",
      text: "Actions",
    },
  ]

  const tableData = map(leads?.leads, (item, index) => ({
    ...item,

    message: (
      <div style={{ whiteSpace: "break-spaces" }}>
        {item?.message?.slice(0, 110)}
        {item?.message?.length > 110 ? "..." : ""}
      </div>
    ),
    createdAt: moment(item.createdAt).format("DD/MM/YYYY - h:mm a"),
    imageData: (
      <div>
        <img
          src={
            item?.images && item?.images[0] ? item?.images[0]?.url : defaultImg
          }
          width="50px"
        />
      </div>
    ),
    action: (
      <div>
        <Link
          to={"#"}
          className="btn-md "
          onClick={() => {
            handleDelete(item._id)
          }}
        >
          <i
            className="bx bx-trash"
            style={{ color: "red", fontSize: "1.3rem" }}
          />
        </Link>
      </div>
    ),
  }))
  // useEffect(() => {
  //   dispatch(getAllProducts(1, "all", "", productSearch))
  // }, [dispatch, productSearch])

  const debounceProductSearch = debounce(value => setProductSearch(value), 600)

  const handleProductEnters = textEntered => {
    debounceProductSearch(textEntered)
  }

  function handlerProductFinalValue(event) {
    const product = {
      ...productSelect,
      title: event.value !== "loading" && event.label,
      id: event.value,
    }
    setProductSelect(product)
  }

  const productOptions = [
    {
      label: "All products",
      value: "",
    },
    {
      options: (products?.length >= 1 ? products : [])?.map(
        (results, index) => ({
          key: index,
          label: results?.primaryLang?.name,
          value: results?._id,
        })
      ),
    },
  ]
  return (
    <React.Fragment>
      <Row>
        <DeleteModal
          show={isOpen?.status}
          onCloseClick={() => setIsOpen({ ...isOpen, status: false })}
          onDeleteClick={handleDeleteEvent}
        />

        <Col className="col-12 mt-4">
          <ToolkitProvider
            keyField="_id"
            key={"id"}
            columns={columns}
            data={tableData}
            search
          >
            {toolkitProps => (
              <React.Fragment>
                <Row
                  className={`mb-2 ${
                    growebDetails?._id ? "justify-content-end" : ""
                  }`}
                >
                  {leads?.leads?.length > 0 && (
                    <>
                      <Col lg={3} className="">
                        <FormGroup className="mb-3">
                          <div className="ajax-select mt-lg-0 select2-container">
                            <Select
                              onInputChange={handleProductEnters}
                              value={productSelect?.title}
                              placeholder={productSelect?.title}
                              onChange={handlerProductFinalValue}
                              options={productOptions}
                              classNamePrefix="select2-selection"
                              isLoading={loading}
                            />
                          </div>
                        </FormGroup>
                      </Col>

                      <Col sm={8} lg="3">
                        <FormGroup className="mb-3">
                          <div className="ajax-select mt-lg-0 select2-container">
                            <Select
                              onInputChange={handleGrowPageEnters}
                              value={growPageSelect?.title}
                              placeholder={growPageSelect?.title}
                              onChange={handlerGrowPageFinalValue}
                              options={growPageOptions}
                              classNamePrefix="select2-selection"
                              isLoading={loading}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                    </>
                  )}

                  {!growebDetails?._id && leads?.leads?.length > 0 && (
                    <>
                      <Col sm={8} lg="3">
                        <FormGroup className="mb-3">
                          <div className="ajax-select mt-lg-0 select2-container">
                            <Select
                              onInputChange={handleGroWebEnters}
                              value={groWebSelect?.title}
                              placeholder={groWebSelect?.title}
                              onChange={handlerGroWebFinalValue}
                              options={groWebOptions}
                              classNamePrefix="select2-selection"
                              isLoading={loading}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                    </>
                  )}
                </Row>
                {loading ? (
                  <Spinner color="secondary" className="d-block m-auto" />
                ) : (
                  <>
                    {leads?.total >= 1 ? (
                      <>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField={"id"}
                                key={"id"}
                                responsive
                                bordered={false}
                                striped={false}
                                // selectRow={selectRow}
                                classes={"table align-middle table-nowrap"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                                // onTableChange={handleTableChange}
                                // {...paginationTableProps}
                              />
                            </div>
                          </Col>
                        </Row>

                        <MyPagination
                          totalPages={pages}
                          page={page}
                          setPage={setPage}
                        />
                      </>
                    ) : (
                      <p className="my-4 mx-3 ">No Leads !</p>
                    )}
                  </>
                )}
              </React.Fragment>
            )}
          </ToolkitProvider>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default Leads

Leads.propTypes = {
  growebDetails: PropTypes.any,
}
