import { AvField, AvForm } from "availity-reactstrap-validation"
import { debounce, map, update } from "lodash"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { WithContext as ReactTags } from "react-tag-input"

import {
  Card,
  CardBody,
  CardTitle,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  Row,
} from "reactstrap"
import {
  checkPageTitle,
  createClient,
  createGrowPage,
  createReview,
  getAllProducts,
  getRawUsers,
  getSections,
  updateClient,
  updateGrowPage,
  updateReview,
} from "store/actions"
import Select from "react-select"
import MyRating from "components/Common/Rating"
import UseImageUploader from "components/imageUploader/useImageUploader"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"

const ClientModal = ({
  show,
  onCloseClick,
  updateData,
  setCustomActiveTab,
}) => {
  const dispatch = useDispatch()
  const params = useParams()

  const { products, users, loading, pageTitleCheck } = useSelector(state => ({
    loading: state.Products.loading,
    pageTitleCheck: state.GrowPage?.pageTitleCheck,

    usersLoading: state.Users.loading,
    // Sections: state.Section.sections,
    products: state.Products.products,
  }))
  const [imagesPreview, setImagesPreview] = useState([])
  const [keywords, setKeywords] = useState([])
  const [tags, setTags] = useState([])
  const [sections, setSections] = useState([])
  const [selectedSection, setSelectedSection] = useState(null)
  const [productSelect, setProductSelect] = useState({})
  const [productSearch, setProductSearch] = useState()
  const [checkPageTitles, setCheckPageTitles] = useState("")

  const handleTitleCheck = v => {
    setCheckPageTitles(v)
    dispatch(checkPageTitle(checkPageTitles, ""))
  }
  console.log(checkPageTitles)
  const handleValidSubmitGrowPage = (e, v) => {
    const growPageDetails = {
      ...v,
      groweb: params.id,
      images: imagesPreview,
      keywords: keywords,
      // sections: sections,
      product: productSelect?.id,
    }
    dispatch(createGrowPage(growPageDetails))
    onCloseClick()
  }

  // useEffect(() => {
  //   dispatch(getSections(1, "", 10, ""))
  // }, [dispatch])
  const optionGroupSection = () => {
    const optionGroup = []?.map(item => {
      return {
        value: item._id,
        label: `${item?.title} `,
      }
    })
    return optionGroup
  }
  function handlerFinalValueSection(
    typeData,
    setSelectedMultiSection,
    setSections
  ) {
    // console.log(typeData)
    setSelectedMultiSection(typeData)
    const arrangedTypes = typeData?.map(a => a.value)
    setSections(arrangedTypes)
  }

  // const sectionListFilterItem = (
  //   selectedMultiSection,
  //   setSelectedMultiSection,
  //   setSections
  // ) => {
  //   return (
  //     Sections?.sections &&
  //     Sections?.sections?.length > 0 && (
  //       <Col md={"12"}>
  //         <Label> Sections</Label>
  //         <Select
  //           value={selectedMultiSection}
  //           isMulti={true}
  //           onChange={e => {
  //             handlerFinalValueSection(e, setSelectedMultiSection, setSections)
  //           }}
  //           options={optionGroupSection()}
  //           classNamePrefix="select2-selection"
  //         />
  //       </Col>
  //     )
  //   )
  // }
  const KeyCodes = {
    comma: 188,
    enter: 13,
  }
  const delimiters = [KeyCodes.comma, KeyCodes.enter]
  const handleDeleteTag = i => {
    setTags(tags.filter((tag, index) => index !== i))
    const arrangedKeywords = tags.map(a => a.text)
    setKeywords(arrangedKeywords)
  }

  const handleAddition = tag => {
    setTags([...tags, tag])
    const arrangedKeywords = [...tags, tag].map(a => a.text)
    setKeywords(arrangedKeywords)
  }

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice()
    newTags.splice(currPos, 1)
    newTags.splice(newPos, 0, tag)
    // re-render
    setTags(newTags)
    const arrangedKeywords = newTags.map(a => a.text)
    setKeywords(arrangedKeywords)
  }
  const handleTagClick = index => {
    console.log("The tag at index " + index + " was clicked")
  }
  useEffect(() => {
    dispatch(getAllProducts(1, "all", "", productSearch))
  }, [dispatch, productSearch])

  const debounceProductSearch = debounce(value => setProductSearch(value), 600)

  const handleProductEnters = textEntered => {
    debounceProductSearch(textEntered)
  }

  function handlerProductFinalValue(event) {
    const product = {
      ...productSelect,
      title: event.value !== "loading" && event.label,
      id: event.value,
    }
    setProductSelect(product)
  }

  const productOptions = (products?.length >= 1 ? products : [])?.map(
    (result, index) => ({
      key: index,
      label: result?.primaryLang?.name,
      value: result?._id,
    })
  )
  useEffect(() => {
    if (updateData?._id) {
      setProductSelect({
        title: updateData?.product?.primaryLang?.name,
        value: updateData?.product?._id,
      })
      // setSelectedSection  ({...selectedSection,value:updateData?.sections?._id,title:updateData?.sections?.title,})

      const pickUpKeywords = updateData?.keywords
      const modifiedList = []
      pickUpKeywords?.map(i => modifiedList?.push({ id: i, text: i }))

      setTags(modifiedList)
      setKeywords(pickUpKeywords)
      setImagesPreview(updateData?.images)
      //
      // .................................................................
      // const pickUpSection = updateData?.sections

      // const modifiedListSection = []
      // pickUpSection?.map(i => modifiedListSection?.push({ id: i, text: i }))
      // setSelectedSection(modifiedListSection)
      // setSections(pickUpSection)
      // setKeywords()
      // setImagesPreview(updateData)
      // setUser({
      //   name: updateData?.name,
      //   phone: updateData?.phone,
      // })
      // setImagesPreview(updateData?.images)
    }
  }, [updateData])

  useEffect(() => {
    const sectionNewList = updateData?.sections

    const newList = map(sectionNewList, i => ({
      ...selectedSection,
      label: i?.title,
      value: i?._id,
    }))
    setSelectedSection(newList)

    const idList = map(sectionNewList, i => i?._id)

    setSections(idList)
  }, [updateData?.sections])
  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody className="p-4">
        <CardTitle>Add New GrowPage</CardTitle>
        <AvForm
          onValidSubmit={(e, v) => {
            handleValidSubmitGrowPage(e, v)
          }}
        >
          <Row>
            <>
              <Col className="col-6 mb-2">
                <p className="h4 mb-2 text-muted font-size-14">Title</p>

                <AvField
                  name="title"
                  type="text"
                  placeholder="Enter title"
                  value={updateData?.title}
                  onChange={e => {
                    handleTitleCheck(e.target.value)
                  }}
                />
                {checkPageTitles && (
                  <>
                    {pageTitleCheck?.response === true ? (
                      <h6 className="pt-1" style={{ color: "green" }}>
                        {" "}
                        Available
                      </h6>
                    ) : (
                      <h6 className="pt-1" style={{ color: "red" }}>
                        Not Available{" "}
                      </h6>
                    )}
                  </>
                )}
              </Col>

              <Col lg={6} className="">
                <p className="h4 mb-2 text-muted font-size-14">Products</p>
                <FormGroup className="mb-3">
                  <div className="ajax-select mt-lg-0 select2-container">
                    <Select
                      onInputChange={handleProductEnters}
                      value={productSelect?.title}
                      placeholder={productSelect?.title}
                      onChange={handlerProductFinalValue}
                      options={productOptions}
                      classNamePrefix="select2-selection"
                      isLoading={loading}
                    />
                  </div>
                </FormGroup>
              </Col>
            </>
          </Row>
          <Row>
            <>
              <Col className="col-12 mb-2">
                <AvField
                  name="description"
                  type="textarea"
                  placeholder="Enter description"
                  value={updateData?.description}
                />
              </Col>
              {/* section */}
              {/* <Col className="col-12 mb-2">
                <AvField
                  name="description"
                  type="text"
                  placeholder="Enter description"
                  value={updateData?.description}
                />
              </Col> */}
            </>
          </Row>
          {/* <Row>
            {sectionListFilterItem(
              selectedSection,
              setSelectedSection,
              setSections
            )}
          </Row> */}
          <Row>
            <div className=" col-12 row mb-4 mt-2">
              <div className="col-12  mb-3">
                <Label htmlFor="lbltype" className="form-label mb-3">
                  keywords:
                </Label>
                <ReactTags
                  tags={tags}
                  placeholder="Type keywords and enter"
                  // suggestions={suggestions}
                  delimiters={delimiters}
                  handleDelete={handleDeleteTag}
                  handleAddition={handleAddition}
                  handleDrag={handleDrag}
                  handleTagClick={handleTagClick}
                  // inputFieldPosition="inline"
                  autocomplete
                  allowUnique
                  inline
                />
              </div>
            </div>
          </Row>

          <Row>
            <Card>
              <CardBody>
                <div className="mt-1  col-12">
                  <h6 className="">images :</h6>
                  <UseImageUploader
                    imageSize={4}
                    imagesPreview={imagesPreview}
                    setImagesPreview={setImagesPreview}
                    uploadUrl={"/growebosperb"}
                    isMultiple
                  />
                </div>
              </CardBody>
            </Card>
          </Row>

          <div className="d-flex justify-content-end align-items-center">
            <div className="text-center mt-3">
              <button
                type="button"
                className="btn btn-danger ms-2"
                onClick={onCloseClick}
              >
                Cancel
              </button>
              <button type="submit" className="btn btn-success ms-2">
                Save
              </button>
            </div>
          </div>
        </AvForm>
      </ModalBody>
    </Modal>
  )
}

ClientModal.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.any,
  updateData: PropTypes.any,
  setCustomActiveTab: PropTypes.any,
}

export default ClientModal
