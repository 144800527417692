import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import GroWebDashboard from "../pages/GroWebDashboard/index"

//ZestonProduct
import Product from "pages/Product/index"
import ProductDetails from "pages/Product/SingleView/index"
import UpdateProduct from "pages/Product/Crud/Update/Update"
import CreateProduct from "pages/Product/Crud/Create/Create"

//User
import User from "pages/User/index"
import UserDetails from "pages/User/SingleView/index"
import UpdateUser from "pages/User/Crud/Update/Update"

//404
import Pages404 from "pages/error/pages-404"
import UpdateProductStock from "pages/Product/stockUpdate/Update"
// import ProductPages from "pages/product-pages"
// import CratePages from "pages/product-pages/Crud/Create"
import UpdatePages from "pages/product-pages/Crud/Update"
import Blogs from "pages/Blogs"
import GroWeb from "pages/Groweb"
import Clients from "pages/Client"
import GrowPages from "pages/GrowPage"
import ClientView from "pages/Client/singleView"
import Leads from "pages/Lead"
import PageViews from "pages/PageView"
import LeadModal from "pages/Lead/Crud/Create"
import ActiveTabs from "pages/GrowPage/ActiveTabs"
import GrowebDetails from "pages/Groweb/SingleView"
import GrowebCreate from "pages/Groweb/Crud/index"

const commonRoute = [
  { path: "/dashboard", component: Dashboard },

  { path: "/logout", component: Logout },

  {
    path: "/",
    exact: true,
    component: function dashboard() {
      return <Redirect to="/dashboard" />
    },
  },
]

const superAdminRoutes = [
  // products
  { path: "/products", component: Product },
  { path: "/product/:id", component: ProductDetails },
  { path: "/product/create/new", component: CreateProduct },
  { path: "/product/update/:id", component: UpdateProduct },
  { path: "/stockUpdate", component: UpdateProductStock },

  //groweb dashboard
  { path: "/gro-web", component: GroWebDashboard },

  // groweb
  { path: "/groWeb", component: GroWeb },
  { path: "/groWeb/update/:id", component: GrowebDetails },
  { path: "/groWeb/create/new", component: GrowebCreate },
  { path: "/Tabs", component: ActiveTabs },

  //pageview
  { path: "/page-view", component: PageViews },

  //clients
  { path: "/client", component: Clients },
  { path: "/grow-pages", component: GrowPages },
  { path: "/client/:id", component: ClientView },

  //Leads
  { path: "/leads", component: Leads },
  { path: "/lead/:id", component: LeadModal },
  { path: "/lead-create", component: LeadModal },

  //pages
  // { path: "/pages/all", component: ProductPages },
  // { path: "/pages/product/create", component: CratePages },
  { path: "/pages/product/:id", component: UpdatePages },

  // Users
  { path: "/users", component: User },
  { path: "/users/:id", component: UserDetails },
  { path: "/user/update/:id", component: UpdateUser },

  //Dashboard
  { path: "/dashboard", component: Dashboard },

  //profile
  { path: "/profile", component: UserProfile },

  //blogs
  { path: "/blogs", component: Blogs },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/404", component: Pages404 },
]

export { publicRoutes, superAdminRoutes, commonRoute }
