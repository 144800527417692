import {
  GET_GROWEBS,
  GET_GROWEBS_SUCCESS,
  GET_GROWEBS_FAIL,
  GET_GROWEB_DETAILS,
  GET_GROWEB_DETAILS_SUCCESS,
  GET_GROWEB_DETAILS_FAIL,
  CREATE_GROWEB,
  CREATE_GROWEB_SUCCESS,
  CREATE_GROWEB_FAIL,
  UPDATE_GROWEB,
  UPDATE_GROWEB_SUCCESS,
  UPDATE_GROWEB_FAIL,
  DELETE_GROWEB_SUCCESS,
  DELETE_GROWEB_FAIL,
  DELETE_GROWEB,
  CLEAR_GROWEB_DETAILS,
  GET_GROWEB_DASHBOARD_DATA,
  GET_GROWEB_DASHBOARD_DATA_SUCCESS,
  GET_GROWEB_DASHBOARD_DATA_FAIL,
} from "./actionTypes"

// GroWeb - This line cannot be edited or removed
export const getGroWebs = (page, sort, limit, client, searchText) => ({
  type: GET_GROWEBS,
  payload: { page, sort, limit, client, searchText },
})

export const getGroWebsSuccess = growebs => ({
  type: GET_GROWEBS_SUCCESS,
  payload: growebs,
})

export const getGroWebsFail = error => ({
  type: GET_GROWEBS_FAIL,
  payload: error,
})

export const getGroWebDetails = growebId => ({
  type: GET_GROWEB_DETAILS,
  payload: growebId,
})

export const getGroWebDetailsSuccess = growebDetails => ({
  type: GET_GROWEB_DETAILS_SUCCESS,
  payload: growebDetails,
})

export const getGroWebDetailsFail = error => ({
  type: GET_GROWEB_DETAILS_FAIL,
  payload: error,
})

export const clearGroWebDetails = () => ({
  type: CLEAR_GROWEB_DETAILS,
})

export const createGroWeb = (groweb, history) => ({
  type: CREATE_GROWEB,
  payload: { groweb, history },
})

export const createGroWebSuccess = groweb => ({
  type: CREATE_GROWEB_SUCCESS,
  payload: groweb,
})

export const createGroWebFail = error => ({
  type: CREATE_GROWEB_FAIL,
  payload: error,
})

export const getGroWebDetail = growebId => ({
  type: GET_GROWEB_DETAILS,
  payload: growebId,
})

export const updateGroWeb = (groweb, growebId, setCustomActiveTab) => ({
  type: UPDATE_GROWEB,
  payload: { groweb, growebId, setCustomActiveTab },
})

export const updateGroWebSuccess = groweb => ({
  type: UPDATE_GROWEB_SUCCESS,
  payload: groweb,
})

export const updateGroWebFail = error => ({
  type: UPDATE_GROWEB_FAIL,
  payload: error,
})

export const deleteGroWeb = growebId => ({
  type: DELETE_GROWEB,
  payload: { growebId },
})

export const deleteGroWebSuccess = groweb => ({
  type: DELETE_GROWEB_SUCCESS,
  payload: groweb,
})

export const deleteGroWebFail = error => ({
  type: DELETE_GROWEB_FAIL,
  payload: error,
})

//Dashboard Data

export const getGrowebDashboardData = growebId => ({
  type: GET_GROWEB_DASHBOARD_DATA,
  payload: { growebId },
})

export const getGrowebDashboardDataSuccess = growebDashboardData => ({
  type: GET_GROWEB_DASHBOARD_DATA_SUCCESS,
  payload: growebDashboardData,
})

export const getGrowebDashboardDataFail = error => ({
  type: GET_GROWEB_DASHBOARD_DATA_FAIL,
  payload: error,
})
