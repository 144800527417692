export const lastSpaceRemover = value => {
  for (var i = value.length - 1; i >= 0; i--) {
    if (value[i] == " ") {
      if (i == value.length - 1) {
        const str = value.substring(0, value.length - 1)
        value = str
      }
    }
  }
  return value
}
