import {
  GET_CLIENTS,
  GET_CLIENTS_SUCCESS,
  GET_CLIENTS_FAIL,
  GET_CLIENT_DETAILS,
  GET_CLIENT_DETAILS_SUCCESS,
  GET_CLIENT_DETAILS_FAIL,
  CREATE_CLIENT,
  CREATE_CLIENT_SUCCESS,
  CREATE_CLIENT_FAIL,
  UPDATE_CLIENT,
  UPDATE_CLIENT_SUCCESS,
  UPDATE_CLIENT_FAIL,
  DELETE_CLIENT_SUCCESS,
  DELETE_CLIENT_FAIL,
  DELETE_CLIENT,
} from "./actionTypes"

// Client - This line cannot be edited or removed
export const getClients = (page, sort, limit, searchText) => ({
  type: GET_CLIENTS,
  payload: { page, sort, limit, searchText },
})

export const getClientsSuccess = clients => ({
  type: GET_CLIENTS_SUCCESS,
  payload: clients,
})

export const getClientsFail = error => ({
  type: GET_CLIENTS_FAIL,
  payload: error,
})

export const getClientDetails = clientId => ({
  type: GET_CLIENT_DETAILS,
  payload: clientId,
})

export const getClientDetailsSuccess = clientDetails => ({
  type: GET_CLIENT_DETAILS_SUCCESS,
  payload: clientDetails,
})

export const getClientDetailsFail = error => ({
  type: GET_CLIENT_DETAILS_FAIL,
  payload: error,
})

export const createClient = (client, history) => ({
  type: CREATE_CLIENT,
  payload: { client, history },
})

export const createClientSuccess = client => ({
  type: CREATE_CLIENT_SUCCESS,
  payload: client,
})

export const createClientFail = error => ({
  type: CREATE_CLIENT_FAIL,
  payload: error,
})

export const getClientDetail = clientId => ({
  type: GET_CLIENT_DETAILS,
  payload: clientId,
})

export const updateClient = (client, clientId, history) => ({
  type: UPDATE_CLIENT,
  payload: { client, clientId, history },
})

export const updateClientSuccess = client => ({
  type: UPDATE_CLIENT_SUCCESS,
  payload: client,
})

export const updateClientFail = error => ({
  type: UPDATE_CLIENT_FAIL,
  payload: error,
})

export const deleteClient = clientId => ({
  type: DELETE_CLIENT,
  payload: { clientId },
})

export const deleteClientSuccess = client => ({
  type: DELETE_CLIENT_SUCCESS,
  payload: client,
})

export const deleteClientFail = error => ({
  type: DELETE_CLIENT_FAIL,
  payload: error,
})
