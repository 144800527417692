import { AvField, AvForm } from "availity-reactstrap-validation"
import { debounce, update } from "lodash"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { CardTitle, Col, FormGroup, Modal, ModalBody, Row } from "reactstrap"
import {
  createClient,
  createReview,
  getAllProducts,
  getRawUsers,
  updateClient,
  updateReview,
} from "store/actions"
import Select from "react-select"
import MyRating from "components/Common/Rating"
import UseImageUploader from "components/imageUploader/useImageUploader"

const ClientModal = ({ show, onCloseClick, updateData }) => {
  const dispatch = useDispatch()

  const { products, users } = useSelector(state => ({
    prodLoading: state.Products.loading,
    usersLoading: state.Users.loading,
  }))

  const handleValidSubmit = (e, v) => {
    const client = {
      ...v,
    }
    if (updateData?._id) {
      dispatch(updateClient(client, updateData?._id, ""))
      onCloseClick()
    } else {
      dispatch(createClient(client, onCloseClick))
      onCloseClick()
    }
  }
  console.log(updateData)

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody className="p-4">
        <CardTitle>Add New Client</CardTitle>
        <AvForm
          onValidSubmit={(e, v) => {
            handleValidSubmit(e, v)
          }}
        >
          <Row>
            <>
              <Col className="col-12 mb-2">
                <AvField
                  name="name"
                  type="text"
                  placeholder="Enter Name"
                  value={updateData?.name}
                />
              </Col>
            </>
          </Row>
          <Row>
            <Col className="col-6">
              <AvField
                name="phone"
                type="number"
                placeholder="Enter Phone"
                value={updateData?.phone}
                validate={{
                  minLength: {
                    value: 10,
                    errorMessage: "phone number must be 10 letter ",
                  },
                  maxLength: {
                    value: 10,
                    errorMessage: "phone number must be 10 letter ",
                  },
                }}
              />
            </Col>
            <Col className="col-6">
              <AvField
                name="whatsappNumber"
                type="number"
                placeholder="Enter Whatsapp Number"
                value={updateData?.whatsappNumber}
                validate={{
                  minLength: {
                    value: 10,
                    errorMessage: "phone number must be 10 letter ",
                  },
                  maxLength: {
                    value: 10,
                    errorMessage: "phone number must be 10 letter ",
                  },
                }}
              />
            </Col>
          </Row>
          <div className="d-flex justify-content-end align-items-center">
            <div className="text-center mt-3">
              <button
                type="button"
                className="btn btn-danger ms-2"
                onClick={onCloseClick}
              >
                Cancel
              </button>
              <button type="submit" className="btn btn-success ms-2">
                Save
              </button>
            </div>
          </div>
        </AvForm>
      </ModalBody>
    </Modal>
  )
}

ClientModal.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.any,
  updateData: PropTypes.any,
}

export default ClientModal
