import { useSelector } from "react-redux"

function useGetStore() {
  const role = sessionStorage.getItem("role")
  const { currentStore } = useSelector(state => ({
    currentStore: state?.Stores?.currentStoreDetails,
  }))

  const storeId = sessionStorage?.getItem("storeId")
  const store = sessionStorage?.getItem("store")

  if (role === "admin") {
    return {
      currentStoreId: undefined,
      isStoreAdmin: false,
    }
  } else if (role === "storeAdmin") {
    return {
      currentStoreId: currentStore?._id || storeId,
      storeName: currentStore?.storeName || store,
      isStoreAdmin: true,
    }
  }
}

export default useGetStore
