module.exports = {
  primaryColor: "#22b3be",
  secondaryColor: "#ed475f",
  companyName: "Groweb-sass",
  shortName: "Groweb",
  siteMetadata: {
    websiteTitle: "Groweb",
    description: "Groweb sass",
    keywords: "",
    faviconUrl: "",
    logoUrl: "",
    secondaryLogo: "",
  },
  apiEndpointDev: "http://192.168.1.37:4000",
  apiEndpointProd: "https://api.groweb.osperb.com",
  frontEndUrl: "https://groweb.osperb.com",
  tokenPrefix: "groweb_token",
}
