export const sidebarData = [
  {
    routeLink: "/#",
    icon: "bx bx-home-circle",
    title: "Dashboard",
    isStore: true,
  },

  {
    routeLink: "/client",
    icon: "bx bx-user",
    title: "Client",
    isStore: true,
  },
  {
    routeLink: "/groWeb",
    icon: "bx bx-briefcase",
    title: "Groweb",
    isStore: true,
  },

  {
    routeLink: "/products",
    icon: "bx bxs-package",
    title: "Products",
    isStore: true,
  },
  {
    routeLink: "/leads",
    icon: "bx bx-food-menu",
    title: "Leads",
    isStore: true,
  },
  // {
  //   routeLink: "/stockUpdate",
  //   icon: "bx bx-store-alt",
  //   title: "Stock & Offer",
  //   isStore: true,
  // },

  // {
  //   routeLink: "/pages/all",
  //   icon: "bx bx-food-menu",
  //   title: "Pages",
  //   isStore: true,
  // },

  // {
  //   routeLink: "/#",
  //   icon: "",
  //   title: "Extras",
  //   isSeparator: true,
  // },
  {
    routeLink: "/page-view",
    icon: "fas fa-users",
    title: "Page View",
  },
]
